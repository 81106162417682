import { clientApi } from "../services/client";

const TIER_LIMITS = {
    free: 3,
    basic: 10,
    advanced: 50,
    pro: Infinity
  };

export function getPlanName(subscription) {
    if(!subscription) return "free";
    const planMapping = {
        "FREE_MONTHLY": "free",
        "BASIC_MONTHLY": "basic",
        "ADVANCED_MONTHLY": "advanced",
        "ADVANCE_MONTHLY": "advanced",
        "PRO_MONTHLY": "pro",
        "FREE_YEARLY": "free",
        "BASIC_YEARLY": "basic",
        "ADVANCED_YEARLY": "advanced",
        "ADVANCE_YEARLY": "advanced",
        "PRO_YEARLY": "pro"
    };

    return planMapping[subscription.plan] || "free";
}  
export function isRowVisible(index: number): boolean {
    const userSub = localStorage.getItem('user-subscription');
    let userTier = 'free';
    if(userSub){
        userTier = getPlanName(JSON.parse(userSub)?.subscription) ?? 'free';
    }
    return index < TIER_LIMITS[typeof userTier === "string" ? userTier.toLowerCase() : userTier];
  }

export function getPlanRowNumber(): number {
    const userSub = localStorage.getItem('user-subscription');
    let userTier = 'free';
    if(userSub){
        userTier = getPlanName(JSON.parse(userSub)?.subscription) ?? 'free';
    }
    return TIER_LIMITS[typeof userTier === "string" ? userTier.toLowerCase() : userTier];
  }
  
export function canDownload(): boolean {
    const userSub = localStorage.getItem('user-subscription');
    let userTier = 'free';
    if(userSub){
        userTier = getPlanName(JSON.parse(userSub)?.subscription) ?? 'free';
    }
    return userTier === 'pro';
}

export const getProductName = (plan:string, isMonthly:boolean) => {
    if (plan === "free") {
      return null;
    }
    if (plan === "basic") {
      return isMonthly ? "BASIC_MONTHLY" : "BASIC_YEARLY";
    }

    if (plan === "advanced") {
      return isMonthly ? "ADVANCED_MONTHLY" : "ADVANCED_YEARLY";
    }

    if (plan === "pro") {
      return isMonthly ? "PRO_MONTHLY" : "PRO_YEARLY";
    }
};

export const getPlanCapitalizeName = (plan:string) => {
    if (plan === "free") {
      return "Free";
    }
    if (plan === "basic") {
      return "Basic";
    }

    if (plan === "advanced") {
      return "Advanced";
    }

    if (plan === "pro") {
      return "Pro";
    }
};

export const getCheckoutUrlByProductName = async (planName) => {
    
    try {
      // make api call to get checkout url
      const resp = await clientApi.post(
        `/api/v1/payments/${planName}`,
        {}
      );
      if (resp.status === 200) {
        console.log(`checkout url:${resp.data.data}`);
        // redirect to checkout url in a new tab
        window.open(resp.data.data, "_blank");
      }
    } catch (error) {
      console.log(error);
      return false;
    } finally {
      return true
    }
  };