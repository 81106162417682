import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import {
  ChakraStylesConfig,
  Select,
  useChakraSelectProps,
} from "chakra-react-select";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from "../../assets/svg/add.svg";
import {
  useAddCompaniesMutation,
  useFetchWatchlistDataQuery,
} from "../../services/watchlistPortfolioApi";
import { Watchlist } from "../../types/Watchlist";
import { containerVariants } from "../../utils/framerVariants";
import { CommonButton } from "../common/buttons/common";
import { CommonModal } from "./Common";
import { ContinueNavigation } from "./ContinueNavigation";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/slices/modal";
import { MODAL_TYPES_ENUM } from "../../constants/variables";
import { registerAddPortofolio, registerAddWatchlist, trackShowUpgradeModal } from "../../utils/gtagAnalytics";
import { IStock } from "../../types/Stock";
//is ticker is undefined allow multiple tickers
interface Props {
  companyName?: string;
  ticker?: string;
  isOpen: boolean;
  is: "watchlist" | "portfolio";
  onOpen?: () => void;
  onClose: () => void;
  tickers?: Array<string>;
  stock?:IStock
}

export function Add2WatchlistModal({
  companyName,
  ticker,
  isOpen,
  onClose,
  is,
  tickers = [],
  stock = undefined
}: Props) {
  const {
    isFetching,
    isError,
    currentData = [],
    refetch,
  } = useFetchWatchlistDataQuery(is, {
    pollingInterval: 60000,
    refetchOnMountOrArgChange: true,
  });

  const [selectedOptions, setSelectedOptions] = useState<Watchlist | null>(
    null,
  );
  const [added, setAdded] = useState(false);
  const toast = useToast();

  const [options, setOptions] = useState<
    Array<{ label: string; value: Watchlist }>
  >([]);

  const [isFirstTimeError,setIsFirstTimeError] = useState(false)

  const selectProps = useChakraSelectProps({
    isMulti: false,
    value: selectedOptions,
    onChange: (newValue) => {
      setSelectedOptions(newValue as Watchlist);
    },
    className: "customSelect",
  });

  const chakraStyles: ChakraStylesConfig = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      background: state.isFocused ? "blue.100" : "transparent",
    }),
    loadingIndicator: (provided, state) => ({
      ...provided,
    }),
  };

  const [addCompany, { isLoading: isAdding }] = useAddCompaniesMutation();
  const dispatch = useDispatch()
  const addedCompanyPromise = useCallback(async () => {
    try {
      await addCompany({
        id: (selectedOptions as any).value.id,
        isWatchlist: is === "watchlist",
        companyIds: [],
        tickers: ticker ? [ticker as string] : tickers,
      }).unwrap();
      setAdded(true);
      try {
          if(is === "watchlist" && stock){
            registerAddWatchlist(stock)
          }else if(stock){
            registerAddPortofolio(stock)
          }
      } catch (error) {
        
      }
      return Promise.resolve(true);
    } catch (error) {
      setAdded(false);
      trackShowUpgradeModal("Add company to watchlist/portfolio")
      dispatch(
        openModal({
          name: MODAL_TYPES_ENUM.pricing,
          link: `${is === "watchlist" ? "watchlist" : "portfolio"}-${
            (error as any)?.data?.limit
          }`,
          type: "pricing",
        }),
      );
      return Promise.reject(error);
    }
  }, [addCompany, is, selectedOptions, ticker, tickers,stock]);

  useEffect(() => {
    const options = currentData.map((w) => {
      return {
        label: w.name,
        value: w,
      };
    });
    setOptions([...options]);
  }, [currentData]);

  return (
    <CommonModal
    modalContentCustomProps={{
      height: { base: "auto", lg: "400px" },
      padding: { lg: "40px, 20px, 40px, 20px" },
      gap: "20px",
      top: { base: "5px", md: "0" },
      position: { base: "absolute", md: "initial" },
      margin: { base: "0 auto", md: "0" },
    }}
      isOpen={isOpen}
      onClose={onClose}
    >
      {added && (
        <ContinueNavigation
          companyName={(selectedOptions as any)?.label}
          is={is}
          id={(selectedOptions as any).value.id}
          name={(selectedOptions as any).value.name}
          onClose={onClose}
        />
      )}

      {!added && (
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          as={motion.div}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit={{ opacity: 0, transition: { duration: 1 } }}
        >
          <Flex alignItems={"center"} flexDirection={"column"} mt={{ base: "-40px", md: 0 }}>
            <PlusIcon />
            <Text
              fontSize={{ base: "14px", lg: "18px", xl: "18px" }}
              fontWeight={"600"}
              lineHeight={"27px"}
              align="center"
              fontFamily="Poppins"
              mb={2}
              mt={2}
              noOfLines={2}
            >
              {`Add ${companyName ? companyName : "multiples companies"} to one of your ${is}s `}
            </Text>
            <Text
              fontWeight="400"
              fontSize="14px"
              lineHeight="21px"
              fontFamily="Poppins"
              noOfLines={1}
              mb={4}
            >
              {`Choose the ${is} you want to add it to:`}
            </Text>
          </Flex>
          <Box width={"100%"} color="#085096">
            <Select
              {...selectProps}
              placeholder={`Choose a ${is}`}
              chakraStyles={chakraStyles as any}
              options={options as any}
            />
            {
              (selectedOptions == null && isFirstTimeError) && (<Text
                fontWeight="400"
                fontSize="14px"
                lineHeight="21px"
                fontFamily="Poppins"
                noOfLines={1}
                mb={4}
                color={"red.500"}
              >
                {`Please select one list to add it to`}
              </Text>)
            }
          </Box>
          <CommonButton
            title={`Add to my ${is}`}
            customProps={{
              isLoading: isAdding,
              mt: 2,
              width: "100%",
              onClick: () => {
                if (selectedOptions != null) {
                  toast.promise(addedCompanyPromise() as any, {
                    success: {
                      title: "Company added",
                      description: `Looks great you added ${companyName ? companyName : "many companies"}`,
                    },
                    error: (error:any)=> {
                      return { title: ":) Upss!!!", description: error?.data?.message ?? "Something wrong." }
                   },
                    loading: {
                      title: "Pending",
                      description: "Adding company",
                    },
                  });
                }else{
                  setIsFirstTimeError(true)
                }
              },
            }}
          ></CommonButton>
        </Flex>
      )}
    </CommonModal>
  );
}
