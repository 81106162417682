import type { AxiosError } from "axios";
import { clientApi } from "./client";
import axios from "axios";

type ExecuteProps = {
    relativeUrl?: string;
    customUrl?: string;
    data: any;
    onSuccess?: (data) => void;
    onError?: (error: AxiosError) => void;
    onFinish?: () => void;
}
export async function executeAxiosPost({ relativeUrl,customUrl, data, onSuccess, onError, onFinish }: ExecuteProps) {
    try {
    const resp = await clientApi.post(
        customUrl ? customUrl : `/api/v1/${relativeUrl}`,
        data
    );    
        console.log("on success axios call")
        onSuccess && onSuccess(resp.data);
        return resp.data
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            console.log('Error status:', axiosError.response?.status);
            console.log('Error data:', axiosError.response?.data);
            onError && onError(axiosError);
        } else {
            // Handle non-Axios errors
            console.log('Unexpected error:', error);
            onError && onError(error as AxiosError);
        }
        throw error;
    } finally {
        onFinish && onFinish();
    }
}