import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import googleIcon from "../../../assets/svg/google_icon.svg";
import { MODAL_TYPES_ENUM } from "../../../constants/variables";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { closeModal, selectModal } from "../../../store/slices/modal";
import SigninSignupWithCredentialsModal from "../../Auth/SigninSignupWithCredentials";
import TermPrivacyModal from "../../Auth/TermsAndPrivacy";
import { LoggedUserModal as AfterLogguedUserModal } from "./AfterLoged";
import { setActualUser } from "../../../store/slices/user";
import { AfterLoginPricingModal } from "../PricingModal/after";
import { getQueryParams } from "../../../utils/navigator";
import { getCheckoutUrlByProductName, getPlanCapitalizeName, getProductName } from "../../../utils/plan";
import {openModal as openModalActions} from "../../../store/slices/modal"

export function UserModal() {
  const dispatch = useDispatch();
  const modalData = useSelector(selectModal);

  const [signinClicked, setSigninClicked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", "");
  const [openTCModal, setOpenTCModal] = useState(false);
  const [userInfo, setUserInfo] = useState({ status: 0, data: { email: "" } });
  const [TCChecked, setTCChecked] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [modalAccessMethod, setModalAccessMethod] = useState("");
  const [userMessage, setUserMessage] = useState({ msg: "", color: "" });
  const [signWithCreds, setSignWithCreds] = useState(false);
  const [sendPeriodicUpdates, setSendPeriodicUpdates] = useState(false);
  const [openAfterLoginModal, setOpenAfterLoginModal] = useState(false);
  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });

  const productName = useMemo(()=>{
      const params = getQueryParams()
      return {
        plan: params['plan'] ?? false,
        isMonthly: params['isMonthly']
      }
  },[openModal])

  useEffect(()=>{
    if(modalData.link === "verification"){
      setSigninClicked(true);
      setModalAccessMethod("signin");
      setShowForm(true);
      setOpenModal(true);
    }
  },[modalData])

  const handleSignin = () => {
    setSigninClicked(true);
    setModalAccessMethod("signin");
    setShowForm(true);
    setOpenModal(true);
  };

  const handleSignupWithCredentials = () => {
    setSigninClicked(false);
    setModalAccessMethod("signup");
    setShowForm(true);
    setOpenModal(true);
  };

  const loginWithGoogle = async (userInfo: any) => {
    try {
      const params  = getQueryParams()
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/login`,
        {
          email: userInfo.data.email,
          socialId: "GOOGLE",
          flag: "GOOGLE",
          plan: params['plan'] ? getProductName(params['plan'],Boolean(params['isMonthly']) ) : false,
        },
      );
      if (response?.status === 200) {
        localStorage.setItem("accessToken", response?.data?.data?.token);
        localStorage.setItem("usr", JSON.stringify(response?.data?.data.user));
        dispatch(setActualUser(response?.data?.data?.user))
        fetchLoginStatus(response?.data?.data?.token);
        setShowForm(false);
        setSigninClicked(false);
        setOpenModal(false);

        if(response?.data?.data?.redirect_options){
            if(response?.data?.data?.redirect_options.redirect && params['plan']){
              getCheckoutUrlByProductName(getProductName(params['plan'],Boolean(params['isMonthly']) ))
            }else if(response?.data?.data?.redirect_options.modal){
              dispatch(openModalActions({ name: "Pricing", link: "", type: MODAL_TYPES_ENUM.pricing }));
            }
        }
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoogleSignup = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInforesp = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        },
      );
      setModalAccessMethod("handleGoogleSignup");
      setUserInfo(userInforesp);
      // handleGoogleSignupCheck(userInforesp);
      if (userInforesp.status === 200) {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/checkUserExist`,
          { email: userInforesp?.data?.email },
        );
        if (
          response?.status === 200 &&
          response?.data?.message ===
            "You already have an existing account - USING_EMAIL "
        ) {
          setSigninClicked(true);
          setUserMessage({
            msg: "You already have an existing account - please signin using Email & Password",
            color: "red",
          });
        } else if (
          response?.status === 200 &&
          response?.data?.message ===
            "You already have an existing account - GOOGLE "
        ) {
          setShowForm(false);
          setSigninClicked(false);
          loginWithGoogle(userInforesp);
        } else if (
          response?.status === 200 &&
          response?.data?.message === "Account does not exist"
        ) {
          setOpenTCModal(true);
        }
      }
    },
  });

  const handleGoogleSignupCheck = async (userInfo: any) => {
    if (userInfo?.status === 200) {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/signUp`,
        {
          email: userInfo?.data?.email,
          socialId: "GOOGLE",
          flag: "GOOGLE",
          firstName: userInfo?.data?.given_name || "",
          lastName: userInfo?.data?.family_name || "",
          photoURL: userInfo?.data?.picture || "",
          sendPeriodicUpdates: sendPeriodicUpdates,
        },
      );

      if (
        response?.status === 200 &&
        response?.data?.message === "signup successfully"
      ) {
        await axios.post(
          `https://cloud.activepieces.com/api/v1/webhooks/D30Izk60RHNZ0npFdw4xe?name=${userInput.firstName}&email=${userInput.email}`,
        );
        loginWithGoogle(userInfo);
      } else {
        console.log(response);
      }
    }
  };

  const fetchLoginStatus = (status) => {
    status && setAccessToken(status || "");
  };

  useEffect(() => {
    if (accessToken) setOpenModal(false);
  }, [accessToken]);

  useEffect(() => {
    if (TCChecked && userInfo.status === 200) {
      handleGoogleSignupCheck(userInfo);
    }
  }, [TCChecked, userInfo]);

  const handleCloseModal = () => dispatch(closeModal());

  return (
    <>
      <Modal
        isOpen={
          modalData.isOpen &&
          modalData?.type === MODAL_TYPES_ENUM.user &&
          !accessToken
        }
        onClose={handleCloseModal}
        size="md"
        isCentered
        // closeOnOverlayClick={false}
        // closeOnEsc={false}
        blockScrollOnMount={true}
      >
        <ModalOverlay sx={{ background: "#3a83c9a8" }} />
        <ModalContent
          bg="#FFF"
          borderRadius="20px"
          my={140}
          mx={5}
          p={"20px"}
          display="flex"
        >
          {signWithCreds ? (
            <ModalBody
              display="flex"
              py={"40px"}
              px="20px"
              flexDir="column"
              backgroundColor={"#F9FAFB"}
              borderRadius={"20px"}
              gap={"30px"}
              mt={0}
              alignItems={"start"}
            >
              <Text
                fontFamily="Poppins"
                fontStyle="normal"
                fontWeight="600"
                fontSize="24px"
                lineHeight="36px"
                textAlign="left"
                color="#021425"
              >
                {"First time here?"}
              </Text>
              <Box
                color="#F9FAFB"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontWeight={600}
                fontSize="16px"
                lineHeight="24px"
                py="15px"
                px="20px"
                width={"100%"}
                borderRadius="8px"
                backgroundColor={"#0564B8"}
                filter="drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.3))"
                cursor="pointer"
                onClick={handleSignupWithCredentials}
              >
                <Text color={"white"}>{"Yep! Sign me up free!"}</Text>
              </Box>
              <Box
                color="#0A64BC"
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontWeight={600}
                fontSize="16px"
                lineHeight="24px"
                py="15px"
                px="20px"
                width={"100%"}
                borderRadius="8px"
                backgroundColor={"white"}
                filter="drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.2))"
                cursor="pointer"
                onClick={handleSignin}
              >
                {"Nope. Let me in!"}
              </Box>
              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                gap="10px"
              >
                <Text
                  fontSize="16px"
                  fontWeight={400}
                  lineHeight="24px"
                  textAlign="center"
                  fontFamily="Poppins"
                  // color="#0A64BC"
                  cursor="pointer"
                >
                  {"I changed my mind..."}
                </Text>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontWeight={500}
                  fontSize="18px"
                  lineHeight="27px"
                  width={"100%"}
                  py="15px"
                  px="20px"
                  borderRadius="10px"
                  backgroundColor="white"
                  boxShadow="0px 2px 3px rgba(0, 0, 0, 0.25)"
                  cursor="pointer"
                  onClick={() => handleGoogleSignup()}
                >
                  <img src={googleIcon} alt="Google" />
                  <Text ms={4} fontFamily="Poppins">
                    <FormattedMessage id="continueWithGoogle" />
                  </Text>
                </Box>
              </Box>
            </ModalBody>
          ) : (
            <ModalBody
              display="flex"
              flexDir="column"
              py={"40px"}
              px="20px"
              rowGap={"30px"}
              mt={0}
              backgroundColor={"#F9FAFB"}
              borderRadius={"20px"}
            >
              <Text
                fontFamily="Poppins"
                fontStyle="normal"
                fontWeight="600"
                fontSize="24px"
                lineHeight="36px"
                textAlign="left"
                color="#021425"
              >
                {productName?.plan ? `Start Your ${getPlanCapitalizeName(productName?.plan as string)} Plan!` : 'Start beating the market in one click'}
              </Text>

              {userMessage?.msg && (
                <Text
                  fontSize="14px"
                  fontFamily="Poppins"
                  lineHeight="20px"
                  textAlign="center"
                  fontWeight={400}
                  color={userMessage?.color}
                  mb={2}
                >
                  {userMessage?.msg}
                </Text>
              )}

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontWeight={500}
                fontSize="18px"
                lineHeight="27px"
                width={"100%"}
                py="15px"
                px="20px"
                borderRadius="10px"
                backgroundColor="white"
                boxShadow="0px 2px 3px rgba(0, 0, 0, 0.25)"
                cursor="pointer"
                onClick={() => handleGoogleSignup()}
              >
                <img src={googleIcon} alt="Google" />
                <Text ms={4} fontFamily="Poppins">
                  <FormattedMessage id="continueWithGoogle" />
                </Text>
              </Box>

              {
                (!getQueryParams()['plan'] || getQueryParams()['plan'].includes("free")) && <Text
                 fontSize="16px"
                 fontWeight={400}
                 lineHeight="24px"
                 textAlign="center"
                 fontFamily="Poppins"
                 color="#0A64BC"
                 cursor="pointer"
                 onClick={() => setSignWithCreds(true)}
               >
                 {"...or use your email & password"}
               </Text>
              }
            </ModalBody>
          )}
        </ModalContent>
      </Modal>

      {/* <LoggedUserModal
        key={accessToken}
        open={
          modalData.isOpen &&
          modalData?.type === MODAL_TYPES_ENUM.user &&
          !!accessToken &&
          !openTCModal
        }
        setOpen={handleCloseModal}
        setAccessToken={setAccessToken}
      /> */}
      <AfterLogguedUserModal
        key={accessToken}
        open={
          modalData.isOpen &&
          modalData?.type === MODAL_TYPES_ENUM.user &&
          !!accessToken &&
          !openTCModal
        }
        setOpen={handleCloseModal}
        setHandleUpgradeModal={()=>{
          handleCloseModal()
          setOpenAfterLoginModal(true)
        }}
      />

      <AfterLoginPricingModal open={openAfterLoginModal} handleCloseModal={()=>setOpenAfterLoginModal(false)}/>
      
      {
        openModal && (<SigninSignupWithCredentialsModal
        key={modalAccessMethod}
        openModal={openModal}
        signinClicked={signinClicked}
        setOpenModal={setOpenModal}
        setSigninClicked={setSigninClicked}
        fetchLoginStatus={fetchLoginStatus}
        userInput={userInput}
        TCChecked={TCChecked}
        setOpenTCModal={setOpenTCModal}
        setUserInput={setUserInput}
        directSignupWithCredentials={showForm}
        sendPeriodicUpdates={sendPeriodicUpdates}
        onClose={()=>{
          setUserInput(
            {
              email: "",
              password: "",
              firstName: "",
              lastName: "",
            }
          )
        }}
      />)
      }
      <TermPrivacyModal
        TCChecked={TCChecked}
        setTCChecked={setTCChecked}
        isOpen={openTCModal}
        sendPeriodicUpdates={sendPeriodicUpdates}
        setSendPeriodicUpdates={setSendPeriodicUpdates}
        setIsOpen={setOpenTCModal}
        email={userInput.email ?? undefined}
      />
    </>
  );
}
