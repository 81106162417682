import { Box, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { StockItem } from "../components/Stocks/StockItem/StockItem";
import defaultStocks from "../constants/defaultStocks";
import DesktopContent from "../layout/MainContent/DesktopContent";
import MobileContent from "../layout/MainContent/MobileContent";
import { useFetchBatchCompaniesStockDataQuery } from "../services/stockApi";
import LeftHomeSubView from "../subviews/LeftSubView/Home";
import RightSubview from "../subviews/RightSubview/Home";
import { IStock } from "../types/Stock";
import {useDispatch} from "react-redux";
import { isPresentSubscription, isPresentVerificationCode } from "../utils/decode";
import { openModal } from "../store/slices/modal";

import "../css/style.css";
import { MODAL_TYPES_ENUM } from "../constants/variables";


export default function Home() {
  const dispatch = useDispatch();
  const [activeSubitemIdx, setActiveSubitemIdx] = useState(0);
  const [stocks, setStocks] = useState<IStock[]>(
    defaultStocks as unknown as IStock[],
  );

  const { data: batchCompaniesData, isFetching } =
    useFetchBatchCompaniesStockDataQuery(
      defaultStocks.map((item) => item.ticker),
    );

  useEffect(() => {
    if (!isFetching && Array.isArray(batchCompaniesData)) {
      const updatedDefaultStocks = defaultStocks.map((stock) => {
        const searchedCompany = batchCompaniesData?.find(
          (company) => company?.fullTicker === stock?.ticker,
        );

        if (searchedCompany) {
          stock.monkScore = searchedCompany?.monkScorePercentile;
        }

        return stock;
      });

      setStocks(updatedDefaultStocks as unknown as IStock[]);
    }
  }, [batchCompaniesData, isFetching]);

  useEffect(()=>{
       if(isPresentVerificationCode()){
           dispatch(openModal({
              name: "Verification",
              link: "",
              type: MODAL_TYPES_ENUM.verification,
           }))
       }else if(isPresentSubscription()){
        dispatch(openModal({
          name: "Verification",
          link: "",
          type: MODAL_TYPES_ENUM.subscription,
       }))
       }
  },[dispatch])

  return (
    <>
      <MobileContent>
        <Box display={{ base: "none", md: "block" }}>
          <DesktopContent>
            <LeftHomeSubView
              currentIdx={activeSubitemIdx}
              setCurrentIdx={setActiveSubitemIdx}
            />
            <RightSubview currentIdx={activeSubitemIdx} stocks={stocks} />
          </DesktopContent>
        </Box>

        <Stack spacing={2} display={{ base: "block", md: "none" }}>
          {stocks.map((stock, i) => (
            <StockItem stock={stock} key={i} />
          ))}
        </Stack>
      </MobileContent></>

  );
}
