import domtoimage from "dom-to-image";
import html2canvas from "html2canvas";
import { extractDomain, iOSSafari } from "./navigator";
import { quickShare } from "./share";
import { IStock } from "../types/Stock";


export function fetchBrandLogo(company: IStock) {
    if(!company.website) return company.image;
    const domain = extractDomain(company.website);
    return `https://cdn.brandfetch.io/${domain}/w/600/h/600?c=1ideigYY3lMoBgmZd0e`;
}

export function imageIsMainlyWhite(
  imageSrc: string,
  threshold: number,
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";

    img.onload = function () {
      let canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      if (ctx) {
        ctx.drawImage(img, 0, 0, img.width, img.height);

        let whitePixels = 0;
        const imageData = ctx.getImageData(0, 0, img.width, img.height);
        const data = imageData.data;

        for (let i = 0; i < data.length; i += 4) {
          const red = data[i];
          const green = data[i + 1];
          const blue = data[i + 2];

          if (red > 200 && green > 200 && blue > 200) {
            whitePixels++;
          }
        }

        const totalPixels = img.width * img.height;
        const whitePercentage = (whitePixels / totalPixels) * 100;

        if (whitePercentage >= threshold) {
          resolve(true);
        } else {
          resolve(false);
        }
      }
    };

    img.onerror = function (err) {
      reject(err);
    };

    img.src = imageSrc;
  });
}

//automatic copy to the clipboard. Otherwise use onSuccess callback
export async function getScreenShot(
  id: string,
  onSuccess: (blob: Blob) => void,
) {
  const element = document.getElementById(id);

  if (element) {
    element.style.maxWidth = "100%";
    element.style.paddingBottom = "0px";
    element.style.paddingTop = "0px";
    return new Promise((resolve, reject) => {
      html2canvas(element).then(function (canvas) {
        canvas.toBlob(function (blob) {
          navigator.clipboard
            .write([
              new ClipboardItem(
                Object.defineProperty({}, blob?.type ?? "image/png", {
                  value: blob,
                  enumerable: true,
                }),
              ),
            ])
            .then(function () {
              onSuccess(blob as Blob);
            });
        });
      });
    });
  }
}

export function getScreenShotDom2Image(
  id: string,
  onSuccess?: (blob: Blob) => void,
  onError?: () => void,
  isCharts?: boolean,
) {
  let element = document.getElementById(id);
  
  if (iOSSafari(navigator.userAgent)) {
    domtoimage
      .toSvg(element, getUpsacalerImageAttributes(element, !isCharts))
      .then(function (dataUrl) {
        cleanUp();
        toClipboardSafari(dataUrl, onSuccess, onError);
      });
  } else {
    domtoimage
      .toBlob(element, getUpsacalerImageAttributes(element, !isCharts))
      .then((blob: Blob) => {
        cleanUp();
        navigator.clipboard
          .write([
            new ClipboardItem({
              "image/png": blob,
            }),
          ])
          .then(() => {
            cleanUp();
            onSuccess && onSuccess(blob);
          })
          .catch((error) => {
            cleanUp();
            onError && onError();
          });
      })
      .catch((error) => {
        cleanUp();
        onError && onError();
      });
  }
}

export function toClipboardSafari(
  dataUrl,
  onSuccess?: (blob: Blob) => void,
  onError?: () => void,
) {
  const svg = dataUrl;
  const image = document.createElement("img");
  image.addEventListener("load", () => {
    const canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    canvas.getContext("2d")?.drawImage(image, 0, 0, image.width, image.height);
    const image2Clipboard = new ClipboardItem({
      "image/png": new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            resolve(blob as Blob);
          },
          "image/png",
          1.0,
        );
      }),
    });
    try {
      navigator.clipboard.write([image2Clipboard]);
      cleanUp();
      onSuccess && onSuccess({} as Blob);
    } catch (error) {
      cleanUp();
      onError && onError();
    }
  });
  image.src = svg;
  var svgElement = document.createElement("div");
  svgElement.id = "CloneSvgElement";
  svgElement.style.display = "none";
  svgElement.appendChild(image);
  document.body.appendChild(svgElement);
}

function cleanUp() {
  new Promise((resolve, reject) => {
    let element = document.getElementById("CloneSvgElement");
    if (element) {
      document.body.removeChild(element);
    }
    let cloneNode = document.getElementById("ClonedDivElement");
    if (cloneNode) {
      document.body.removeChild(cloneNode);
    }
    resolve(true);
  });
}

export function shareContent({
  id,
  onSuccess,
  onError,
  isCharts,
}: {
  id: string;
  onSuccess?: () => void;
  onError?: () => void;
  isCharts?: boolean;
}) {
  let element = document.getElementById(id);
  let executeCleanUp = false
  
  const width = (element?.offsetWidth ?? 1)
  const height = (element?.offsetHeight ?? 1)
 
  domtoimage
    .toSvg(element, {
      width: (width) * 3, // Adjust for padding
      height: (height) * 3,
      style: { transform: "scale(3)", transformOrigin: "top left",
      imageResolution: "300dpi",
      width: `${width}px`,
      height: `${height}px`,
      backgroundColor: "#F9FAFB" },
    })
    .then(function (dataUrl) {
      executeCleanUp && cleanUp();
      const svg = dataUrl;
      const image = document.createElement("img");
      image.addEventListener("load", async () => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        canvas
          .getContext("2d")
          ?.drawImage(image, 0, 0, image.width, image.height);
        const dataUrl = canvas.toDataURL();
        const blob = await (await fetch(dataUrl)).blob();
        const filesArray = [
          new File([blob], "monkshare.png", { type: blob.type }),
        ];
        const shareData = {
          files: filesArray,
        };

        quickShare(
          shareData,
          () => {
            executeCleanUp && cleanUp();
            onSuccess && onSuccess();
          },
          () => {
            executeCleanUp && cleanUp();
            onError && onError();
          },
        );
      });

      image.src = svg;
      var svgElement = document.createElement("div");
      svgElement.id = "CloneSvgElement";
      svgElement.style.display = "none";
      svgElement.appendChild(image);
      document.body.appendChild(svgElement);
    });
}

function getUpsacalerImageAttributes(domNode, isMonkScore?: boolean) {
  let scale = 3;
  const width = (domNode?.offsetWidth ?? 1)
  const height = (domNode?.offsetHeight ?? 1)
  return {
    width: (domNode.clientWidth ?? 0) * scale,
    height: (domNode.clientHeight ?? 0) * scale,
    style: {
      transform: `scale(${scale})`,
      transformOrigin: "top left",
      //display: "grid",
      imageResolution: "300dpi",
      backgroundColor: "#F9FAFB",
      width: `${width}px`, height: `${height}px`,
      padding: "20px !important",
    },
    quality: 1.0,
  };
}
