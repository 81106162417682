import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fakeDataForSkeleton } from "../../constants/fakeData";
import { axiosInstance } from "../../context/api";
import { Filter, Screener, ScreenerFilterResult } from "../../types/Screener";
import { download } from "../../utils/download";

type State = {
  actualScreener: Screener | null;
  loading: boolean;
  error: boolean;
  filters: Array<Filter>;
  screeners: Array<Screener>;
  filterResult: Array<ScreenerFilterResult>;
  total: number;
  totalPages: number;
  fakeData: any;
  selectedTickers: Array<string>;
  errorMessage?: string 
};

const initialState: State = {
  actualScreener: null,
  filters: [],
  screeners: [],
  loading: false,
  error: false,
  filterResult: [],
  total: 0,
  totalPages: 0,
  fakeData: fakeDataForSkeleton,
  selectedTickers: [],
  errorMessage: undefined
};

export const fetchOne = createAsyncThunk(
  "screener/fetchOne",
  async ({ id }: { id: string | number }, thunkAPI) => {
    const response = await axiosInstance.get<Screener>(`screener/${id}`);
    return response.data;
  },
);

let currentAbortController: AbortController | null = null;

export const applyFilters = createAsyncThunk(
  "screener/applyFilters",
  async (
    {
      filters = [],
      page = 1,
      pageSize = 25,
      isOr = false,
      sortingBy = [],
    }: {
      filters?: Array<Filter>;
      page?: number;
      pageSize?: number;
      isOr?: boolean;
      sortingBy?: Array<any>;
    },
    thunkAPI,
  ) => {
    if (currentAbortController) {
      currentAbortController.abort();
    }

    currentAbortController = new AbortController();

    try {
      const response = await axiosInstance.post<any>(
        `screener/applyFilters`,
        {
          filters,
          sortingBy,
        },
        {
          params: {
            page,
            pageSize,
            isOr,
          },
          signal: currentAbortController.signal,
        },
      );
      const result = response.data;

      return {
        total: result.total,
        totalPages: result.pagination.totalPages,
        data: result.data as Array<ScreenerFilterResult>,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
);

export const downloadFilters = createAsyncThunk(
  "screener/downloadFilters",
  async (
    { filters = [], limit = 100 }: { filters?: Array<Filter>; limit?: number, isOr?: boolean },
    thunkAPI,
  ) => {
    const response = await axiosInstance.post<any>(
      `screener/downloadFilters`,
      {
        filters: filters,
      },
      {
        params: {
          limit,
        },
        responseType: "blob",
      },
    );
    const result = response.data;
    download(result).then(() => {});
  },
);

const screenerSlice = createSlice({
  name: "screener",
  initialState,
  reducers: {
    addFilter: (state, action: PayloadAction<Filter>) => {
      const actualFilters = state.filters as Array<Filter>;
      actualFilters.push(action.payload);
      state.filters = [...actualFilters];
    },
    updateFilter: (
      state,
      action: PayloadAction<{ index: number; filter: Filter }>,
    ) => {
      const actualFilters = state.filters as Array<Filter>;
      actualFilters.splice(action.payload.index, 1, action.payload.filter);
      state.filters = [...actualFilters];
    },
    removeFilter: (state, action: PayloadAction<{ index: number }>) => {
      const index = action.payload.index;
      if (action.payload.index !== -1) {
        state.filters.splice(index, 1);
      }
    },

    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filters = [...action.payload];
    },
    cleanUpOne: (state) => {
      state.filters = [];
      state.actualScreener = null;
    },
    setScreener: (state, action: PayloadAction<Screener>) => {
      state.actualScreener = action.payload;
    },
    setScreeners: (state, action: PayloadAction<Screener[]>) => {
      state.screeners = action.payload;
    },
    setSelection: (state, action: PayloadAction<any>) => {
      const selected = [] as Array<string>;
      const indexs = action.payload;
      for (const key in indexs) {
        if (Object.prototype.hasOwnProperty.call(indexs, key)) {
          if (indexs[key]) {
            selected.push(
              state.filterResult[Number(key)].CompanyProfile.ticker,
            );
          }
        }
      }
      state.selectedTickers = selected;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOne.fulfilled, (state, action) => {
        state.actualScreener = action.payload;
        state.filters = action.payload?.filters;
        state.loading = false;
      })
      .addCase(fetchOne.rejected, (state, action) => {
        state.error = true;
        state.loading = false;
        state.actualScreener = null;
        state.filters = [];
      })
      .addCase(fetchOne.pending, (state, action) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(applyFilters.fulfilled, (state, action) => {
        state.filterResult = action.payload?.data as ScreenerFilterResult[];
        state.total = action.payload?.total;
        state.totalPages = action.payload?.totalPages;
        state.loading = false;
      })
      .addCase(applyFilters.rejected, (state, action) => {
        console.log("action",action)
        state.error = (action.payload as any).response.status ?? true;
        state.loading = false;
        state.filterResult = [];
        state.total = 0;
        state.totalPages = 0;
        state.errorMessage = (action.payload as any)?.message
      })
      .addCase(applyFilters.pending, (state, action) => {
        state.error = false;
        state.loading = true;
      });
  },
});

export const {
  setFilters,
  addFilter,
  cleanUpOne,
  removeFilter,
  setSelection,
  updateFilter,
} = screenerSlice.actions;
export const actualScreener = (state) =>
  state.screener.actualScreener as Screener;
export const filterTableResult = (state) =>
  state.screener.filterResult as Array<ScreenerFilterResult>;
export const screeners = (state) => state.screener.screeners as Array<Screener>;
export const filters = (state) => state.screener.filters as Array<Filter>;
export const total = (state) => state.screener.total as number;
export const totalPages = (state) => state.screener.totalPages as number;
export const loading = (state) => state.screener.loading as boolean;
export const error = (state) => state.screener.error as boolean;
export const errorMessage = (state) => state.screener.errorMessage as string;
export const dataSkeleton = (state) => state.screener.fakeData as any;
export const selectedTickers = (state) =>
  state.screener.selectedTickers as Array<string>;
export default screenerSlice.reducer;
