import { Box, Image } from "@chakra-ui/react";
import { useMainlyWhite } from "../../../hooks/useMainlyWhite";

interface StockImageProps {
  name: string;
  fullTicker: string;
  image: string;
  mainlyBackgroundColor?: string;
}

export default function NewStockImage({
  name,
  fullTicker,
  image,
  mainlyBackgroundColor,
}: StockImageProps) {
  const { isMainlyWhite } = useMainlyWhite(image, 5);
  const mainlyColor = mainlyBackgroundColor || "#1c1c1c";

  return (
    <Box
      width="70px"
      height="70px"
      borderRadius={"50%"}
      overflow={"hidden"}
      display={"grid"}
      alignItems={"center"}
      justifyItems={"center"}
      className="border-none-important"
    >
      <Image
        src={image}
        alt={name}
        fallbackSrc={`${process.env.PUBLIC_URL}/stock-icons/fallback.png`}
        onError={(e: any) => {
          e.target.onError = null;
          e.target.src = `${process.env.PUBLIC_URL}/stock-icons/fallback.png`;
        }}
        fallbackStrategy="beforeLoadOrError"
        boxSize={"100%"}
        minHeight={"100%"}
        objectFit="cover"
        maxWidth={"100%"}
        overflow={"hidden"}
        className="border-none-important"
      />
    </Box>
  );
}
