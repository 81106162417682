import { Text } from "@chakra-ui/react";

import { ReactComponent as ArrowIcon } from "../../../../assets/svg/arrow-outward.svg";
import { useCallback } from "react";

type TProps = {
  vector: string;
  meta: string;
  large?: boolean;
  lowerIsBetter?: boolean
};

function GrowthBadge({ vector, meta, large = false, lowerIsBetter = false }: TProps) {
  const getBgColor = useCallback(()=>{
        if(vector === "eq"){
          return "#055093"
        }
        if(meta.includes("n/a")){
          return' #C9CACB'
        }
        if((vector === "down" && lowerIsBetter) || ( vector === "up" && !lowerIsBetter)){
           return "#58CC0233"
        }
        return "#FF4B4B33"
  },[lowerIsBetter, meta, vector])
  const getColor = useCallback(()=>{
    if(meta.includes("n/a")){
      return' #fff'
    }
    if((vector === "down" && lowerIsBetter) || ( vector === "up" && !lowerIsBetter)){
       return "#58CC02"
    }
    return "#FF4B4B"
},[lowerIsBetter, meta, vector])
  return (
    <Text
      borderRadius={"999px"}
      px={large ? "6px" : "4px"}
      py={large ? "4px" : "2px"}
      backgroundColor={getBgColor()}
      color={getColor()}
      fontSize={"10px"}
      fontWeight={"300"}
      lineHeight={"10px"}
      width={"max-content"}
      display="flex"
      alignItems={"center"}
    >
      {vector === "up" ? (
        <ArrowIcon />
      ) : (
        <ArrowIcon style={{ transform: "rotateX(180deg)" }} />
      )}
      &nbsp;{meta}&nbsp;
    </Text>
  );
}

export default GrowthBadge;
