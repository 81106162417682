import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
  reducerPath: "userData",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_SERVER_URL}/api/v1/auth`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        const replacedToken = token.replace(/['"]+/g, "").trim();
        headers.set("authorization", replacedToken);
        headers.set("token", replacedToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchSubscriptionData: builder.query({
      query: () => `/user-subscription`,
      transformResponse: (response: any) => {
        localStorage.setItem(
          "user-subscription",
          JSON.stringify(response.data),
        );
        return response.data;
      },
    }),
    forgotPassword: builder.mutation<
      { success: boolean; message: string },
      { email: string }
    >({
      query: (body) => ({
        url: "/forgot-password",
        method: "POST",
        body,
      }),
    }),
    changePassword: builder.mutation<
      { success: boolean; message: string },
      { currentPassword: string; newPassword: string }
    >({
      query: (body) => ({
        url: "/change-password",
        method: "POST",
        body,
      }),
    }),
    resendEmailVerification: builder.mutation<
      { success: boolean; message: string },
      { email: string }
    >({
      query: (body) => ({
        url: "/resend-verification",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useFetchSubscriptionDataQuery,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useResendEmailVerificationMutation
} = userApi;
