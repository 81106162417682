import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Text, Tooltip, VStack } from "@chakra-ui/react";
import React from "react";
import { CommonButton } from "../../common/buttons/common";

interface SubscriptionDetailsProps {
  onChangePlan: () => void;
  onManagePaymentMethod: () => void;
  onViewPaymentHistory: () => void;
  onCancelMembership: () => void;
  onReanudeMembership?: () => void;
  subscriptionData?: {
    subscription: {
      plan: string;
      currentPeriodStart: number;
      currentPeriodEnd: number;
      cancelAtPeriodEnd: boolean;
      status: string;
      pausedSubscription: boolean
    };
  };
}

const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({
  onChangePlan,
  onManagePaymentMethod,
  onViewPaymentHistory,
  onCancelMembership,
  onReanudeMembership,
  subscriptionData,
}) => {
  const renderButton = (label: string, onClick: () => void) => (
    <>
      <Button
        variant="link"
        onClick={onClick}
        justifyContent="space-between"
        textAlign="left"
        color="black"
        rightIcon={<ChevronRightIcon />}
        width="100%"
        _hover={{ textDecoration: "none" }}
      >
        {label}
      </Button>
      <Divider />
    </>
  );

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const getPlanDisplay = (plan: string) => {
    const [name] = plan.split("_");
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  return (
    <Box
      maxWidth="400px"
      borderWidth="1px"
      borderRadius="lg"
      p={6}
      pt={12}
      position="relative"
    >
      <Box
        bg="blue.600"
        color="white"
        p={2}
        my={2}
        borderRadius="md"
        borderLeftRadius={0}
        position="absolute"
        left={0}
        top={0}
      >
        <Text fontSize="sm">
          Member since{" "}
          {subscriptionData?.subscription
            ? formatDate(subscriptionData.subscription.currentPeriodStart)
            : "N/A"}
        </Text>
      </Box>
      <VStack spacing={4} align="stretch">
        <Text fontWeight="bold" fontSize="xl">
          Plan:{" "}
          {subscriptionData?.subscription
            ? getPlanDisplay(subscriptionData.subscription.plan)
            : "Free"}
        </Text>
        <Text>
          Next payment:{" "}
          {subscriptionData?.subscription?.pausedSubscription ? "Never" : subscriptionData?.subscription
            ? formatDate(subscriptionData.subscription.currentPeriodEnd)
            : "N/A"}
        </Text>
        {renderButton("Change plan", onChangePlan)}
        {renderButton("Manage payment method", onManagePaymentMethod)}
        {renderButton("View payment history", onViewPaymentHistory)}
        {subscriptionData?.subscription?.pausedSubscription ? <Tooltip shouldWrapChildren label={"You can resume your subscription as long as it has not expired and your current plan is paused."} hasArrow >
          <CommonButton title="RESUME SUBSCRIPTION" customProps={{
            width:"100%",
            height: "16px",
            isDisabled: !subscriptionData?.subscription ||
            !subscriptionData?.subscription?.pausedSubscription,
            onClick: onReanudeMembership
          }}/>
        </Tooltip> : <>
        {(subscriptionData?.subscription && subscriptionData?.subscription.plan !== "pro" && subscriptionData?.subscription.plan !== "PRO_YEARLY" && subscriptionData?.subscription.plan !== "PRO_MONTHLY") && <CommonButton title="UPGRADE  SUBSCRIPTION" customProps={{
            width:"100%",
            height: "16px",
            onClick: onChangePlan
          }}/>}
        </>}
        
        <Button
          colorScheme="red"
          variant="outline"
          onClick={onCancelMembership}
          color="#8a4542"
          height={"16px"}
          fontSize={"16px"}
          fontWeight={600}
          textTransform={"uppercase"}
          gap="10px"
          borderRadius="8px"
          py={"22px"}
          px={"30px"}
          isDisabled={
            !subscriptionData?.subscription ||
            subscriptionData.subscription.cancelAtPeriodEnd
          }
        >
          {subscriptionData?.subscription?.cancelAtPeriodEnd
            ? "Cancellation Scheduled"
            : "Cancel Membership"}
        </Button>
      </VStack>
    </Box>
  );
};

export default SubscriptionDetails;
