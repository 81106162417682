import {
    Button,
    Flex,
    Text,
    useToast,
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { CommonModal } from "../Common";
import { useSelector,useDispatch } from "react-redux";
import { closeModal, selectModal } from "../../../store/slices/modal";
import { MODAL_TYPES_ENUM } from "../../../constants/variables";
import { useResendEmailVerificationMutation } from "../../../services/userApi";

export const RegistrationSuccessModal = () => {
    const dispatch = useDispatch()
    const modalData = useSelector(selectModal);
    const toast = useToast();
    const [resendEmail, { isLoading }] = useResendEmailVerificationMutation();

    const handleResendEmail = async () => {
          try {
            await resendEmail({email:modalData?.link ?? ""}).unwrap();
            return Promise.resolve(true);
          } catch (error) {
            return Promise.reject(error);
          }
    }
    return (
        <CommonModal
            isOpen={
                modalData.isOpen &&
                modalData?.type === MODAL_TYPES_ENUM.registration
            }
            onClose={()=> {
                dispatch(closeModal());
            }}
            modalContentCustomProps={{
                padding: "20px, 20px, 40px, 20px",
                gap: "20px",
            }}
            showCloseIcon={false}
        >
            <Flex>
                <Text
                    fontSize="16px"
                    fontFamily="Poppins"
                    fontWeight="500"
                    lineHeight="24px"
                    display="flex"
                    alignItems="center"
                    alignSelf={"start"}
                    pb={10}
                >
                    Verify your email
                </Text>
            </Flex>
            <Flex flexDirection={"column"} alignItems={"start"} gap={"2px"}>

                <Text>
                    <FormattedMessage
                        id="registration.success.message"
                        defaultMessage="We’ve sent a link to {email}."
                        values={{
                            email: <strong>{modalData.link}</strong>,
                            br: <br />,
                        }}
                    />
                </Text>
                <Text mb={5} color="#667085">
                    <FormattedMessage
                        id="registration.success.spam"
                        defaultMessage="Didn’t get it? Check SPAM or:"
                    />
                </Text>

                <Button
                    colorScheme="blue"
                    onClick={() => {dispatch(closeModal())}}
                    w={"100%"}
                    textTransform={"uppercase"}
                    height={"16px"}
                    py={"22px"}
                    px={"30px"}
                    minWidth={"150px"}
                    color={"#F9FAFB"}
                    backgroundColor={"#055093"}
                    borderRadius={"8px"}
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    fontWeight={600}
                    fontFamily={"Poppins"}
                >
                    {"Continue exploring"}
                </Button>

                <Button
                    colorScheme="whiteAlpha"
                    onClick={() => {
                        toast.promise(handleResendEmail() as any, {
                            success: {
                              title: "Resend email successfully",
                              description: `Looks great. Email has been sent to ${modalData.link}`,
                            },
                            error: (error:any)=> {
                              return { title: ":) Upss!!!", description: error?.data?.message ?? "Something wrong." }
                           },
                            loading: {
                              title: "Pending",
                              description: "Sending email...",
                            },
                          });
                     }}
                    fontFamily={"Poppins"}
                    w={"100%"}
                    h={"56px"}
                    minH={"56px"}
                    color="#0564B8"
                    backgroundColor={"white"}
                    borderRadius={"8px"}
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    fontWeight={600}
                    isLoading={isLoading}
                >
                    {"Resend email verification"}
                </Button>
                
            </Flex>
        </CommonModal>
    );
};