import { ChangeEvent, useState } from "react";

import { Box, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import axios from "axios";
import Logo from "../../components/Logo";
import { ICompany } from "../../types/Stock";
import CompanySearch from "./CompanySearch";
import MobileTopContent from "./MobileTopContent";
import { registerSearchEvent } from "../../utils/gtagAnalytics";
import { debounce } from "lodash";

function TopBar() {
  const [searchValue, setSearchValue] = useState("");

  const [stocks, setStocks] = useState<ICompany[]>([]);
  const [cache, setCache] = useState(new Map<string, ICompany[]>());

  const fetchSearchStocks = async (searchValue: string) => {
    if (searchValue === "") return;

    if (cache.has(searchValue)) {
      setStocks(cache.get(searchValue) as ICompany[]);
      return;
    }

    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/stocks`,
      {
        params: { limit: 20, search: searchValue },
      },
    );

    const filteredData = res.data.data.filter(
      (e: ICompany, i: number) =>
        res.data.data.findIndex((a: ICompany) => a.symbol === e.symbol) === i &&
        i <= 5,
    );

    setCache((prevCache) => {
      const newCache = new Map(prevCache);
      newCache.set(searchValue, filteredData);
      return newCache;
    });
    registerSearchEvent(searchValue,res.data?.data?.length ?? 0)
    setStocks(filteredData);
  };

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchValue(newValue);

    if (newValue === "") {
      setStocks([]);
      setCache(new Map<string, ICompany[]>());
      return;
    }

    fetchSearchStocks(newValue);
  };

  const clearSearch = () => {
    setSearchValue("");
    setStocks([]);
  };

  const debouncedFunction = debounce((value) => {
      onChange(value);
    }, 500); 

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      columnGap={"30px"}
      position={{ md: "fixed", base: "static" }}
      top="0"
      left="0"
      pl={{ md: "35px", base: "10px" }}
      pr={{ md: "40px", base: "10px" }}
      pt={{ md: "unset", base: "10px" }}
      pb={{ md: "unset", base: "66px" }}
      width="100%"
      height={{md: "100px", base: "auto" }}
      backgroundColor="#04284B"
      fontFamily="Poppins"
      zIndex={200}
      as="header"
    >
      <Flex alignItems={"center"} display={{ md: "flex", base: "none" }}>
        <Link to={"/"} className="defaultLink">
          <Logo />
        </Link>
      </Flex>

      <CompanySearch
        searchValue={searchValue}
        onChange={debouncedFunction as any}
        stocks={stocks}
        clearSearch={clearSearch}
      />

      <MobileTopContent
        searchValue={searchValue}
        onChange={onChange}
        stocks={stocks}
        clearSearch={clearSearch}
      />
    </Box>
  );
}

export default TopBar;
