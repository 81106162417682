import {
    Box,
    Button,
    Flex,
    Text,
    Tooltip,
    useToast
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { CommonModal } from "../Common";
import { useSelector, useDispatch } from "react-redux";
import { closeModal, selectModal } from "../../../store/slices/modal";
import { MODAL_TYPES_ENUM } from "../../../constants/variables";
import { useCallback, useEffect, useState } from "react";
import { executeAxiosPost } from "../../../services/common";
import { extractSubscription, extractVerificationCode } from "../../../utils/decode";
import { ReactComponent as SuccessIcon } from "../../../assets/svg/icons-success.svg"
import { subtitleText, titleText } from "../../../constants/styles";
import { getPlanName } from '../../../utils/plan';
import { billingPlans, OptionsListTooltips } from "../PricingModal/data";
import { useNavigate } from "react-router-dom";
import { CommonButton } from "../../common/buttons/common";

export const SubscriptionSuccessModal = () => {

    const dispatch = useDispatch()
    const modalData = useSelector(selectModal);
    const toast = useToast();
    const [error, setError] = useState(false)
    const navigate = useNavigate();
    const getPlanNameCallback = useCallback(() => {
        const data = extractSubscription()
        const planName = data ? getPlanName(data) : "free"
        return {
            plan: planName,
            planNameClean: `${planName.charAt(0).toUpperCase() + planName.slice(1)}`
        }
    }, [])

    const handleSubscription = useCallback(async () => {
        setTimeout(async () => {
            try {
                /*Decode code is this object{
                    plan: data.plan,
                    user:data.user,
                    email: data.email
                  }*/
                const data = extractSubscription()

                if (data) {
                    await executeAxiosPost({
                        customUrl: "/product_subscription_success",
                        data: {
                            ...data
                        },
                    });
                }
            } catch (error: any) {
                setError(true)
            }
        }, 1000)
    }, [])

    useEffect(() => {
        if (modalData?.isOpen && modalData?.type === MODAL_TYPES_ENUM.subscription) {
            handleSubscription()
        }
    }, [handleSubscription, modalData?.isOpen, modalData?.type])

    return (
        <CommonModal
            isOpen={
                modalData.isOpen &&
                modalData?.type === MODAL_TYPES_ENUM.subscription
            }
            onClose={() => {
                dispatch(closeModal());
            }}
            modalContentCustomProps={{
                padding: "20px, 20px, 40px, 20px",
                gap: "20px",
            }}

        >
            <Flex flexDirection={"column"} alignItems={"center"} gap={"10px"}>

                <Flex flexDirection={"column"} alignItems={"center"} gap={"5px"} textAlign={"center"}>
                    <SuccessIcon />
                    <Text {...titleText}>
                        <FormattedMessage
                            id="subscription.title"
                            values={{
                                plan: getPlanNameCallback().planNameClean
                            }}
                        />
                    </Text>
                    <Text {...subtitleText}>
                        <FormattedMessage
                            id="subscription.subtitle"
                            defaultMessage="Time to start discovering exceptional companies"
                        />
                    </Text>
                </Flex>

                <Flex flexDirection={"column"} alignSelf={"start"} alignItems={"start"} gap={"10px"} padding={"10px"}>
                    <Text {...titleText}>Your plan includes: </Text>
                    {
                        Object.values(billingPlans).filter((plan) => plan.name === getPlanNameCallback().plan).map((plan) => {
                            return (
                                <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                                    {plan.optionsList.map((option, i) => {
                                        const [...rest] =
                                            option.split(" ");

                                        return (
                                            <Tooltip key={`tooltip-${i}`} label={OptionsListTooltips[i]} p={2} placement="top-start" hasArrow >
                                                <Box
                                                    display="flex"
                                                    gap="5px"
                                                    alignItems={"start"}
                                                    className="option-item"
                                                    fontSize={"sm"}
                                                    key={i}
                                                >
                                                    <Text fontWeight={"light"}>
                                                        {rest.join(" ").replaceAll("/", " ")}
                                                    </Text>
                                                </Box>
                                            </Tooltip>
                                        );
                                    })}
                                </Box>
                            )
                        })
                    }
                </Flex>

                <Button
                    colorScheme="blue"
                    w={"100%"}
                    height={"16px"}
                    py={"22px"}
                    px={"30px"}
                    minWidth={"150px"}
                    color={"#F9FAFB"}
                    backgroundColor={error ? '#FF4B4B !important' : "#055093"}
                    borderRadius={"8px"}
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    fontWeight={600}
                    fontFamily={"Poppins"}
                    mb="20px"
                    onClick={() => {
                        dispatch(closeModal())
                        navigate("/")

                    }}
                >
                    Start Analyzing Companies
                </Button>
                <CommonButton
                    title="Close"
                    lightMonkButton={true}
                    customProps={{
                        onClick: () => dispatch(closeModal()),
                        w: "100%",
                        h: "56px",
                        borderRadius: "20px",
                        mb: "20px",
                        boxShadow: "none",
                    }}
                />
            </Flex>
        </CommonModal>
    );
};