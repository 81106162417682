import {
  Button,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Eye from "../../assets/svg/Eye.png";
import Eyeshow from "../../assets/svg/Eyeshow.svg";
import Lock from "../../assets/svg/Lock.svg";
import { MODAL_TYPES_ENUM } from "../../constants/variables";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { closeModal, selectModal } from "../../store/slices/modal";
import SigninSignupWithCredentialsModal from "../Auth/SigninSignupWithCredentials";

export default function ResetPasswordModal() {
  const modalData = useSelector(selectModal);
  const dispatch = useDispatch();

  const handleCloseModal = () => dispatch(closeModal());
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [codeFocused, setCodeFocused] = useState(false);
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [openCredsModal, setOpenCredsModal] = useState(false);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", "");
  const initUserMessage = {
    msg: "Verification code is sent to your mail",
    color: "black",
  };
  const [userMessage, setUserMessage] = useState(initUserMessage);
  const [showLoader, setShowLoader] = useState(false);
  const [code, setCode] = useState("");
  const [userInput, setUserInput] = useState({
    email: modalData?.link || "",
    password: "",
    firstName: "",
    lastName: "",
  });

  useEffect(() => {
    if (modalData?.link) {
      setUserInput({ ...userInput, email: modalData?.link });
    }
  }, []);

  const handleResetPassword = async () => {
    let { email } = userInput;
    if (!code.trim() || !password.trim()) {
      setUserMessage({ msg: "Please fill all fields", color: "red" });
      return;
    }
    setUserMessage(initUserMessage);
    setShowLoader(true);
    if (!email) {
      email = modalData?.link || "";
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/reset-password`,
        {
          email,
          code,
          newPassword: password,
        },
      );
      if (!res.data.success) {
        const message = res?.data?.message === "user doesnot exist" ? "We couldn't find an account associated with the provided email address. Please double-check the email or consider creating a new account." : res?.data?.message
        setUserMessage({ msg: message, color: "red" });
      } else {
        handleCloseModal();
        setOpenCredsModal(true);
      }
    } catch (error) {
      setUserMessage({ msg: "Something went wrong. Try again", color: "red" });
      console.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={
          modalData.isOpen &&
          modalData.type === MODAL_TYPES_ENUM.resetPassword &&
          !!!accessToken
        }
        onClose={handleCloseModal}
        size="md"
        isCentered
      >
        <ModalOverlay sx={{ background: "#3a83c9a8" }} />
        <ModalContent
          bg="#FFF"
          borderRadius="20px"
          my={140}
          mx={5}
          p={"20px"}
          display="flex"
        >
          <ModalBody
            py={"40px"}
            px="20px"
            backgroundColor={"#F9FAFB"}
            borderRadius={"20px"}
            mt={0}
          >
            <Text
              fontSize="24px"
              lineHeight="36px"
              textAlign="left"
              fontFamily="Poppins"
              fontWeight={600}
              mb={6}
            >
              {"Reset your password"}
            </Text>
            <Text
              fontSize="14px"
              fontFamily="Poppins"
              lineHeight="20px"
              textAlign="left"
              fontWeight={400}
              color={userMessage?.color}
              mb={3}
            >
              {userMessage?.msg}
            </Text>
            <>
              <InputGroup my="14px" position="relative">
                <InputLeftElement py="27px" ms={1}></InputLeftElement>
                <Input
                  focusBorderColor="#D3D5DA"
                  onFocus={() => setCodeFocused(true)}
                  onBlur={() => setCodeFocused(code ? true : false)}
                  onChange={(e) => {
                    setCode(e.target.value.trim());
                  }}
                  value={code}
                  backgroundColor="white"
                  pt="33px"
                  pb="13px"
                  height="55px"
                  fontWeight={400}
                  fontSize="16px"
                  borderRadius="16px"
                  fontFamily="Poppins"
                  border={0}
                  placeholder=" "
                  type="text"
                />
                <FormLabel
                  position="absolute"
                  fontFamily="Poppins"
                  top={codeFocused ? "1px" : 3}
                  color="#9EA3AE"
                  fontWeight={400}
                  fontSize={codeFocused ? "12px" : "16px"}
                  left={10}
                  zIndex={2}
                  cursor="text"
                  onClick={() => setCodeFocused(true)}
                >
                  Verification code
                </FormLabel>
              </InputGroup>
              <InputGroup my="14px" position="relative">
                <InputLeftElement pointerEvents="none" py="27px" ms={1}>
                  <img src={Lock} alt="" />
                </InputLeftElement>
                <Input
                  fontWeight={400}
                  fontSize="16px"
                  height="55px"
                  pt="33px"
                  pb="13px"
                  fontFamily="Poppins"
                  onFocus={() => setPasswordFocused(true)}
                  onBlur={() =>
                    setPasswordFocused(show || password ? true : false)
                  }
                  onChange={(e) => setPassword(e.target.value.trim())}
                  value={password}
                  backgroundColor="white"
                  focusBorderColor="#D3D5DA"
                  borderRadius="16px"
                  border={0}
                  placeholder=""
                  type={show ? "text" : "password"}
                />
                <InputRightElement
                  me={1}
                  py="27px"
                  onClick={() => setShow(!show)}
                  cursor="pointer"
                  children={<img src={show ? Eye : Eyeshow} alt="" />}
                />
                <FormLabel
                  position="absolute"
                  top={passwordFocused ? "1px" : 3}
                  color="#9EA3AE"
                  fontWeight={400}
                  fontSize={passwordFocused ? "12px" : "16px"}
                  left={10}
                  zIndex={2}
                  fontFamily="Poppins"
                  cursor="text"
                  onClick={() => setPasswordFocused(true)}
                >
                  New password
                </FormLabel>
              </InputGroup>
              <Button
                textAlign="center"
                width="100%"
                fontFamily="Poppins"
                color="white"
                borderRadius="8px"
                backgroundColor="#055093"
                boxShadow="0px 2px 3px rgba(0, 0, 0, 0.25)"
                my={"14px"}
                cursor="pointer"
                _hover={{ backgroundColor: "#0A64BC" }}
                textTransform={"uppercase"}
                height={"16px"}
                py={"22px"}
                px={"30px"}
                onClick={() => handleResetPassword()}
              >
                <Text
                  fontWeight={600}
                  fontSize="16px"
                  fontFamily="Poppins"
                  lineHeight="24px"
                >
                  {showLoader ? <Spinner /> : "Save my password and login!"}
                </Text>
              </Button>
            </>
          </ModalBody>
        </ModalContent>
      </Modal>
      {
        (openCredsModal && !!!accessToken) && (
          <SigninSignupWithCredentialsModal
        openModal={openCredsModal && !!!accessToken}
        signinClicked={true}
        setOpenModal={setOpenCredsModal}
        setSigninClicked={setOpenCredsModal}
        fetchLoginStatus={(status) => {
          status && setAccessToken(status || "");
        }}
        userInput={userInput}
        TCChecked={true}
        setOpenTCModal={() => {}}
        setUserInput={setUserInput}
        directSignupWithCredentials={true}
        sendPeriodicUpdates={true}
        onClose={() => {
          setUserInput({
            email: "",
            password: "",
            firstName: "",
            lastName: "",
          })
          window.location.reload();
          
        }}
      />
        )
      }
    </>
  );
}
