import {
    Button,
    Flex,
    Text,
    useToast
} from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { CommonModal } from "../Common";
import { useSelector, useDispatch } from "react-redux";
import { closeModal, selectModal, openModal } from "../../../store/slices/modal";
import { MODAL_TYPES_ENUM } from "../../../constants/variables";
import { useCallback, useEffect, useState } from "react";
import { executeAxiosPost } from "../../../services/common";
import { extractVerificationCode } from "../../../utils/decode";
import { ReactComponent as SuccessIcon } from "../../../assets/svg/icons-success.svg"
import { ReactComponent as WarningIcon } from "../../../assets/svg/warning.svg";
import { subtitleText, titleText } from "../../../constants/styles";

export const VerifyEmailModal = () => {

    const dispatch = useDispatch()
    const modalData = useSelector(selectModal);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const [message, setMessage] = useState<string | null>(null)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const handleVerify = useCallback(async () => {
        setTimeout(async () => {
            try {
                setIsLoading(true);
                /*Decode code is this object{
                    firstName: data.firstName,
                    code:data.code,
                    email: recipientEmail
                  }*/
                const data = extractVerificationCode()

                if (data) {
                    await executeAxiosPost({
                        relativeUrl: "auth/verify-email",
                        data: {
                            ...data
                        },
                    });
                }
                toast({
                    title: "Success",
                    description: "User verified successfully",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                setMessage("User verified successfully")
                setSuccess(true)
                return Promise.resolve(true);
            } catch (error: any) {
                console.log(error);
                setMessage(error?.response?.data?.message ?? "Error when verifying email")
                toast({
                    title: "Error",
                    description: "Error when verifying email",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                setError(true)
                setSuccess(false)
            } finally {
                setIsLoading(false);
            }
        }, 2000)
    }, [toast])

    useEffect(() => {
        if (modalData?.isOpen && modalData?.type === MODAL_TYPES_ENUM.verification) {
            handleVerify()
        }
    }, [handleVerify, modalData?.isOpen, modalData?.type])

    return (
        <CommonModal
            showCloseIcon={false}
            isOpen={modalData.isOpen &&
                modalData?.type === MODAL_TYPES_ENUM.verification
            }
            onClose={() => {
                dispatch(closeModal());
            }}
            modalContentCustomProps={{
                padding: "20px, 20px, 40px, 20px",
                gap: "20px"
            }}
        >
            <Flex>
                <Text
                    fontSize="16px"
                    fontFamily="Poppins"
                    fontWeight="500"
                    lineHeight="24px"
                    display="flex"
                    alignItems="center"
                    alignSelf={"start"}
                >
                    Verify your email
                </Text>
            </Flex>
            <Flex flexDirection={"column"} alignItems={"start"} gap={"10px"}>
                {
                    (message && error) ? <Flex flexDirection={"column"} alignItems={"center"} gap={"5px"} w={'100%'}>
                        <WarningIcon />
                        <Text {...subtitleText}>{message}</Text>
                    </Flex> : (
                        <Flex flexDirection={"column"} alignItems={"center"} gap={"5px"} w={'100%'}>
                            {success && <SuccessIcon />}
                            <Text {...subtitleText}>
                                <FormattedMessage
                                    id="verification.email"
                                    defaultMessage="Your email account is being verified."
                                />
                            </Text>
                        </Flex>
                    )
                }

                <Button
                    colorScheme="blue"
                    w={"100%"}
                    height={"16px"}
                    py={"22px"}
                    px={"30px"}
                    minWidth={"150px"}
                    color={"#F9FAFB"}
                    backgroundColor={error ? '#FF4B4B !important' : "#055093"}
                    borderRadius={"8px"}
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    fontWeight={600}
                    fontFamily={"Poppins"}
                    loadingText="Verifying email..."
                    isLoading={isLoading}
                    isDisabled={!success}
                    mb="20px"
                    onClick={() => {
                        dispatch(closeModal())
                        if (success) {
                            dispatch(openModal({ name: "User", link: "verification", type: "user" }));
                        }
                    }}
                >
                    {error ? "Error when verifying email" : success ? "Go to login" : "Verifying email..."}
                </Button>
            </Flex>
        </CommonModal>
    );
};