import {
  Box,
  Button,
  Progress,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SkeletonCard } from "../components/common/skeleton/card";
import ChangePasswordModal from "../components/Modals/ChangePassword";
import PricingCards from "../components/Modals/PricingModal/pricing-cards";
import SubscriptionDetails from "../components/Modals/SubscriptionDetails/SubscriptionDetails";
import { PaymentMethodStripe } from "../components/Payment/PaymentMethodStripe";
import { PaymentHistory } from "../components/Table/PaymentHistoryTable";
import { PLAN_LIMITS } from "../constants/plans";
import { useLocalStorage } from "../hooks/useLocalStorage";
import DesktopContent from "../layout/MainContent/DesktopContent";
import MobileContent from "../layout/MainContent/MobileContent";
import { useFetchSubscriptionDataQuery } from "../services/userApi";
import { setActualUser } from "../store/slices/user";
import { cancelSubscription,reanudeSubscription } from "../utils/subscription";
import { MODAL_TYPES_ENUM } from '../constants/variables';
import { openModal } from '../store/slices/modal';
import LeftHomeSubView from "../subviews/LeftSubView/Home";
import RightProfileSubView from "../subviews/RightSubview/Profile";
import { submenu } from "../constants/menu";
import { isPresentSubscription } from "../utils/decode";
import { SubscriptionSuccessModal } from "../components/Modals/UserModal/SubscriptionSuccess";
import { CommonMenu } from "../components/common/menu/CommonMenu";
import { IMenu } from "../types/common";
import { CommonModal } from "../components/Modals/Common";
import Portal from "../components/Portal";

export default function ProfilePage() {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [activeIdx, setActiveIdx] = useState(0);
  const [menu] = useState<Array<IMenu>>([
    {
      name: "My account",
      code: "account",
    },
    {
      name: "Subscription",
      code: "subscription",
    },
    {
      name: "Usage",
      code: "usage",
    }
  ]);
  const [activeSubitemIdx, setActiveSubitemIdx] = useState(0);
  const toast = useToast();
  const [isMonthly, setIsMonthly] = useState(false);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", "");
  const [currentView, setCurrentView] = useState("subscriptionDetails");
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY ?? "");
  const {
    isLoading,
    data: subscriptionData,
    refetch,
  } = useFetchSubscriptionDataQuery(accessToken,{
    refetchOnMountOrArgChange: true,
    skip: !localStorage.getItem("accessToken")
  });

  const tabSubMenu = submenu["profile" as string];
  useEffect(() => {
    if (subscriptionData?.subscription?.plan.includes("YEARLY")) {
      setIsMonthly(false);
    } else {
      setIsMonthly(true)
    }
  }, [subscriptionData]);

  const dispatch = useDispatch();

  const handleSignOut = () => {
    localStorage.clear();
    setAccessToken("");
    localStorage.removeItem("accessToken");
    dispatch(setActualUser());
    window.location.replace("/");
  };

  const handlePricingCard = useCallback(() => {
    dispatch(
      openModal({
        name: MODAL_TYPES_ENUM.pricing,
        link: `pricing-card-profile`,
        type: "pricing",
      }),
    );
  },[dispatch])

  useEffect(() => {
    if (isPresentSubscription()) {
      dispatch(openModal({
        name: "Subscription",
        link: "",
        type: MODAL_TYPES_ENUM.subscription,
      }))
    }
  }, [dispatch])

  useEffect(() => {
    if (
      (!subscriptionData?.subscription ||
        subscriptionData.subscription.plan === "free") &&
      currentView !== "pricingCards"
    ) {
      setCurrentView("pricingCards");
    }
  }, [subscriptionData, currentView]);

  useEffect(()=>{
    if(tabSubMenu[activeSubitemIdx]?.name !== "subscription"){
         setCurrentView("subscriptionDetails")
    }
  },[activeSubitemIdx, tabSubMenu])

  const renderComponent = useCallback(() => {
    switch (currentView) {
      case "pricingCards":
        return (
          <PricingCards
            isMonthly={!isMonthly}
            setIsMonthly={(p) => setIsMonthly(!p)}
            showFree={false}
          // showCurrentSubscription
          />
        );
      case "paymentMethod":
        return isLoading ? (
          <SkeletonCard />
        ) : (
          <Elements stripe={stripePromise}>
            <PaymentMethodStripe card={subscriptionData?.paymentMethod} />
          </Elements>
        );
      case "paymentHistory":
        return isLoading ? (
          <SkeletonCard />
        ) : (
             <PaymentHistory data={subscriptionData?.paymentHistory} />
           );
      default:
        return (
          <SubscriptionDetails
            onChangePlan={() => handlePricingCard()}
            onManagePaymentMethod={() => setCurrentView("paymentMethod")}
            onViewPaymentHistory={() => setCurrentView("paymentHistory")}
            onCancelMembership={async () => {
              await cancelSubscription(accessToken, toast, refetch);
            }}
            onReanudeMembership={async () => {
               await reanudeSubscription(toast,refetch)
            }}
            subscriptionData={subscriptionData}
          />
        );
    }
  }, [accessToken, currentView, handlePricingCard, isLoading, isMonthly, refetch, stripePromise, subscriptionData, toast]);

  const RenderMetrics = ({
    metric,
    title,
    isMonthly,
  }: {
    title: string;
    metric: string;
    isMonthly: boolean;
  }) => {
    const metricMap = {
      numAnalysis: "analysis",
      numPortfolio: "portfolio",
      numWatchlist: "watchlist",
    };

    const metricFormatted = metricMap?.[metric];

    const plan = subscriptionData?.subscription?.plan;
    const limits = PLAN_LIMITS[plan];

    const numData = subscriptionData?.usage
      ? subscriptionData.usage?.[metricFormatted].used
      : 0;

    const totalData = subscriptionData?.usage
      ? subscriptionData?.usage?.[metricFormatted].limit
      : limits;

    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"10px"}
        justifyItems={"start"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          width={"100%"}
          alignItems={"center"}
        >
          <Text>{title}</Text>

          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"baseline"}
            gap={"10px"}
          >
            {numData}/ {`${totalData === -1 ? "∞" : totalData}`}
          </Box>
        </Box>

        <Progress
          // value={totalData !== 0 ? (numData * 100) / totalData : 0}
          value={(numData * 100) / totalData}
          max={100}
          size="lg"
          colorScheme="blue"
          width="100%"
          borderRadius={"md"}
        />
      </Box>
    );
  };

  const handleChangePassword = () => {
    dispatch(
      openModal({
        type: MODAL_TYPES_ENUM.changePassword,
        name: "Change Password",
        link: ""
      }),
    );
  };

  const renderUsage = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"20px"}
        m="20px"
      >
        <Text
          fontFamily="Poppins"
          fontStyle="normal"
          fontWeight="600"
          fontSize="18px"
          lineHeight="36px"
          textAlign="left"
          color="#021425"
        >
          Your usage of MonkStreet app in this month
        </Text>

        <RenderMetrics
          metric={"numAnalysis"}
          title={"Stocks Analysed"}
          isMonthly
        />
        <RenderMetrics
          metric={"numPortfolio"}
          title={"Stocks in Portfolio"}
          isMonthly
        />
        <RenderMetrics
          metric={"numWatchlist"}
          title={"Stocks in Watchlist"}
          isMonthly
        />
      </Box>
    )
  }

  const renderProfileActions = () => {
    return (
      <>
        <Button
          colorScheme="blue"
          onClick={handleSignOut}
          w={"100%"}
          h={"16px"}
          color={"#F9FAFB"}
          backgroundColor={"#055093"}
          borderRadius={"8px"}
          fontSize="16px"
          lineHeight={"24px"}
          fontWeight={600}
          fontFamily={"Poppins"}
          py={"22px"}
          px={"30px"}
          _hover={{ backgroundColor: "#0A64BC" }}
          textTransform={"uppercase"}
        >
          {"Log out"}
        </Button>
        <Button
          colorScheme="whiteAlpha"
          onClick={handleChangePassword}
          fontFamily={"Poppins"}
          w={"100%"}
          h={"16px"}
          color="#0564B8"
          backgroundColor={"white"}
          borderRadius={"8px"}
          fontSize="16px"
          lineHeight={"24px"}
          fontWeight={400}
          py={"22px"}
          px={"30px"}
          textTransform={"uppercase"}
        >
          {"Change password"}
        </Button>
      </>
    )
  }

  const renderMobileContents = () => {
    return (
      <Box w={"100%"} display={{ base: "flex", md: "none" }} flexDirection={"column"} gap={"20px"} padding={{ base: "20px", md: "0px" }}>    
              {activeIdx === 0 && <Box
                display="flex"
                flexDirection={"column"}
                gap={"20px"}
                m="20px"
              >
                <Text
                  fontFamily="Poppins"
                  fontStyle="normal"
                  fontWeight="600"
                  fontSize="24px"
                  lineHeight="36px"
                  textAlign="left"
                  color="#021425"
                >
                  Hey {subscriptionData?.user?.firstName}!
                </Text>
                <Text fontFamily="Poppins" fontStyle="normal" fontSize={"sm"}>
                  {subscriptionData?.user?.email}
                </Text>
                {renderProfileActions()}
              </Box>}
              {activeIdx === 1 && <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"20px"}
                mt="20px"
              >
                <Text
                  fontFamily="Poppins"
                  fontStyle="normal"
                  fontWeight="600"
                  fontSize="18px"
                  lineHeight="36px"
                  textAlign="left"
                  color="#021425"
                >
                  Manage Your subscription
                </Text>
                {renderComponent()}
              </Box>}
            {activeIdx === 2 && <Box key={"usage"}>
              {renderUsage()}
            </Box>}
      </Box>
    )
  }

  return (
    <>  
      {!isLargerThan768 && (
        <Portal target={"header-app"}>
          <Box
          display={{ md: "none", base: "flex" }}
          w="100%"
          padding={2}
          alignItems="center"
          justifyContent="center"
          flexDirection={"column"}
          color="#FFF"
          className="topheader"
          mt={"-10px"}
        >
          <Box
            w="100%"
            height={"auto"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"start"}
            transition={"1s all"}
          >
            <Box
              textAlign={"center"}
              w="100%"
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text
                fontSize="32px"
                fontWeight="500"
                lineHeight="35px"
                color="#fff"
                textAlign="center"
                mb="16px"
              >
                Settings
              </Text>
            </Box>
            <CommonMenu
              currentIdx={activeIdx}
              sectionName={menu[activeIdx]?.name ?? ""}
              onSelectMenu={(idx, menu) => {
                setActiveIdx(idx);
              }}
              menu={menu}
            />
          </Box>
        </Box>
        </Portal>
      )}
      <MobileContent>
        <DesktopContent>
          <Box display={{ base: "none", md: "flex" }} w={"100%"} margin={"-10px"}>
            <LeftHomeSubView
              currentIdx={activeSubitemIdx}
              setCurrentIdx={setActiveSubitemIdx}
              currentActiveMenu="profile"
            />
            <RightProfileSubView currentIdx={activeSubitemIdx}>
              {tabSubMenu[activeSubitemIdx]?.name === "account" && (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"20px"}
                  borderWidth="1px"
                  borderRadius="lg"
                  p={6}
                  pt={12}
                  width={{ md: "400px", lg: "500px" }}>
                  <Box>
                    <Text
                      fontFamily="Poppins"
                      fontStyle="normal"
                      fontWeight="600"
                      fontSize="md"
                      lineHeight="36px"
                      textAlign="left"
                      color="#021425"
                    >
                      {subscriptionData?.user?.firstName}
                    </Text>
                    <Text fontFamily="Poppins" fontStyle="normal" fontSize={"sm"}>
                      {subscriptionData?.user?.email}
                    </Text>
                  </Box>
                  <Box w={"100%"} h={"1px"} bgColor={"#999A9B"} opacity={0.4} />
                  {renderProfileActions()}
                </Box>
              )}
              {tabSubMenu[activeSubitemIdx]?.name === "subscription" && (<Box display={"flex"}
                flexWrap={"wrap"}
                gap={"20px"}
                mt="20px">{renderComponent()}</Box>)}
              {tabSubMenu[activeSubitemIdx]?.name === "usage" && (<>{renderUsage()}</>)}
            </RightProfileSubView>
          </Box>
          {renderMobileContents()}
        </DesktopContent>

        <SubscriptionSuccessModal />
        <ChangePasswordModal />
      </MobileContent>
    </>
  );
}
