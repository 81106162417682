import { IStock } from "../types/Stock";
import { WatchlistStock } from "../types/Watchlist";

export function checkGTag() {
    return typeof window !== 'undefined' && window.gtag
}

export function registerPageVisit(pathname: string, firstTime: boolean = false) {
    if (checkGTag()) {
        window.gtag('event', 'page_view', {
            page_path: pathname,
            page_title: window.document.title,
            firstTime: firstTime
        });
    }
}

export function registerViewCompany(stock?: IStock) {
    if (checkGTag() && stock) {
        window.gtag('event', 'view_company', {
            company_id: stock.ticker,
            exchange: stock.exchange,
            full_ticker: `${stock.exchange}:${stock.ticker}`
        });
    }
}

export function registerShare(element: string) {
    if (checkGTag()) {
        window.gtag('event', 'share', {
            type: 'image',
            element
        });
    }

}

export function registerEventCopy(stock?: IStock) {
    if (checkGTag() && stock) {
        window.gtag('event', 'copy_link', {
            company_id: stock.ticker,
            exchange: stock.exchange,
            full_ticker: `${stock.exchange}:${stock.ticker}`
        });
    }
}

export function registerScienceBehind(stock?: IStock) {
    if (checkGTag() && stock) {
        window.gtag('event', 'view_science_behind', {
            company_id: stock.ticker,
            exchange: stock.exchange,
            full_ticker: `${stock.exchange}:${stock.ticker}`
        });
    }
}

export function registerReportError(stock?: IStock) {
    if (checkGTag() && stock) {
        window.gtag('event', 'report_error', {
            company_id: stock.ticker,
            exchange: stock.exchange,
            full_ticker: `${stock.exchange}:${stock.ticker}`
        });
    }
}

export function registerAddWatchlist(stock?: IStock) {
    if (checkGTag() && stock) {
        window.gtag('event', 'add_to_watchlist_from_analysis', {
            company_id: stock.ticker,
            exchange: stock.exchange,
            full_ticker: `${stock.exchange}:${stock.ticker}`,
            source: 'company_analysis'
        });
    }
}

export function registerAddPortofolio(stock?: IStock) {
    if (checkGTag() && stock) {
        window.gtag('event', 'add_to_portfolio_from_analysis', {
            company_id: stock.ticker,
            exchange: stock.exchange,
            full_ticker: `${stock.exchange}:${stock.ticker}`,
            source: 'company_analysis'
        });
    }
}


export function registerAddWatchlistPage(id:string,stock?: WatchlistStock) {
    if (checkGTag() && stock) {
        window.gtag('event', 'add_to_watchlist', {
            watchlist_id: id,
            company_id: stock.ticker,
            exchange: stock.symbol,
            full_ticker: `${stock.symbol}:${stock.ticker}`,
            source: 'watchlist_page'
        });
    }
}

export function registerPortfolioPage(id:string,stock?: WatchlistStock) {
    if (checkGTag() && stock) {
        window.gtag('event', 'add_to_portfolio', {
            portfolio_id: id,
            company_id: stock.ticker,
            exchange: stock.symbol,
            full_ticker: `${stock.symbol}:${stock.ticker}`,
            source: 'watchlist_page'
        });
    }
}

export function registerRemoveFromWatchlist(id:string,stock?: WatchlistStock) {
    if (checkGTag() && stock) {
        window.gtag('event', 'remove_from_watchlist', {
            watchlist_id:id,
            company_id: stock.ticker,
            symbol: stock.symbol,
            full_ticker: `${stock.symbol}:${stock.ticker}`
        });
    }
}

export function registerRemoveFromPortfolio(id:string,stock?: WatchlistStock) {
    if (checkGTag() && stock) {
        window.gtag('event', 'remove_from_portfolio', {
            portfolio_id:id,
            company_id: stock.ticker,
            symbol: stock.symbol,
            full_ticker: `${stock.symbol}:${stock.ticker}`
        });
    }
}

export function registerCreateWatchlist(name: string) {
    if (checkGTag()) {
        window.gtag('event', 'create_watchlist', {
            watchlist_name: name
        });
    }
}

export function registerCreatePortfolio(name: string) {
    if (checkGTag()) {
        window.gtag('event', 'create_portfolio', {
            portfolio_name: name
        });
    }
}

export function registerCreateScreener(name: string) {
    if (checkGTag()) {
        window.gtag('event', 'create_screener', {
            screener_name: name
        });
    }
}

export function registerDeletePortfolio(id: string) {
    if (checkGTag()) {
        window.gtag('event', 'delete_portfolio', {
            portfolio_id: id
        });
    }
}

export function registerDeleteWatchlist(id: string) {
    if (checkGTag()) {
        window.gtag('event', 'delete_watchlist', {
            watchlist_id: id
        });
    }
}

export function registerDeleteScreener(id: string) {
    if (checkGTag()) {
        window.gtag('event', 'delete_screener', {
            watchlscreener_idist_id: id
        });
    }
}

export function registerRenameScreener(id: string, old_name: string, new_name: string) {
    if (checkGTag()) {
        window.gtag('event', 'rename_screener', {
            screener_id: id,
            old_name,
            new_name
        });
    }
}

export function registerRenamePortfolio(id: string, old_name: string, new_name: string) {
    if (checkGTag()) {
        window.gtag('event', 'rename_portfolio', {
            portfolio_id: id,
            old_name,
            new_name
        });
    }
}

export function registerRenameWatchlist(id: string, old_name: string, new_name: string) {
    if (checkGTag()) {
        window.gtag('event', 'rename_watchlist', {
            watchlist_id: id,
            old_name,
            new_name
        });
    }
}

export function registerDownloadScreener(id: string, filter_count: number, company_count: number) {
    if (checkGTag()) {
        window.gtag('event', 'download_screener_csv', {
            screener_id: id,
            filter_count,
            company_count
        });
    }
}


export function registerSearchEvent(searchTerm, resultsCount) {
    if (checkGTag()) {
        window.gtag('event', 'search', {
            search_term: searchTerm,
            results_count: resultsCount
        });
    }
}

export function registerSearchSelectResultEvent(searchTerm, selectedCompany) {
    if (checkGTag()) {
        window.gtag('event', 'search_select_result', {
            search_term: searchTerm,
            selected_company: selectedCompany
        });
    }
}

export function registerError(page: string, error: Error) {
    if (checkGTag()) {
        window.gtag('event', 'error', {
            error_type: error.name,
            error_message: error.message,
            page,
            component: "error_boundaty"
        });
    }
}

export function trackShowUpgradeModal(trigger) {
    if (checkGTag()) {
      window.gtag('event', 'show_upgrade_modal', {
        trigger: trigger
      });
    }
}

export function trackCloseUpgradeModal(timeSpent) {
    if (checkGTag()) {
      window.gtag('event', 'close_upgrade_modal', {
        time_spent: timeSpent
      });
    }
}

export function trackSelectPlan(planName, planPrice, billingPeriod) {
    if (checkGTag()) {
      window.gtag('event', 'select_plan', {
        plan_name: planName,
        plan_price: planPrice,
        billing_period: billingPeriod
      });
    }
}

export function trackBeginCheckout(planName, planPrice, billingPeriod) {
    if (checkGTag()) {
      window.gtag('event', 'begin_checkout', {
        plan_name: planName,
        plan_price: planPrice,
        billing_period: billingPeriod
      });
    }
}

export function trackRedirectToStripe(planName, planPrice, billingPeriod) {
    if (checkGTag()) {
      window.gtag('event', 'redirect_to_stripe', {
        plan_name: planName,
        plan_price: planPrice,
        billing_period: billingPeriod
      });
    }
}

export function trackReturnFromStripe(status, planName, planPrice, billingPeriod) {
    if (checkGTag()) {
      window.gtag('event', 'return_from_stripe', {
        status: status,
        plan_name: planName,
        plan_price: planPrice,
        billing_period: billingPeriod
      });
    }
}

export function trackCancelSubscription(planName) {
    if (checkGTag()) {
      window.gtag('event', 'cancel_subscription', {
        plan_name: planName
      });
    }
}

export function trackDowngradeSubscription(fromPlan, toPlan) {
    if (checkGTag()) {
      window.gtag('event', 'downgrade_subscription', {
        from_plan: fromPlan,
        to_plan: toPlan
      });
    }
}

export function trackAddNewlester(email, date) {
    if (checkGTag()) {
      window.gtag('event', 'register_newsletter', {
        email,
        date
      });
    }
}
