import { jwtDecode, JwtPayload } from "jwt-decode";

export function decodeUserToken(token: string) {
    if (!token) return null
    try {
        const decoded = jwtDecode<JwtPayload & { email: string }>(token);
        return decoded
    } catch (error) {
        console.log("Error when decoded token")
        console.error(error)
    }
}

export function decodeBase64(base64: string): string {
    // Create a Buffer from the Base64 string
    return atob(base64);
}

export function isPresentVerificationCode(): string | null {
    try {
        const urlParams = new URLSearchParams(new URL(window.location.href).search);
        return urlParams.get('code');
    } catch (error) {
        console.error('Error extracting verification code:', error);
        return null;
    }
}

export function isPresentSubscription(): string | null {
    try {
        const urlParams = new URLSearchParams(new URL(window.location.href).search);
        return urlParams.get('subscription');
    } catch (error) {
        console.error('Error extracting verification code:', error);
        return null;
    }
}

export function extractVerificationCode(): {
    firstName: string
    code: string,
    email: string
} | null {
    try {
        const urlParams = new URLSearchParams(new URL(window.location.href).search);
        const code = urlParams.get('code');
        return code ? JSON.parse(decodeBase64(code)) : null;
    } catch (error) {
        console.error('Error extracting verification code:', error);
        return null;
    }
}

export function extractSubscription(): {
    plan: string
    monthly: string,
} | null {
    try {
        const urlParams = new URLSearchParams(new URL(window.location.href).search);
        const code = urlParams.get('subscription');
        return code ? JSON.parse(decodeBase64(code)) : null;
    } catch (error) {
        console.error('Error extracting verification code:', error);
        return null;
    }
}