import { Box, Flex } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import GrowthCard from "../Stocks/CompanyScores/GrowthCard";
import HealthCard from "../Stocks/CompanyScores/HealthCard";
import ProfitabilityCard from "../Stocks/CompanyScores/ProfitabilityCard";
import ShareholderCard from "../Stocks/CompanyScores/ShareholderCard";
import ValuationCard from "../Stocks/CompanyScores/ValuationCard";
import { CommonModal } from "./Common";
import { HeaderItem } from "./HeaderItem";
import TooltipModal  from "../Stocks/tooltipModal/tooltipModal";
import { getFormattedRatioName } from "../../utils/ratios";

interface Props {
  isOpen: boolean;
  activeChart: string;
  type: string;
  onOpen?: () => void;
  onClose: () => void;
}

export function ChartModal({ isOpen, onClose, activeChart, type }: Props) {

  const [isOpenTootlip,setIsOpenTootlip] = useState(false)

  const renderChart = useMemo(() => {
    if (type === "profitability") {
      return <ProfitabilityCard activeChart={activeChart as any} />;
    }

    if (type === "health") {
      return <HealthCard activeChart={activeChart as any} />;
    }

    if (type === "valuation") {
      return <ValuationCard activeChart={activeChart as any} />;
    }

    if (type === "growth") {
      return <GrowthCard activeChart={activeChart as any} />;
    }

    if (type === "shareholder") {
      return <ShareholderCard activeChart={activeChart as any} />;
    }
  }, [activeChart, type]);

  const [showMore, setShowMore] = useState<boolean>(false);

  const adjustedRatioName = useCallback(()=>{
        return getFormattedRatioName(activeChart)
  },[activeChart])

  return (
   <>
    <CommonModal
      isOpen={isOpen}
      onClose={() => {
        setShowMore(false);
        onClose();
      }}
      modalContentCustomProps={{
        padding: {base:"0px",lg:"20px, 20px, 40px, 20px"},
        gap: {base:"0px",lg:"20px"},
      }}
      color={"#FFF"}
    >
    <Box id="renderChart" padding={2}>
      <HeaderItem
          activeChart={activeChart}
          showMore={showMore}
          onShowMore={() => setIsOpenTootlip(true)}
        />
      <Flex flexDirection={"column"} alignItems={"center"} w="100%" mt="10px">
        {renderChart}
      </Flex>
    </Box>
      
    </CommonModal>
     <TooltipModal
     isOpen={isOpenTootlip}
     setIsOpen={setIsOpenTootlip}
     activeChart={adjustedRatioName()}
   />
   </>
  );
}
