import { AnimatePresence } from "framer-motion";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider, useLocation } from "react-router-dom";
import "./App.css";
import { PrivateRoute } from "./components/Auth/PrivateRouter";
import Layout from "./components/Layout";
import IframeModal from "./components/Modals/IframeModal";
import LocaleModal from "./components/Modals/LocaleModal";
import { PricingModal } from "./components/Modals/PricingModal";
import ResetPasswordModal from "./components/Modals/ResetPassword";
import { UserModal } from "./components/Modals/UserModal";
import { TooltipModalProvider } from "./components/Stocks/TooltipModal";
import Company from "./pages/company";
import Disclaimer from "./pages/Disclaimer";
import Home from "./pages/Home";
import Legal from "./pages/Legal";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProfilePage from "./pages/profile_copy";
import { Screener } from "./pages/Screener";
import { WatchList } from "./pages/Watchlist";
import { store } from "./store/store";
import { useEffect, useLayoutEffect } from "react";
import { OneTapGoogle } from "./components/Auth/OneTap";
import { isSafari } from "./utils/browser";
import { SpeedInsights } from '@vercel/speed-insights/react';
import { registerPageVisit } from "./utils/gtagAnalytics";
import { RegistrationSuccessModal } from "./components/Modals/UserModal/RegistrationSuccess";
import { VerifyEmailModal } from "./components/Modals/UserModal/Verification";
import { PlanRoute } from "./components/Auth/PlanRouter";

const PageViewTracker = () => {
  const location = useLocation();
  useEffect(() => {
    const visit = localStorage.getItem(`monkstreet_visit_${location.pathname}`)
    if(!visit){
      localStorage.setItem(`monkstreet_visit_${location.pathname}`,location.pathname)
      registerPageVisit(location.pathname,true)
    }else{
      registerPageVisit(location.pathname)
    }
   
  }, [location]);

  return <></>;
};

const Wrapper = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo({ top: 0, left: 0, behavior: "instant" as any });
  }, [location.pathname]);

  return children;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout showLogo={false} active="home" key={"home"}>
        <PageViewTracker />
        <Home />
      </Layout>
    ),
  },
  {
    path: "/signup",
    element: (
      <Layout showLogo={false} active="signup_home" key={"signup_home"}>
        <PageViewTracker />
        <PlanRoute>
          <Home />
        </PlanRoute>
      </Layout>
    ),
  },
  {
    path: "/stocks/:exchangeTicker/:ticker",
    element: (
      <Wrapper>
      <Layout active="stocks" key={"stocks"}>
        <PageViewTracker />
        <Company />
      </Layout>
      </Wrapper>
    ),
  },
  /* {
    path: "/privacy-policy",
    element: (
      <Layout active="stocks">
        <PageViewTracker />
        <PrivacyPolicy />
      </Layout>
    ),
  },
  {
    path: "/legal",
    element: (
      <Layout active="stocks">
        <PageViewTracker />
        <Legal />
      </Layout>
    ),
  }, */
  /* {
    path: "/disclaimer",
    element: (
      <Layout active="stocks">
        <PageViewTracker />
        <Disclaimer />
      </Layout>
    ),
  }, */
  {
    path: "/watchlist/:id?",
    element: (
      <PrivateRoute>
        <Layout active="watchlist" key={"watchlist"}>
        <PageViewTracker />
          <AnimatePresence>
            <DndProvider backend={HTML5Backend}>
              <WatchList />
            </DndProvider>
          </AnimatePresence>
        </Layout>
      </PrivateRoute>
    ),
  },
  {
    path: "/portfolio/:id?",
    element: (
      <PrivateRoute>
        <Layout active="portfolio" key={"portfolio"}>
        <PageViewTracker />
          <AnimatePresence>
            <DndProvider backend={HTML5Backend}>
              <WatchList is="portfolio" />
            </DndProvider>
          </AnimatePresence>
        </Layout>
      </PrivateRoute>
    ),
  },
  {
    path: "/screener/:id?",
    element: (
      <PrivateRoute>
        <Layout active="screener" key={"screener"}>
        <PageViewTracker />
          <AnimatePresence>
            <Screener />
          </AnimatePresence>
        </Layout>
      </PrivateRoute>
    ),
  },
  {
    path: "/profile",
    element: (
      <PrivateRoute>
        <Layout active="profile" key={"profile"}>
        <PageViewTracker />
          <ProfilePage />
        </Layout>
      </PrivateRoute>
    ),
  },
]);


function App() {
  const accessToken = localStorage.getItem("accessToken");
  
  return (
    <Provider store={store}>
      <TooltipModalProvider>
        {(!accessToken && !isSafari()) && <OneTapGoogle></OneTapGoogle>}
        <RouterProvider router={router} />
      </TooltipModalProvider>
      <IframeModal />
      <LocaleModal />
      <UserModal />
      <PricingModal />
      <ResetPasswordModal />
      <SpeedInsights />
      <RegistrationSuccessModal />
      <VerifyEmailModal />
    </Provider>
  );
}

export default App;
