import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { HiOutlineUserCircle } from "react-icons/hi2";

import { ChangeEvent, useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import CustomLink from "../../components/common/CustomLink";
import MobileStockMenu from "../../components/common/menu/MobileStockMenu";
import Logo from "../../components/Logo";
import { ICompany } from "../../types/Stock";
import CompanySearch from "./CompanySearch";
import { useDispatch, useSelector } from "react-redux";
import { selectActualUser } from "../../store/slices/user";

type TProps = {
  searchValue: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  stocks: ICompany[];
  clearSearch: () => void;
};

function MobileTopContent({
  searchValue = "",
  onChange,
  stocks,
  clearSearch,
}: TProps) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [user, setUser] = useState<{
    firstName: string,
    lastName: string,
    photoURL: string
  } | null>(null)
  const actualUser = useSelector(selectActualUser);

  const isHomePage = pathname === "/" || pathname?.startsWith("/?");
  const isStockPage = pathname?.startsWith("/stocks");
  const isWatchlist =
    pathname?.startsWith("/watchlist") || pathname?.startsWith("/portfolio");
  const isScreener = pathname?.startsWith("/screener");
  const isProfile = pathname?.startsWith("/profile");

  useEffect(() => {
    const usr = localStorage.getItem("usr")
    if (usr) {
      setUser(JSON.parse(usr))
    }


  }, [])


  return (
    <Flex flexDirection="column" width={{ md: "fit-content", base: "100%" }}>
      <Flex
        alignItems="flex-start"
        justifyContent="center"
        position="relative"
        mb={{ md: "0", base: "40px" }}
      >
        <Box display={{ base: isHomePage ? "none" : "block", md: "none" }}>
          <CustomLink
            to={"/"}
            title="Home"
            ariaLabel="Back to Home page"
            withTransition
          >
            <BiChevronLeft fontSize="24px" color="currentColor" />
          </CustomLink>
        </Box>
        <Flex
          alignItems="center"
          columnGap="24px"
          justifyContent="center"
          position="relative"
          ml={{ base: "auto" }}
          pt={{ base: "4px", md: "0" }}
        >
          {/* <CustomLink
            as='button'
            title='Settings'
            ariaLabel='Settings'
            withTransition
            onClick={(e) => {
              e.preventDefault();
              dispatch(openModal({ name: 'Locale', link: '', type: 'locale' }));
            }}
          >
            <IoMdSettings fontSize='30px' color='currentColor' />
          </CustomLink> */}

          <CustomLink
            to={"/profile"}
            title="Profile"
            ariaLabel="Profile"
            withTransition
          // onClick={(e) => {
          //   e.preventDefault();
          //   dispatch(openModal({ name: "User", link: "", type: "user" }));
          // }}
          >
            {(actualUser && actualUser?.firstName) ? <Avatar bgColor="#1CB0F6" name={`${actualUser?.firstName ?? ''} ${actualUser?.lastName ?? ''}`} src={actualUser?.photoURL} /> : <HiOutlineUserCircle fontSize="30px" color="currentColor" />}
          </CustomLink>
        </Flex>
      </Flex>
      {isHomePage && (
        <Box
          display={{ md: "none", base: "flex" }}
          w="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={"column"}
          color="#FFF"
          gap="16px"
        >
          <Box mb="28px">
            <Logo />
          </Box>

          <Flex
            w="100%"
            textAlign="center"
            fontSize="26px"
            lineHeight="29px"
            fontWeight="600"
            alignItems="center"
            justifyContent="center"
            flexDirection={"column"}
            mb="0px"
          >
            <span>Beat the market.</span>
            <span>With science.</span>
            <Text
              mt={"30px"}
              mb={"20px"}
              w={"100%"}
              color="white"
              fontSize={"14px"}
              fontWeight={"400"}
              lineHeight={"19px"}
              align="center"
              maxWidth={"346px"}
              fontFamily="Rubik">
              <span>MonkStreet is a science-backed fundamental </span>
              <span>analysis tool designed to help long-term investors </span>
              <span>beat the market—built on insights from 300+ </span>
              <span>research papers.</span>
            </Text>
          </Flex>

          <CompanySearch
            searchValue={searchValue}
            stocks={stocks}
            onChange={onChange}
            show
            reversed
            mobile
            clearSearch={clearSearch}
          />

          <Text fontSize={"16px"}
            fontWeight={"400"}
            lineHeight={"16px"}
            align="center" my={"20px"}>...or start exploring below</Text>
        </Box>
      )}
      {isStockPage && (
        <Box
          display={{ md: "none", base: "block" }}
          w="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={"column"}
          color="#FFF"
        >
          <Text
            fontSize={"32px"}
            fontWeight={500}
            lineHeight={"35px"}
            mb="16px"
          >
            Stock analysis
          </Text>
          <MobileStockMenu />
        </Box>
      )}
      {isWatchlist && (
        <Box
          display={{ md: "none", base: "block" }}
          w="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={"column"}
          color="#FFF"
          id="header-app"
        ></Box>
      )}
      {isScreener && (
        <Box
          display={{ md: "none", base: "block" }}
          w="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={"column"}
          color="#FFF"
          id="header-app"
        ></Box>
      )}
      {isProfile && (
        <Box
          display={{ md: "none", base: "block" }}
          w="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={"column"}
          color="#FFF"
          id="header-app"
        ></Box>
      )}
    </Flex>
  );
}

export default MobileTopContent;
