import { Box, Flex, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import RatioIcon from "../../assets/svg/ratioiconcolor.svg";
import { InfoButton } from "../common/buttons/info";

const getFormattedRatioName = (activeChart: string) => {
  return activeChart === "Gross Margin"
    ? "grossMargin"
    : activeChart === "Operating Margin"
      ? "operatingMargin"
      : activeChart === "Net Profit Margin"
        ? "netProfitMargin"
        : activeChart === "FCF Margin"
          ? "fcfMargin"
          : activeChart === "ROIC"
            ? "returnOnInvestedCapital"
            : activeChart === "ROE"
              ? "returnOnEquity"
              : activeChart === "ROA"
                ? "returnOnAssets"
                : activeChart === "ROCE"
                  ? "returnOnCapitalEmployed"
                  : activeChart === "Net Debt to Equity"
                    ? "netDebtToEquity"
                    : activeChart === "Interest Coverage"
                      ? "interestCoverage"
                      : activeChart === "Current Ratio"
                        ? "currentRatio"
                        : activeChart === "Quick Ratio"
                          ? "quickRatio"
                          : activeChart === "Cash Conversion Cycle"
                            ? "cashConversionCycle"
                            : activeChart === "Asset Growth"
                              ? "assetGrowth"
                              : activeChart === "External Financing Ratio"
                                ? "externalFinancingRatio"
                                : activeChart === "Percent Accruals"
                                  ? "percentAccruals"
                                  : activeChart === "Dividend Yield"
                                    ? "dividendYield"
                                    : activeChart === "Buyback Ratio"
                                      ? "buybackYield"
                                      : activeChart === "Debt Paydown Ratio"
                                        ? "debtPaydownYield"
                                        : activeChart === "Shareholder Yield"
                                          ? "shareholderYield"
                                          : activeChart === "Revenue Growth"
                                            ? "revenueGrowth"
                                            : activeChart ===
                                                "Oper. Income Growth"
                                              ? "operatingIncomeGrowth"
                                              : activeChart ===
                                                  "Oper. Cash Flow Growth"
                                                ? "operatingCashFlowGrowth"
                                                : activeChart ===
                                                    "Cap. Exp. Growth"
                                                  ? "capitalExpendituresGrowth"
                                                  : activeChart ===
                                                      "Free Cash Flow Growth"
                                                    ? "freeCashFlowGrowth"
                                                    : activeChart ===
                                                        "Growth Trend"
                                                      ? "growthTrend"
                                                      : activeChart ===
                                                          "Growth Predictability"
                                                        ? "growthPredictability"
                                                        : activeChart ===
                                                            "Growth Sustainability"
                                                          ? "growthSustainability"
                                                          : activeChart ===
                                                              "Momentum"
                                                            ? "momentum"
                                                            : activeChart ===
                                                                "Price / Sale"
                                                              ? "priceToSales"
                                                              : activeChart ===
                                                                  "Price / Earnings"
                                                                ? "priceToEarnings"
                                                                : activeChart ===
                                                                    "Price / FCF"
                                                                  ? "priceToFCF"
                                                                  : activeChart ===
                                                                      "Price / Earnings Growth"
                                                                    ? "priceToEarningsGrowth"
                                                                    : activeChart ===
                                                                        "EV / Revenue"
                                                                      ? "evToRevenue"
                                                                      : activeChart ===
                                                                          "EV / Operating Earnings"
                                                                        ? "acquirersMultiple"
                                                                        : activeChart ===
                                                                            "EV / EBITDA"
                                                                          ? "evToEBITDA"
                                                                          : activeChart ===
                                                                              "EV / FCF"
                                                                            ? "evToFCF"
                                                                            : activeChart ===
                                                                                "P/FCF"
                                                                              ? "priceToFCF"
                                                                              : //UNKNOWN RATIOS
                                                                                activeChart ===
                                                                                  "P/S"
                                                                                ? "PS"
                                                                                : activeChart ===
                                                                                    "Sloan Ratio"
                                                                                  ? "sloan_ratio"
                                                                                  : activeChart ===
                                                                                      "GP/Assets"
                                                                                    ? "GP_Assets"
                                                                                    : activeChart ===
                                                                                        "Net Debt to Equity"
                                                                                      ? "NDTE"
                                                                                      : activeChart ===
                                                                                          "External Financing"
                                                                                        ? "Ex_financing"
                                                                                        : activeChart ===
                                                                                            "Net Income"
                                                                                          ? "Net_income"
                                                                                          : activeChart ===
                                                                                              "Shareholder Yield"
                                                                                            ? "shareHolder"
                                                                                            : activeChart ===
                                                                                                "Outstanding Shares"
                                                                                              ? "outstanding"
                                                                                              : activeChart ===
                                                                                                  "Market cap"
                                                                                                ? "mktCap"
                                                                                                : activeChart ===
                                                                                                    "Operating Income"
                                                                                                  ? "operatingIncome"
                                                                                                  : activeChart ===
                                                                                                      "Probability of Default"
                                                                                                    ? "probabilityOfDefault"
                                                                                                    : activeChart ===
                                                                                                        "Operating Cash Flow"
                                                                                                      ? "operatingCashFlow"
                                                                                                      : activeChart ===
                                                                                                          "Capital Expenditure"
                                                                                                        ? "capEx"
                                                                                                        : activeChart ===
                                                                                                            "Long Term Reinvestment Rate"
                                                                                                          ? "longTermReinvestmentRate"
                                                                                                          : activeChart ===
                                                                                                              "R&D to Enterprise Value"
                                                                                                            ? "rdToEnterpriseValue"
                                                                                                            : activeChart ===
                                                                                                                "LTM FCF Growth As Percentage Of Revenue"
                                                                                                              ? "ltmFCFGrowthAsPercentageOfRevenue"
                                                                                                              : activeChart ===
                                                                                                                  "R&D Index Score"
                                                                                                                ? "rdIndexScore"
                                                                                                                : activeChart ===
                                                                                                                    "EV/Operating Income"
                                                                                                                  ? "evToOperatingIncome"
                                                                                                                  : activeChart ===
                                                                                                                      "EV/Net Income"
                                                                                                                    ? "evToNetIncome"
                                                                                                                    : activeChart ===
                                                                                                                        "EV/Assets"
                                                                                                                      ? "evToAssets"
                                                                                                                      : activeChart ===
                                                                                                                          "EV/Operating Income Growth"
                                                                                                                        ? "evToOperatingIncomeGrowth"
                                                                                                                        : activeChart ===
                                                                                                                            "Price / Earnings Growth"
                                                                                                                          ? "priceToEarningsGrowth"
                                                                                                                          : activeChart;
};

interface HeaderItemProps {
  activeChart: string;
  showMore: boolean;
  onShowMore?: () => void;
}

// ratios which doesn't have any contents on notion
const excludedCharts = ["longTermReinvestmentRate"];

export const HeaderItem = ({
  activeChart,
  showMore,
  onShowMore,
}: HeaderItemProps) => {
  return (
    <Flex alignItems={"start"}>
      <Flex flexDirection={"row"} alignItems={"center"} justifyItems={"center"} gap={"5px"}>
        <Text
          fontSize="18px"
          fontWeight={"600"}
          lineHeight={"27px"}
          align="center"
          fontFamily="Poppins"
          noOfLines={2}
        >
          <FormattedMessage id={getFormattedRatioName(activeChart)} />
        </Text>
        <InfoButton onClick={()=> onShowMore && onShowMore()} />
        {/* {
          <Text
            fontWeight="300"
            fontSize="14px"
            lineHeight="21px"
            fontFamily="Poppins"
            noOfLines={showMore ? 10 : 2}
            wordBreak={"break-word"}
            color={"#000000"}
          >
            <FormattedMessage
              id={
                excludedCharts.includes(getFormattedRatioName(activeChart))
                  ? "noId"
                  : `${getFormattedRatioName(activeChart)}_para1`
              }
            />
          </Text>
        } */}

        {/* {showMore && (
          <Box>
            {
              <Text
                fontWeight="300"
                fontSize="14px"
                lineHeight="21px"
                fontFamily="Poppins"
                color={"#000000"}
              >
                <FormattedMessage
                  id={
                    excludedCharts.includes(getFormattedRatioName(activeChart))
                      ? "noId"
                      : `${getFormattedRatioName(activeChart)}_para2`
                  }
                />
              </Text>
            }
            {
              <Text
                fontWeight="300"
                fontSize="14px"
                lineHeight="21px"
                fontFamily="Poppins"
                color={"#000000"}
              >
                <FormattedMessage
                  id={
                    excludedCharts.includes(getFormattedRatioName(activeChart))
                      ? "noId"
                      : `${getFormattedRatioName(activeChart)}_para3`
                  }
                />
              </Text>
            }
          </Box>
        )} */}

        {/* <Text
          fontWeight="300"
          fontSize="14px"
          lineHeight="21px"
          fontFamily="Poppins"
          color={"#0564B8"}
          cursor="pointer"
          onClick={() => onShowMore && onShowMore(!showMore)}
        >
          <FormattedMessage
            id={showMore ? "show_less_link" : "show_more_link"}
          />
        </Text> */}
      </Flex>
    </Flex>
  );
};
