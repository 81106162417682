import { useToast } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { getScreenShotDom2Image, shareContent } from "../utils/image";

export function useScreenShots(elementId: string, isChart?: boolean) {
  const toast = useToast();
  const [blob, setBlob] = useState<Blob>();
  const getScreenShot = useCallback(() => {
    getScreenShotDom2Image(
      localStorage.getItem('idElementForScreenshot') ?? elementId,
      (blobResult) => {
        setBlob(blobResult);
        toast({
          title: `Copied the image to the clipboard.`,
          status: "info",
        });
      },
      () => {
        toast({
          title: `There was an error copying to the clipboard.`,
          status: "error",
          isClosable: true,
        });
      },
      isChart,
    );
  },[elementId, isChart, toast])

  return {
    blob,
    getScreenShot,
  };
}

export function useSharedDom(elementId: string, isChart?: boolean) {
  const toast = useToast();

  const share = useCallback(() => {
    shareContent({
      id: localStorage.getItem('idElementForScreenshot') ?? elementId,
      onError: () => {
        toast({
          title: `Error on share`,
          description: "Reload page if the error persist",
          status: "error",
          isClosable: true,
        });
      },
      isCharts: isChart,
    });
  },[elementId, isChart, toast]);

  return {
    share,
  };
}
