import { Box, useBreakpoint } from "@chakra-ui/react";
import { FC, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import CustomNegativeChart from "../../common/Charts/CustomNegativeChart/CustomNegativeChart";
import BarChart from "../../Charts/BarChart"
export interface CandlestickChatProps {
  lessCurved?: boolean;
  activeChart?: string;
  altColor?: boolean;
  data: any[];
  sign?: any;
  multiplier?: number,
  modal?: boolean
}

const isReverse = (activeChart: string | undefined) => {
  const reverseCharts = [
    "Net Debt to Equity",
    "Cash Conversion Cycle",
    "Asset Growth",
    "External Financing Ratio",
    "Percent Accruals",
    "Cap. Exp. Growth",
    "Price / Sale",
    "Price / Earnings",
    "P/FCF",
    "Price / Earnings Growth",
    "EV / Revenue",
    "EV / Operating Earnings",
    "EV / EBITDA",
    "EV / FCF",
  ];

  return reverseCharts.includes(activeChart ?? "");
};

const CandlestickChart: FC<CandlestickChatProps> = ({
  data,
  activeChart,
  sign,
  multiplier = 1,
  modal
}) => {
  
  const formattedData = useCallback((data: any) => {
    if (data.length === 0) return [];

    const formated = data.map((item, i) => {
      return {
        fullDate: `${item.yearQ}-${item.period}`,
        date: item.yearQ,
        percentile: item.value,
        period: item.period,
      };
    });

    console.log("this is formated data for", activeChart, "sign",sign, "data:",formated)
    return formated
  },[activeChart, sign]);

  return (
    <Box paddingX={"10px"} paddingBottom={"30px"}>
      {/* <CustomNegativeChart
        initialData={formattedData(data)}
        reverse={isReverse(activeChart)}
        activeChart={activeChart}
        sign={sign}
        multiplier={multiplier}
      /> */}
      <BarChart
        renderInModal={modal}
        valueSuffix={sign}
        data={formattedData(data).map((v)=>{
          return {
            year: v.date, quarter: v.period, value: v.percentile ,
          } 
        })}
      /> 
    </Box>
  );
};

export default CandlestickChart;
