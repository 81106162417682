import Bowser from "bowser";
// TypeScript
export function isSafari() {
    const browser = Bowser.parse(window.navigator.userAgent)
    return browser.browser.name === "Safari" && (browser.os.name?.includes("iOS") || browser.os.name?.includes("macOS") || browser.os.name?.includes("OS X") || browser.platform.vendor?.includes("Apple"))
}

export function isMac() {
    const browser = Bowser.parse(window.navigator.userAgent)
    return browser.os.name === "macOs"
}