import { Box, Flex, Text } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";
import { submenu } from "../../../constants/menu";

type TProps = {
  currentIdx: number;
};

function RightProfileSubView({ currentIdx = 0, children }: PropsWithChildren<TProps>) {
  const tabSubMenu = submenu["profile" as string];

  return (
    <Flex
      w="max-content"
      flexDirection="column"
      alignItems="center"
      gap="20px"
      backgroundColor="#FFF"
      px="40px"
      py="30px"
      borderRadius="24px"
      maxWidth={{base:"1800px","xl":"1200px"}}
    >
      <Box w="100%">
        <Text
          fontSize="24px"
          fontWeight="600"
          lineHeight="36px"
          textAlign="left"
        >
          {tabSubMenu?.[currentIdx]?.title}
        </Text>

        <Text
          fontSize="16px"
          fontWeight="300"
          lineHeight="24px"
          textAlign="left"
        >
          {tabSubMenu?.[currentIdx]?.subtitle}
        </Text>
      </Box>
      <Box
        mt="15px"
        w={"100%"}
        h={"max-content"}
        flexWrap={"wrap"}
        gap="10px"
        overflowY={"auto"}
        alignItems={"center"}
        overflow={"hidden"}
      >
        {children}
      </Box>
    </Flex>
  );
}

export default React.memo(RightProfileSubView);
