import { Box, Flex, Text } from "@chakra-ui/react";

import { useCallback, useEffect, useState } from "react";
import { LogoDark, LogoWhite } from "../../../../components/Logo";
import NewCompanyBasicCard from "../../../../components/Stocks/CompanyCard/NewCompanyBasicCard";
import MonkStreetButton from "../../../../components/common/buttons/MonkStreetButton";
import { IStock } from "../../../../types/Stock";
import { iOSSafari } from "../../../../utils/navigator";
import { useDispatch } from "react-redux";
import { openModal,setShareOptions } from "../../../../store/slices/modalShare"
import dayjs from "dayjs";
function CompanyMetaRow({
  compData,
  lastUpdate,
  lightMonkButton = false,
  idElementForScreenshot,
  isChart,
  styledBox,
  onPressShare,
}: {
  compData: IStock;
  lastUpdate: string|Date;
  lightMonkButton?: boolean;
  idElementForScreenshot?: string;
  isChart?: boolean;
  styledBox?: boolean;
  onPressShare?: () => void
}) {
  const [showLogo, setShowLogo] = useState<boolean>(false);
  
  const [isSafari, _] = useState<boolean>(iOSSafari(navigator.userAgent));
  const dispatch = useDispatch();

  const onClickCopyButton = useCallback(() => {
    onPressShare && onPressShare();
    setShowLogo(true);
    setTimeout(()=>{
      localStorage.setItem('idElementForScreenshot',idElementForScreenshot as string)
      dispatch(openModal({ name: "share" }));
      dispatch(setShareOptions({ elemetId: idElementForScreenshot,isChart:isChart }));
    },300)
    //cleanUpCopy();
  },[dispatch, idElementForScreenshot, isChart])

  function cleanUpCopy() {
    setTimeout(() => {
      setShowLogo(false);
    }, 4000);
  }

  useEffect(() => {
    if (showLogo) {
    }
  }, [showLogo]);

  const boxStyle = {
    //mb: "10px",
    px: 4,
    py: 3,
    borderRadius: "20px",
    backgroundColor: "#F9FAFB",
  };

  const boxStyleFn = styledBox ? boxStyle : {};

  return (
    <Box {...boxStyleFn}>
      <Flex gap="0px" alignItems="center" justifyContent={"space-between"}>
        <NewCompanyBasicCard compData={compData} small />
        <Flex
          gap={"2px"}
          flexWrap={"wrap"}
          alignItems={"center"}
          justifyContent={"center"}
          h={"24px"}
        >
          <Text
            fontWeight="500"
            textAlign={{ base: "center", md: "center" }}
            fontSize="8px"
            lineHeight="12px"
            color="#999A9B"
            letterSpacing="-2%"
            w={"max-content"}
          >
            Updated:
          </Text>

          <Text
            fontWeight="500"
            textAlign={{ base: "center", md: "center" }}
            fontSize="8px"
            lineHeight="12px"
            color="#999A9B"
            letterSpacing="-2%"
            w={"max-content"}
            // h={'10px'}
          >
            {dayjs(lastUpdate).format("YYYY-MM-DD")}
          </Text>
        </Flex>
        {showLogo ? (
          <Box color="#6ca2d6" onClick={onClickCopyButton}>
            {lightMonkButton ? <LogoWhite /> : <LogoDark />}
          </Box>
        ) : (
          <MonkStreetButton
            lightMonkButton={lightMonkButton}
            onClick={onClickCopyButton}
            isSafari={isSafari}
          />
        )}
      </Flex>
    </Box>
  );
}

export default CompanyMetaRow;
