import { Navigate } from "react-router-dom";
import { getQueryParams } from "../../utils/navigator";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/slices/modal";
import { MODAL_TYPES_ENUM } from "../../constants/variables";

export const PlanRoute = ({ children, ...rest }) => {
  const isLoggedIn = localStorage.getItem("accessToken");
  const params = getQueryParams()
  const plan = params["plan"]
  const dispatch = useDispatch();
  
  useEffect(()=>{
    if(plan !== "free" && isLoggedIn){
      dispatch(openModal({ name: "Pricing", link: "", type: MODAL_TYPES_ENUM.pricing }))
    }else if(plan !== "free" && !isLoggedIn){
      dispatch(openModal({ name: "User", link: "", type: "user" }))
    }else if(plan.includes("free") && !isLoggedIn){
      dispatch(openModal({ name: "User", link: "free_sign_up", type: "user" }))
    }
  },[dispatch, isLoggedIn, params, plan])
  

  return (
    <>
      {plan !== "free" ? (
        children
      ) : (
        (plan === "free" && isLoggedIn) ? <Navigate to="/profile" replace /> : children 
      )}
    </>
  );
};
