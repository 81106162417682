import { ChangeEvent, useState } from "react";

import { Box, Text } from "@chakra-ui/react";

import axios from "axios";
import Logo from "../../components/Logo";
import { ICompany } from "../../types/Stock";
import CompanySearch from "./CompanySearch";
import { registerSearchEvent } from "../../utils/gtagAnalytics";
import { debounce } from 'lodash';

export function HeroTopBar() {
  const [searchValue, setSearchValue] = useState("");
  const [stocks, setStocks] = useState<ICompany[]>([]);
  const [cache, setCache] = useState(new Map<string, ICompany[]>());

  const fetchSearchStocks = async (searchValue: string) => {
    if (searchValue === "") return;

    if (cache.has(searchValue)) {
      setStocks(cache.get(searchValue) as ICompany[]);
      return;
    }

    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/stocks`,
      {
        params: { limit: 20, search: searchValue },
      },
    );

    const filteredData = res.data.data.filter(
      (e: ICompany, i: number) =>
        res.data.data.findIndex((a: ICompany) => a.symbol === e.symbol) === i &&
        i <= 5,
    );

    setCache((prevCache) => {
      const newCache = new Map(prevCache);
      newCache.set(searchValue, filteredData);
      return newCache;
    });
    registerSearchEvent(searchValue, res.data.data?.length)
    setStocks(filteredData);
  };



  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchValue(newValue);

    if (newValue === "") {
      setStocks([]);
      return;
    }

    fetchSearchStocks(newValue);
  };

  const debouncedFunction = debounce((value) => {
    onChange(value);
  }, 500);

  const clearSearch = () => {
    setSearchValue("");
    setStocks([]);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height="100%"
      fontFamily="Rubik"
      mt={"10px"}
      padding={"10px 60px 20px 60px"}
    >
      <Box mt="20px" mb="28px" color="#fff">
        <Logo />
      </Box>

      <Box
        width={"100%"}
        height={"100%"}
        mb="28px"
        color="#fff"
        textAlign={"center"}
        display="flex"
        flexDirection={"column"}
        justifyContent={"space-around"}
        alignItems={"center"}
      >
        <Box width={"100%"} height={"100%"} gap={"5px"}
          mb="20px" display="flex"
          flexDirection={"column"}
          justifyContent={"space-around"}
          alignItems={"center"}>
          <Text
            color="white"
            fontSize={{ md: "18px", xl: "24px" }}
            fontWeight={"400"}
            lineHeight={"18px"}
            noOfLines={1}
            align="center"
            fontFamily="Rubik"
            mb={"5px"}
          >
            Beat the market. With science.
          </Text>

          <Box maxWidth="560px" w={"100%" } flexDirection={"column"} justifyItems={"center"}>
            <Text
              color="white"
              fontSize={{ md: "10px", xl: "14px" }}
              fontWeight={"400"}
              lineHeight={"18px"}
              align="center"
              fontFamily="Rubik"
              noOfLines={{ md: 3, xl: 2 }}
            >
              MonkStreet is a science-backed fundamental analysis tool designed to help
              long-term investors beat the market—built on insights from 300+ research papers.
            </Text>
          </Box>
        </Box>

        <CompanySearch
          heroStyle={true}
          searchValue={searchValue}
          onChange={debouncedFunction as any}
          stocks={stocks}
          clearSearch={clearSearch}
        />

        <Text
          fontWeight={"400"}
          lineHeight={"29px"}
          fontSize="16px"
          mt={{ lg: "10px", xl: "20px" }}
          mb={{ lg: "10px", xl: "20px" }}
          color="#fff"
        >
          ...or start exploring below
        </Text>
      </Box>
    </Box>
  );
}
