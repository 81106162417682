import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Box
} from "@chakra-ui/react";

import { closeModal, selectModal } from "../../../store/slices/modal";

import { MODAL_TYPES_ENUM } from "../../../constants/variables";
import styles from "./styles.module.css";


function IframeModal() {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const dispatch = useDispatch();
  const modalData = useSelector(selectModal);
  const [loading, setLoading] = useState(false)

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    if (modalData?.isOpen && modalData.type === MODAL_TYPES_ENUM.iframe) {
      setLoading(true)
    }
  }, [modalData])

  const onIframeLoad = () => {
    if (iframeRef.current) {
      (iframeRef.current as HTMLIFrameElement)?.focus();
    }
    setLoading(false)
  };

  return (
    <Modal
      isOpen={modalData?.isOpen && modalData.type === MODAL_TYPES_ENUM.iframe}
      onClose={handleCloseModal}
      // size='full'
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={true}
      blockScrollOnMount={true}
      scrollBehavior="inside"
    >
      <ModalOverlay sx={{ background: "#3a83c9a8" }} />
      <ModalContent
        bg="#F9FaFB"
        borderRadius="20px"
        py={{ base: "20px", md: 0 }}
        maxW="95vw"

      >
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent={modalData?.name ? "space-between" : "flex-end"}
          w="full"
        >
          {modalData?.name && modalData?.name}

          <Button size={"sm"} px="6px" onClick={handleCloseModal}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#667085"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
        </ModalHeader>
        <ModalBody
          px={{ base: "10px", md: "20px", lg: "40px", xl: "40px" }}
          pb={{ md: "20px", lg: "40px", xl: "40px" }}
          fontFamily="Poppins"
          fontSize="sm"
          lineHeight="24px"
          overflow={"hidden"}
          height={'100%'}

        >
          {
            <Flex
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              opacity={loading ? 1 : 0}
              transition="opacity 0.2s ease-in-out"
              pointerEvents={loading ? "auto" : "none"}
              alignItems={"center"}
              alignContent={"space-between"}
              flexDirection={"column"}
              gap={"20px"}
            >
              <Flex justifyContent={"center"}>
                <Spinner color="#055093" size={"xl"} />
              </Flex>
              <Text
                fontWeight="600"
                fontSize="32px"
                lineHeight="24px"
              >
                Loading form...
              </Text>
              <Text
                fontWeight="400"
                fontSize="24px"
                lineHeight="24px"
              >
                This may take a few seconds
              </Text>
            </Flex>
          }
          <Box w="100%" h="100%" opacity={loading ? 0 : 1}
            transition="opacity 0.3s ease-in-out">

            <iframe
              title={modalData?.name}
              src={modalData?.link}
              width="100%"
              height="800"
              className={styles.frame}
              frameBorder="0"
              ref={iframeRef}
              onLoad={onIframeLoad}
            ></iframe>

          </Box>
          {/* <Flex justifyContent={"flex-end"} mt="20px">
            <Button colorScheme="blue" onClick={handleCloseModal}>
              Close
            </Button>
          </Flex> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default IframeModal;
