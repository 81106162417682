import {
  Button,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Eye from "../../assets/svg/Eye.png";
import Eyeshow from "../../assets/svg/Eyeshow.svg";
import Lock from "../../assets/svg/Lock.svg";
import { MODAL_TYPES_ENUM } from "../../constants/variables";
import { useChangePasswordMutation } from "../../services/userApi";
import { closeModal, selectModal } from "../../store/slices/modal";
import { CloseButton, CloseButtonMark } from "../common/buttons/close";
import { CommonButton } from "../common/buttons/common";

export default function ChangePasswordModal() {
  const modalData = useSelector(selectModal);
  const dispatch = useDispatch();
  const toast = useToast();

  const handleCloseModal = () => dispatch(closeModal());
  const [currentPasswordFocused, setCurrentPasswordFocused] = useState(false);
  const [newPasswordFocused, setNewPasswordFocused] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const handleChangePassword = async () => {
    if (!currentPassword || !newPassword) {
      toast({
        title: "Error",
        description: "Please fill all fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const result = await changePassword({
        currentPassword,
        newPassword,
      }).unwrap();

      if (result.success) {
        toast({
          title: "Success",
          description: result.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        handleCloseModal();
      } else {
        toast({
          title: "Error",
          description: result.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          (error as any).data.message ||
          "Something went wrong. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={
        modalData.isOpen && modalData.type === MODAL_TYPES_ENUM.changePassword
      }
      onClose={handleCloseModal}
      size="md"
      isCentered
    >
      <ModalOverlay sx={{ background: "#3a83c9a8" }} />
      <ModalContent
        bg="#FFF"
        borderRadius="20px"
        my={140}
        mx={5}
        p={"20px"}
        display="flex"
      >
        <ModalHeader
          display="flex"
          alignItems="start"
          justifyContent={"flex-end"}
          w="full"
          zIndex={200}
        >
          <CloseButtonMark onClose={()=>handleCloseModal()} />
        </ModalHeader>
        <ModalBody
          py={"40px"}
          px="20px"
          backgroundColor={"#F9FAFB"}
          borderRadius={"20px"}
          mt={0}
        >
          <Text
            fontSize="24px"
            lineHeight="36px"
            textAlign="left"
            fontFamily="Poppins"
            fontWeight={600}
            mb={6}
          >
            Change your password
          </Text>

          <InputGroup my="14px" position="relative">
            <InputLeftElement pointerEvents="none" py="27px" ms={1}>
              <img src={Lock} alt="" />
            </InputLeftElement>
            <Input
              focusBorderColor="#D3D5DA"
              onFocus={() => setCurrentPasswordFocused(true)}
              onBlur={() => setCurrentPasswordFocused(!!currentPassword)}
              onChange={(e) => setCurrentPassword(e.target.value)}
              value={currentPassword}
              backgroundColor="white"
              pt="33px"
              pb="13px"
              height="55px"
              fontWeight={400}
              fontSize="16px"
              borderRadius="16px"
              fontFamily="Poppins"
              border={0}
              placeholder=" "
              type={showCurrentPassword ? "text" : "password"}
            />
            <InputRightElement
              me={1}
              py="27px"
              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
              cursor="pointer"
            >
              <img src={showCurrentPassword ? Eye : Eyeshow} alt="" />
            </InputRightElement>
            <FormLabel
              position="absolute"
              fontFamily="Poppins"
              top={currentPasswordFocused ? "1px" : 3}
              color="#9EA3AE"
              fontWeight={400}
              fontSize={currentPasswordFocused ? "12px" : "16px"}
              left={10}
              zIndex={2}
              cursor="text"
              onClick={() => setCurrentPasswordFocused(true)}
            >
              Current password
            </FormLabel>
          </InputGroup>

          <InputGroup my="14px" position="relative">
            <InputLeftElement pointerEvents="none" py="27px" ms={1}>
              <img src={Lock} alt="" />
            </InputLeftElement>
            <Input
              focusBorderColor="#D3D5DA"
              onFocus={() => setNewPasswordFocused(true)}
              onBlur={() => setNewPasswordFocused(!!newPassword)}
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              backgroundColor="white"
              pt="33px"
              pb="13px"
              height="55px"
              fontWeight={400}
              fontSize="16px"
              borderRadius="16px"
              fontFamily="Poppins"
              border={0}
              placeholder=" "
              type={showNewPassword ? "text" : "password"}
            />
            <InputRightElement
              me={1}
              py="27px"
              onClick={() => setShowNewPassword(!showNewPassword)}
              cursor="pointer"
            >
              <img src={showNewPassword ? Eye : Eyeshow} alt="" />
            </InputRightElement>
            <FormLabel
              position="absolute"
              fontFamily="Poppins"
              top={newPasswordFocused ? "1px" : 3}
              color="#9EA3AE"
              fontWeight={400}
              fontSize={newPasswordFocused ? "12px" : "16px"}
              left={10}
              zIndex={2}
              cursor="text"
              onClick={() => setNewPasswordFocused(true)}
            >
              New password
            </FormLabel>
          </InputGroup>

          <Button
            textAlign="center"
            width="100%"
            fontFamily="Poppins"
            color="white"
            backgroundColor="#085096"
            boxShadow="0px 2px 3px rgba(0, 0, 0, 0.25)"
            my={"14px"}
            cursor="pointer"
            borderRadius="8px"
            textTransform={"uppercase"}
            height={"16px"}
            py={"22px"}
            px={"30px"}
            _hover={{ backgroundColor: "#0A64BC" }}
            onClick={handleChangePassword}
          >
            <Text
              fontWeight={600}
              fontSize="16px"
              fontFamily="Poppins"
              lineHeight="24px"
            >
              {isLoading ? <Spinner /> : "Change password"}
            </Text>
          </Button>
          <CloseButton onClose={()=>handleCloseModal()} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
