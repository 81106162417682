export function quickShare(
  shareData: any,
  onSuccess?: () => void,
  onError?: () => void,
) {
  if (navigator.share) {
    navigator
      .share(shareData)
      .then(() => {
        onSuccess && onSuccess();
      })
      .catch((error) => {
        console.error("Share failed:", error);
        onError && onError();
      });
  } else {
    console.log("Web Share API not supported");
  }
}

export const copyContent = async (text:string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
}

export const scrollInto = () => {
  const link = new URL(window.location.href)
  const id = link.searchParams.get("idScrollable")
  if(id){
    const element = document.getElementById(id);
  if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
  }else{
    window.location.hash = id;
  }
  }
}
