import { ChangeEvent, useEffect, useState } from "react";

import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Input,
  Stack,
  Text,
  useBreakpoint,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { HiMagnifyingGlass } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { ICompany } from "../../types/Stock";

import styles from "./styles.module.css";
import { registerSearchSelectResultEvent } from "../../utils/gtagAnalytics";

type TProps = {
  searchValue: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  show?: boolean;
  reversed?: boolean;
  mobile?: boolean;
  stocks: ICompany[];
  clearSearch: () => void;
  heroStyle?: boolean;
};

function CompanySearch({
  searchValue = "",
  onChange,
  show = false,
  reversed = false,
  mobile = false,
  stocks,
  clearSearch,
  heroStyle = false,
}: TProps) {
  const intl = useIntl();
  const navigate = useNavigate();

  const [innerSearchValue, setInnerSearchValue] = useState<ChangeEvent<HTMLInputElement>>()
  const [debouncedValue, setDebouncedValue] = useState(searchValue);

  // Debounce effect
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(innerSearchValue?.target?.value as string); // Set the debounced value after delay
      
        onChange(innerSearchValue as any)
      
    }, 700); // Delay of 700ms

    return () => {
      clearTimeout(timeoutId); // Cleanup the timeout if inputValue changes before the delay
    };
  }, [innerSearchValue]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInnerSearchValue(e)
  }


  return (
    <Flex
      alignItems="center"
      columnGap={"10px"}
      justifyContent="flex-start"
      width="full"
      height="60px"
      py="21px"
      px="18px"
      borderRadius="12px"
      maxWidth="658px"
      position="relative"
      background="#FFFFFF1A"
      display={{ md: "flex", base: show ? "flex" : "none" }}
      flexDirection={reversed ? "row-reverse" : "row"}
      zIndex={heroStyle ? 0 : 200}
    >
      <Input
        value={debouncedValue}
        onChange={onChange}
        size="md"
        border="0"
        fontSize={mobile ? "14px" : "20px"}
        fontWeight="light"
        color={"#FFF"}
        placeholder={intl.formatMessage({ id: "search_placeholder" })}
        _placeholder={{ color: "#FFF", fontSize: mobile ? "16px" : "19px", padding: "2px" }}
        pl="0"
        backgroundColor="transparent"
        padding={"5px"}
        sx={{
          WebkitTextSizeAdjust: "100%",
          fontSize: "16px",
          textSizeAdjust: "100%"
        }}
        onFocus={(e) => {
          //prevent css error
         ( document.body.style as any).zoom = "100%";
        }}
        onKeyDown={(e) => {
          if(e.code === "Enter" && stocks.length > 0){
            registerSearchSelectResultEvent(searchValue,stocks[0].ticker)
            navigate(
              `/stocks/${stocks[0].ticker.split(":")[0]}/${
                stocks[0].ticker.split(":")[1]
              }`,
            );
            clearSearch()
          }
        }}
      />

      <HiMagnifyingGlass fontSize="30px" fontWeight="300" color="#CEE0F1" />

      {stocks?.length > 0 && searchValue ? (
        <Box
          position={"absolute"}
          top={"52px"}
          left={0}
          width={"100%"}
          backgroundColor={"#04284B"}
          color={"#FFF"}
          className={styles.stocksSearchWrapper}
          zIndex={200}
        >
          <Box
            w={"100%"}
            backgroundColor={!heroStyle ? "#FFFFFF1A" : "#16150A"}
            px="18px"
            pb="12px"
            pt="8px"
            borderBottomLeftRadius={"12px"}
            borderBottomRightRadius={"12px"}
          >
            {!heroStyle && <Divider mb="4" />}

            <Stack spacing={2} width="full">
              {stocks?.map((stock, index) => (
                <Grid
                  templateColumns="repeat(12, 1fr)"
                  style={{ cursor: "pointer" }}
                  gap={2}
                  key={stock.ticker || `stock-${index}`}
                  fontFamily="Poppins"
                  fontSize={mobile ? "14px" : "16px"}
                  className={styles.stockSearchItem}
                  onClick={() => {
                    registerSearchSelectResultEvent(searchValue,stock.ticker)
                    navigate(
                      `/stocks/${stock.ticker.split(":")[0]}/${
                        stock.ticker.split(":")[1]
                      }`,
                    );
                    clearSearch();
                  }}
                >
                  <GridItem display={{base:"flex",md:"none"}} colSpan={{base:5,sm:3}} placeItems={"center"}>
                    <Text fontWeight="bold">{stock.ticker.split(":")[0]}:{stock.ticker.split(":")[1]}</Text>
                  </GridItem>
                  <GridItem display={{base:"flex",md:"none"}} colSpan={{base:7,sm:9}} placeItems={"center"}>
                  <Text width="full" noOfLines={1}>
                      {stock.companyName}
                    </Text>
                  </GridItem>
                  
                  <GridItem display={{base:"none",md:"flex"}} colSpan={mobile ? 4 : 3}>
                    <Text fontWeight="bold">{stock.ticker.split(":")[1]}</Text>
                  </GridItem>

                  <GridItem display={{base:"none",md:"flex"}} colSpan={mobile ? 5 : 6}>
                    <Text width="full" noOfLines={1}>
                      {stock.companyName}
                    </Text>
                  </GridItem>

                  <GridItem display={{base:"none",md:"flex"}} colSpan={3}>
                    <Text fontWeight="medium" noOfLines={1} textAlign="right">
                      {stock.ticker.split(":")[0]}
                    </Text>
                  </GridItem>
                </Grid>
              ))}
            </Stack>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </Flex>
  );
}

export default CompanySearch;
