export const titleText: any = {
    fontSize:{ base: "18px", lg: "18px", xl: "18px" },
    fontWeight: "600",
    lineHeight:"27px",
    align:"center",
    fontFamily:"Poppins",
    noOfLines:{ base: 3, md: 2 }
}

export const subtitleText: any = {
    fontWeight:"300",
    fontSize:"16px",
    lineHeight:"24px",
    fontFamily:"Poppins",
    noOfLines:{ base: 4, md: 3, lg: 2 },
    mb:"20px",
}