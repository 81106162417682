import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { roundNumber } from '../../utils/Math';

type DataPoint = {
  year: string;
  quarter: string;
  value: number;
};

type TooltipData = {
  x: number;
  y: number;
  data: DataPoint;
  position: 'top' | 'bottom';
};

type BarChartProps = {
  data: DataPoint[];
  valuePrefix?: string;
  valueSuffix?: string;
  renderInModal?: boolean
  roundExact?: boolean;
};

const BarChart: React.FC<BarChartProps> = ({ data, valuePrefix = '', valueSuffix = '', renderInModal = false, roundExact = false }) => {
  const [tooltip, setTooltip] = useState<TooltipData | null>(null);
  const [hoveredBar, setHoveredBar] = useState<string | null>(null);
  const [dimensions, setDimensions] = useState({ width: 800, height: 300 });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const containerWidth = Math.min(containerRef.current.clientWidth, window.innerWidth - 40);
        const isMobile = window.innerWidth < 500;
        setDimensions({
          width: containerWidth,
          height: isMobile ? 200 : 300,
        });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const margin = useMemo(() => {
    return {
      top: 20,
      right: (() => {
        if (!renderInModal) {
          if (dimensions.width < 300) return Math.max(80, dimensions.width * 0.025);
          if (dimensions.width < 360) return Math.max(60, dimensions.width * 0.025);
          if (dimensions.width < 400) return Math.max(40, dimensions.width * 0.025);
          if (dimensions.width < 500) return Math.max(30, dimensions.width * 0.025);
        }
        return Math.max(20, dimensions.width * 0.025);
      })(),
      bottom: 40,
      left: (dimensions.width < 400 && !renderInModal) ? Math.max(20, dimensions.width * 0.075) : Math.max(40, dimensions.width * 0.075),
    };
  }, [dimensions.width, renderInModal])

  const years = Array.from(new Set(data.map(d => d.year))).sort();
  const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

  const xScale = scaleBand<string>({
    domain: years,
    range: [margin.left, dimensions.width - margin.right],
    padding: 0,
  });

  const xSubScale = scaleBand<string>({
    domain: quarters,
    range: [0, xScale.bandwidth()],
    padding: window.innerWidth < 500 ? 0.25 : 0.2,
  });

const { minValue, maxValue, allNegative, hasNegativeValues, tickCount } = useMemo(() => {
  const minValue = Math.min(...data.map(d => d.value));
  const maxValue = Math.max(...data.map(d => d.value));
  const allNegative = maxValue <= 0;
  const hasNegativeValues = minValue < 0 ;
  const tickCount = Math.max(5, Math.ceil(Math.abs(maxValue - minValue) / 10));

  return {
    minValue,
    maxValue,
    allNegative,
    hasNegativeValues,
    tickCount
  };
}, [data]);

  const yScale = scaleLinear<number>({
    domain: allNegative ? [minValue,0] : [hasNegativeValues ? minValue : 0, maxValue],
    range: [dimensions.height - margin.bottom, margin.top],
    nice: true
  });

  const customTicks = useCallback(()=>{
     return allNegative ? 
     Array.from({ length: tickCount }, (_, i) => 
       minValue + (i * (Math.abs(minValue) / (tickCount - 1)))
     ) : 
     yScale.ticks(5);
  },[allNegative, minValue, tickCount, yScale])

  const handleMouseMove = (_: React.MouseEvent | React.TouchEvent, d: DataPoint) => {
    const barWidth = xSubScale.bandwidth();
    const barX = (xScale(d.year) || 0) + (xSubScale(d.quarter) || 0);
    const barCenterX = barX + barWidth / 2;
    const y = yScale(d.value);
    
    const tooltipWidth = dimensions.width < 600 ? 80 : 100;
    const tooltipHalfWidth = tooltipWidth / 2;
    
    let x = barCenterX - tooltipHalfWidth;
    
    if (x < margin.left) {
      x = margin.left;
    }
    
    if (x + tooltipWidth > dimensions.width - margin.right) {
      x = dimensions.width - margin.right - tooltipWidth;
    }
    
    const spaceAbove = y - margin.top;
    const tooltipHeight = 70;
    const position = spaceAbove < tooltipHeight ? 'bottom' : 'top';
    
    setTooltip({
      x,
      y,
      data: d,
      position,
    });
    setHoveredBar(`${d.year}-${d.quarter}`);
  };

  const handleMouseLeave = () => {
    setTooltip(null);
    setHoveredBar(null);
  };

  const isMobile = dimensions.width < 500;
  const fontSize = isMobile ? 8 : 10;
  const yearFontSize = isMobile ? 8 : 10;
  const quarterFontSize = isMobile ? 8 : 10;
  const subscriptFontSize = isMobile ? 5 : 6;
  const fiscalFontSize = isMobile ? 5 : 6;

  const quarterLabelY = dimensions.height - margin.bottom + 15;
  const yearLabelY = dimensions.height - margin.bottom + 30;

  const cornerRadius = isMobile ? xSubScale.bandwidth() / 2 : Math.min(12, xSubScale.bandwidth() * 0.25);

  return (
    <div ref={containerRef} style={{ width: '100%', minWidth: '300px', overflow: 'hidden' }}>
      <svg width={dimensions.width} height={dimensions.height}>
        {hasNegativeValues && (
          <line
            x1={margin.left}
            x2={dimensions.width - margin.right}
            y1={yScale(0)}
            y2={yScale(0)}
            stroke="#E2E8F0"
            strokeWidth={2}
          />
        )}

        {customTicks().map(tick => (
          <line
            key={tick}
            x1={margin.left}
            x2={dimensions.width - margin.right}
            y1={yScale(tick)}
            y2={yScale(tick)}
            stroke="#E2E8F0"
            strokeDasharray="2,2"
          />
        ))}

        {customTicks().map(tick => (
          <text
            key={tick}
            x={margin.left - 10}
            y={yScale(tick)}
            textAnchor="end"
            alignmentBaseline="middle"
            fontSize={fontSize}
            fill="#A0AEC0"
          >
            {valuePrefix}{tick}{valueSuffix}
          </text>
        ))}

        {years.map((year, index) => {
          if (index === 0) return null;
          const x = xScale(year) || 0;
          return (
            <line
              key={year}
              x1={x}
              x2={x}
              y1={dimensions.height - margin.bottom}
              y2={dimensions.height - margin.bottom + 15}
              stroke="#E2E8F0"
              strokeWidth={1}
            />
          );
        })}

        <Group>
          {years.map(year => (
            quarters.map(quarter => {
              const dataPoint = data.find(d => d.year === year && d.quarter === quarter);
              if (!dataPoint) return null;

              const barWidth = xSubScale.bandwidth();
              const x = (xScale(year) || 0) + (xSubScale(quarter) || 0);
              const y = Math.min(yScale(0), yScale(dataPoint.value));
              const height = Math.abs(yScale(dataPoint.value) - yScale(0));
              const barId = `${year}-${quarter}`;
              const isPositive = dataPoint.value >= 0;

              return (
                <svg key={barId}>
                  <path
                    d={isPositive ? `
                      M ${x},${y + height}
                      L ${x},${y + cornerRadius}
                      Q ${x},${y} ${x + cornerRadius},${y}
                      L ${x + barWidth - cornerRadius},${y}
                      Q ${x + barWidth},${y} ${x + barWidth},${y + cornerRadius}
                      L ${x + barWidth},${y + height}
                      Z
                    ` : `
                      M ${x},${y}
                      L ${x},${y + height - cornerRadius}
                      Q ${x},${y + height} ${x + cornerRadius},${y + height}
                      L ${x + barWidth - cornerRadius},${y + height}
                      Q ${x + barWidth},${y + height} ${x + barWidth},${y + height - cornerRadius}
                      L ${x + barWidth},${y}
                      Z
                    `}
                    fill={hoveredBar === barId ? '#055093' : '#c3d7eb'}
                    onMouseMove={(e) => handleMouseMove(e, dataPoint)}
                    onTouchStart={(e) => handleMouseMove(e, dataPoint)}
                    onMouseLeave={handleMouseLeave}
                    onTouchEnd={handleMouseLeave}
                    style={{ cursor: 'pointer', transition: 'fill 0.2s ease' }}
                  />
                </svg>
              );
            })
          ))}
        </Group>

        {years.map(year => (
          <g key={year}>
            <text
              x={(xScale(year) || 0) + xScale.bandwidth() / 2}
              y={isMobile ? yearLabelY-18 : yearLabelY}
              textAnchor="middle"
              fill="#A0AEC0"
            >
              <tspan fontSize={yearFontSize}>{year}</tspan>
              {<tspan
                dx="1"
                dy="2"
                fontSize={fiscalFontSize}
              >{isMobile ? 'f' : 'fiscal'}</tspan>}
            </text>
          </g>
        ))}

        {!isMobile && years.map(year => (
          quarters.map(quarter => {
            const x = (xScale(year) || 0) + (xSubScale(quarter) || 0) + xSubScale.bandwidth() / 2;
            return (
              <text
                key={`${year}-${quarter}`}
                x={x}
                y={quarterLabelY}
                textAnchor="middle"
                fill="#A0AEC0"
              >
                <tspan fontSize={quarterFontSize}>Q</tspan>
                <tspan
                  fontSize={subscriptFontSize}

                  dy="2"
                  dx="1"
                >
                  {quarter.slice(1)}
                </tspan>
              </text>
            );
          })
        ))}

        <line
          x1={margin.left}
          x2={dimensions.width - margin.right}
          y1={dimensions.height - margin.bottom}
          y2={dimensions.height - margin.bottom}
          stroke="#E2E8F0"
        />


{tooltip && (
          <g style={{ pointerEvents: 'none' }}>
            <foreignObject
              x={tooltip.x-20}
              y={tooltip.position === 'top' ? tooltip.y - 80 : tooltip.y + 10}
              width={dimensions.width < 600 ? "120" : "160"}
              height="70"
            >
              <div
                style={{
                  background: 'white',
                  color: '#0A64BC',
                  padding: '8px',
                  borderRadius: '4px',
                  fontSize: dimensions.width < 600 ? '10px' : '12px',
                  boxShadow: '0 2px 2px rgba(0, 0, 0, 0.5)',
                  textAlign: 'center',
                  position: 'relative',
                  fontFamily: 'Poppins',
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'center', gap: '8px', alignItems: 'center' }}>
                  <p style={{ fontWeight: 400, margin: 0  }}>
                    {tooltip.data.year} {tooltip.data.quarter}:
                  </p>
                  <p style={{ fontWeight: 400 }}>
                    {valuePrefix.includes('days') ? ' d' : `${valuePrefix} `}{roundExact ? Number(tooltip.data.value).toFixed(0) :  roundNumber(tooltip.data.value)}{valueSuffix.includes('days') ? ' d' : ` ${valueSuffix}`}
                  </p>
                </div>
              </div>
            </foreignObject>
          </g>
        )}
      </svg>
    </div>
  );
};

export default BarChart;