import {
    Avatar,
    Box,
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectActualUser } from "../../../store/slices/user";
import { RenderMetrics } from "../../metrics/usageMetrics";
import { useFetchSubscriptionDataQuery } from "../../../services/userApi";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

export function LoggedUserModal({
    open,
    setOpen,
    setHandleUpgradeModal
}: {
    open: boolean;
    setOpen: () => void;
    setHandleUpgradeModal: () => void;
}) {

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", localStorage.getItem('accessToken'));
    const {
        data: subscriptionData = {}
    } = useFetchSubscriptionDataQuery(accessToken,{
        refetchOnMountOrArgChange:true,
        skip: !localStorage.getItem('accessToken')
    });

    const actualUser = useSelector(selectActualUser);
    const [user, setUser] = useState(() => {
        if (typeof window === "undefined") {
            return null;
        }

        try {
            const item = localStorage.getItem("usr");
            return item ? JSON.parse(item) : null;
        } catch (error) {
            console.log(error);
            return null;
        }
    });


    return (
            <Modal
                isOpen={open}
                onClose={() => setOpen()}
                size="sm"
                isCentered
                scrollBehavior="inside"
                blockScrollOnMount={true}
            >
                <ModalOverlay sx={{ background: "#3a83c9a8" }} />
                <ModalContent bg="#FFF" borderRadius="20px" p={"20px"} width={"100%"}>
                    <ModalBody
                        display="flex"
                        py={"10px"}
                        px="10px"
                        flexDir="column"
                        backgroundColor={"#FFF"}
                        borderRadius={"20px"}
                        rowGap={"30px"}
                        mt={0}
                        alignItems={"center"}
                        width={"100%"}
                        sx={{
                            "&::-webkit-scrollbar": {
                                width: "5px",
                            },
                            "&::-webkit-scrollbar-track": {
                                width: "6px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                background: "#0A64BC",
                                borderRadius: "24px",
                            },
                        }}
                    >
                        <Avatar
                            size={"xl"}
                            bgColor="#1CB0F6"
                            name={`${actualUser?.firstName ?? ''} ${actualUser?.lastName ?? ''}`}
                            src={actualUser?.photoURL} />

                        <Text
                            fontFamily="Poppins"
                            fontStyle="normal"
                            fontWeight="600"
                            fontSize="24px"
                            lineHeight="36px"
                            textAlign="center"
                            color="#000000"
                        >
                            {`Hello ${user && user?.firstName ? `${user?.firstName}` : ""}!`}
                        </Text>

                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            gap={"10px"}
                            alignSelf={"flex-start"}
                            width={"100%"}
                        >
                            <Text
                                fontFamily="Poppins"
                                fontStyle="normal"
                                fontWeight="600"
                                fontSize="16px"
                                lineHeight="24px"
                                textAlign="left"
                                color="#021425"
                            >
                                Your usage this month
                            </Text>

                            <RenderMetrics
                                subscriptionData={subscriptionData}
                                metric={"analysis"}
                                title={"Stocks Analysed"}
                                defaults={[0, 5]}
                            />
                            <RenderMetrics
                                subscriptionData={subscriptionData}
                                metric={"portfolio"}
                                title={"Stocks in Portfolio"}
                                defaults={[0, 1]}
                            />
                            <RenderMetrics
                                subscriptionData={subscriptionData}
                                metric={"watchlist"}
                                title={"Stocks in Watchlist"}
                                defaults={[0,1]}
                            />
                        </Box>


                        <Button
                            colorScheme="blue"
                            onClick={() => setOpen()}
                            w={"100%"}
                            textTransform={"uppercase"}
                            height={"16px"}
                            py={"22px"}
                            px={"30px"}
                            minWidth={"150px"}
                            color={"#F9FAFB"}
                            backgroundColor={"#055093"}
                            borderRadius={"8px"}
                            fontSize={"16px"}
                            lineHeight={"24px"}
                            fontWeight={600}
                            fontFamily={"Poppins"}
                        >
                            {"Continue exploring"}
                        </Button>
                        <Button
                            colorScheme="whiteAlpha"
                            onClick={() => setHandleUpgradeModal()}
                            fontFamily={"Poppins"}
                            w={"100%"}
                            textTransform={"uppercase"}
                            height={"16px"}
                            py={"22px"}
                            px={"30px"}
                            minWidth={"150px"}
                            color="#0564B8"
                            backgroundColor={"white"}
                            borderRadius={"8px"}
                            fontSize={"16px"}
                            lineHeight={"24px"}
                            fontWeight={600}
                        >
                            {"Upgrade subscription"}
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
    );
}
