import axios from "axios";
import { APIData, Attributes } from "../types/strapi";

const STRAPI_URL = "https://cms.monk.st/api";

export default async function getPricingDataFromStrapi(): Promise<APIData | null | undefined> {
    
    try {
      const res = await axios(
        `${STRAPI_URL}/pricing?locale=en`,
        {
            method:"GET",
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
              },
        }
      );
  
      if (res.status !== 200) {
        console.log('error (getPricingData.tsx:13):', res?.statusText);
        return null;
      }
      return res.data?.data;
    } catch (error) {
      console.log('getPricingData error', error);
    }
  }