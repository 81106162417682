import { Flex } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

export function RightSubView(props: PropsWithChildren) {
  return (
    <Flex
      w="100%"
      h="auto"
      flexDirection="column"
      alignItems="flex-start"
      gap="20px"
      backgroundColor="#FFF"
      px={{ base: "20px", md: "40px" }}
      borderRadius="30px"
      flexGrow="1"
      overflow={"auto"}
      marginTop={{ base: "10px", md: "0px" }}
      maxWidth={{base:"1800px","xl":"1200px"}}
    >
      {props.children}
    </Flex>
  );
}
