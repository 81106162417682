import { Badge, Box, Text } from "@chakra-ui/react";
import {
  ChakraStylesConfig,
  MultiValue,
  Select,
  useChakraSelectProps,
} from "chakra-react-select";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { countrySelect } from "../../constants/country";
import { exchangeSelect } from "../../constants/exchange";
import { industriesSelect } from "../../constants/industries";
import { numberOperators, stringOperators } from "../../constants/items";
import { sectorSelect } from "../../constants/sector";
import {
  optionsPortfolio,
  optionsWatchlist,
} from "../../store/slices/watchlist";
import type { Filter } from "../../types/Screener";
import {
  findLabelInValue,
  findLabelInValueMulti,
  resolveNameProperty,
} from "../../utils/select";
import {
  TextBtwNumberInput,
  TextNumberInput,
} from "../common/Text/TextNumberInput";
import { TextPropertyInput } from "../common/Text/TextPropertyInput";
interface FilterViewObjectProps {
  filter: Filter;
  onRemove: () => void;
  onChangeFilterParams: (filter: Filter) => void;
}

/**
 *
 * @returns
 */

export function FilterViewObject({
  filter,
  onRemove,
  onChangeFilterParams,
}: FilterViewObjectProps) {
  const listWatchlistOptions = useSelector(optionsWatchlist);
  const listPortfolioOptions = useSelector(optionsPortfolio);

  const [options, setOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [capitalizationOptions, setCapitalizationOptions] = useState<
    Array<{ label: string; value: string }>
  >([
    {
      label: "Unit",
      value: "unit",
    },
    {
      label: "Thousand",
      value: "thousand",
    },
    {
      label: "Million",
      value: "million",
    },
    {
      label: "Billion",
      value: "billion",
    },
  ]);
  const [selectedCapitalizationOptions, setSelectedCapitalizationOptions] =
    useState<{ label: string; value: string }>({
      label: "Million",
      value: "million",
    });

  const [selectedOptions, setSelectedOptions] = useState<{
    label?: string;
    value: string;
  }>();
  const [selectedWatchlist, setSelectedWatchlist] =
    useState<MultiValue<{ label?: string; value: string }>>();
  const [selectedPortfolio, setSelectedPortfolio] =
    useState<MultiValue<{ label?: string; value: string }>>();
  const [selectedIndustry, setSelectedIndustry] =
    useState<MultiValue<{ label?: string; value: string }>>();
  const [selectedCountry, setSelectedCountry] =
    useState<MultiValue<{ label?: string; value: string }>>();
  const [selectedExchange, setSelectedExchange] =
    useState<MultiValue<{ label?: string; value: string }>>();
  const [selectedSector, setSelectedSector] =
    useState<MultiValue<{ label?: string; value: string }>>();

  const onChangeOperator = (operator: string) => {
    onChangeFilterParams({
      ...filter,
      operator: operator,
    });
  };

  const onChangeAmount = (amount: string) => {
    onChangeFilterParams({
      ...filter,
      amount: amount,
    });
  };

  const onChangeBtwValue = useCallback(
    (start, end) => {
      const startValue = start ?? 0;
      const endValue = end ?? 100;
      onChangeFilterParams({
        ...filter,
        value: [startValue, endValue],
      });
    },
    [filter, onChangeFilterParams],
  );

  const onChangeValue = useCallback(
    (value) => {
      onChangeFilterParams({
        ...filter,
        value: value,
      });
    },
    [filter, onChangeFilterParams],
  );

  const CustomMultiValue = ({ children, ...props }) => (
    <Badge
      textOverflow={"ellipsis"}
      variant="outline"
      colorScheme="blue"
      fontSize={"6px"}
      fontWeight={500}
      mr={1}
    >
      {children}
    </Badge>
  );

  const MultiValueLabel = ({ children }) => {
    if (selectedSector?.length) return null;

    return (
      <Text fontSize="sm" fontWeight="bold">
        {children}
      </Text>
    );
  };

  const selectProps = useChakraSelectProps({
    isMulti: false,
    value: selectedOptions,
    onChange: (newValue) => {
      setSelectedOptions(newValue as any);
      onChangeOperator(newValue?.value as any);
    },
  });
  const selectCapitalizationProps = useChakraSelectProps({
    isMulti: false,
    value: selectedCapitalizationOptions,
    onChange: (newValue) => {
      setSelectedCapitalizationOptions(newValue as any);
      onChangeAmount(newValue?.value as any);
    },
  });

  const industryProps = useChakraSelectProps({
    isMulti: true,
    value: selectedIndustry,
    // components:{
    //     MultiValue:CustomMultiValue
    // },
    onChange: (newValue) => {
      setSelectedIndustry(newValue);
      onChangeValue(newValue?.map((item) => item.value as any));
    },
  });

  const countryProps = useChakraSelectProps({
    isMulti: true,
    value: selectedCountry,
    // components:{
    //     MultiValue:CustomMultiValue
    // },
    onChange: (newValue) => {
      setSelectedCountry(newValue);
      onChangeValue(newValue?.map((item) => item.value as any));
    },
  });

  const exchangeProps = useChakraSelectProps({
    isMulti: true,
    value: selectedExchange,
    // components:{
    //     MultiValue:CustomMultiValue
    // },
    onChange: (newValue) => {
      setSelectedExchange(newValue);
      onChangeValue(newValue?.map((item) => item.value as any));
    },
  });

  const sectorProps = useChakraSelectProps({
    isMulti: true,
    value: selectedSector,
    // components:{
    //     MultiValue:CustomMultiValue,

    // },
    onChange: (newValue) => {
      setSelectedSector(newValue);
      onChangeValue(newValue?.map((item) => item.value as any));
    },
  });

  const watchlistSelectedProps = useChakraSelectProps({
    isMulti: true,
    value: selectedWatchlist,
    // components:{
    //     MultiValue:CustomMultiValue
    // },
    onChange: (newValue) => {
      setSelectedWatchlist(newValue as any);
      onChangeValue(newValue?.map((item) => item.value as any));
    },
  });

  const portfolioSelectedProps = useChakraSelectProps({
    isMulti: true,
    value: selectedPortfolio,
    // components:{
    //     MultiValue:CustomMultiValue
    // },
    onChange: (newValue) => {
      setSelectedPortfolio(newValue as any);
      onChangeValue(newValue?.map((item) => item.value as any));
    },
  });

  const customFormatOptionLabel = (data, selectedValues) => {
    if (selectedValues.length > 1) {
      return `multiple (${selectedValues.length})`;
    }
    return null;
  };

  const chakraStyles: ChakraStylesConfig = useMemo(() => {
    return {
      placeholder: (provided, state) => ({
        ...provided,
        color: "#085096",
        fontWeight: 500,
        padding: "0 8px",
        fontSize: "12px",
        lineHeight: "20px",
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        color: "#085096",
        width: "min-content",
        maxWidth: "max-content",
        fontWeight: 500,
        padding: "2px 0 2px 0px",
        marginLeft: "8px",
        fontSize: "12px",
        lineHeight: "20px",
      }),
      container: (provided, state) => ({
        ...provided,
        color: "#085096",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "20px",
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#fff",
        "& div:nth-child(2):has(* + hr)": {
          paddingLeft: "8px",
        },
      }),
      inputContainer: (provided, state) => ({
        ...provided,
        height: "36px",
        width: "min-content",
        maxWidth: "178px",
        padding: "0 16px",
        color: "#085096",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "20px",
        display: "flex",
        flexWrap: "wrap",
      }),
      input: (provided, state) => ({
        ...provided,
        color: "#085096",
        width: "100%",
        height: "36px",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "20px",
      }),
      menu: (provided, state) => ({
        ...provided,
        w: "max-content",
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        background: state.isFocused ? "blue.100" : "transparent",
        color: "blue.300",
      }),
      loadingIndicator: (provided, state) => ({
        ...provided,
      }),
    };
  }, []);

  useEffect(() => {
    if (filter.type.includes("number")) {
      setOptions(numberOperators);
    } else {
      setOptions(stringOperators);
    }
  }, [filter]);

  const findLabelForMarketCapitalizationAmount = (
    value: string,
    arr: any[],
  ) => {
    return arr.find((f) => f.value === value);
  };

  useEffect(() => {
    new Promise(() => {
      setSelectedOptions(
        findLabelInValue(filter.operator, [
          ...numberOperators,
          ...stringOperators,
        ]),
      );

      if (filter?.amount) {
        setSelectedCapitalizationOptions(
          findLabelForMarketCapitalizationAmount(filter?.amount, [
            ...capitalizationOptions,
          ]),
        );
      }

      if (filter.propertyName.includes("industry")) {
        const selectIndustry = findLabelInValueMulti(filter.value, [
          ...industriesSelect(),
        ]);
        setSelectedIndustry(selectIndustry);
      } else if (filter.propertyName.includes("country")) {
        const selectCountry = findLabelInValueMulti(filter.value, [
          ...countrySelect(),
        ]);
        setSelectedCountry(selectCountry);
      } else if (filter.propertyName.includes("exchange")) {
        const selectExchange = findLabelInValueMulti(filter.value, [
          ...exchangeSelect(),
        ]);
        setSelectedExchange(selectExchange);
      } else if (filter.propertyName.includes("sector")) {
        const selectSector = findLabelInValueMulti(filter.value, [
          ...sectorSelect(),
        ]);
        setSelectedSector(selectSector);
      }
      const selectList = findLabelInValueMulti(filter.value, [
        ...listWatchlistOptions,
      ]);
      const selectedPortfolio = findLabelInValueMulti(filter.value, [
        ...listPortfolioOptions,
      ]);
      setSelectedWatchlist(selectList);
      setSelectedPortfolio(selectedPortfolio);
    });
  }, [filter, listPortfolioOptions, listWatchlistOptions]);

  const afterText = useMemo(() => {
    if (
      filter.propertyName.includes("mktCapUSD") ||
      filter.propertyName.includes("mktcapusd") ||
      filter.propertyName.includes("market capitalization")
    ) {
      return "USD or equivalent";
    } else if (
      filter.propertyName.includes("dividendYield") ||
      filter.propertyName.includes("returnOnInvestedCapital") ||
      filter.propertyName.includes("returnOnEquity") ||
      filter.propertyName.includes("returnOnAssets")
    ) {
      return "%";
    }
    return null;
  }, [filter]);

  const renderItemsByOperators = useCallback(() => {
    if (filter.type.includes("numeric") || filter.type.includes("number")) {
      return (
        <>
          {filter.operator.includes("btw") ? (
            <TextBtwNumberInput
              onChange={(start: number, end: number) => {
                onChangeBtwValue(start, end);
              }}
            ></TextBtwNumberInput>
          ) : (
            <>
              <TextNumberInput
                onChange={(value: number) => {
                  onChangeValue(value);
                }}
                value={filter.value}
              ></TextNumberInput>
              {(filter.propertyName.includes("mktCapUSD") ||
                filter.propertyName.includes("mktcapusd") ||
                filter.propertyName.includes("market capitalization")) && (
                <Select
                  {...selectCapitalizationProps}
                  chakraStyles={
                    {
                      ...chakraStyles,
                      placeholder: (provided, state) => ({
                        ...provided,
                        color: "#085096",
                        fontWeight: 500,
                        padding: "0 8px",
                        fontSize: "12px",
                        lineHeight: "20px",
                      }),
                      valueContainer: (provided, state) => ({
                        ...provided,
                        color: "#085096",
                        width: "max-content",
                        maxWidth: "max-content",
                        fontWeight: 500,
                        padding: "2px 0",
                        marginLeft: "8px",
                        marginRight: "8px",
                        fontSize: "12px",
                        lineHeight: "20px",
                      }),
                      container: (provided, state) => ({
                        ...provided,
                        color: "#085096",
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "20px",
                        display: "flex",
                        flexWrap: "wrap",
                        backgroundColor: "#fff",
                        "&::after": {
                          content: `''`,
                          position: "absolute",
                          top: "50%",
                          left: 0,
                          width: "10px",
                          height: "1px",
                          transform: "translateX(-100%)",
                          background: "gray.500",
                        },
                        "& div:nth-child(2) hr": {
                          display: "none",
                        },
                        "& div:nth-child(2) div:first-of-type": {
                          padding: "0 5px",
                        },
                      }),
                      inputContainer: (provided, state) => ({
                        ...provided,
                        height: "36px",
                        width: "min-content",
                        maxWidth: "178px",
                        padding: "0 16px",
                        color: "#085096",
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "20px",
                        display: "flex",
                        flexWrap: "wrap",
                      }),
                      input: (provided, state) => ({
                        ...provided,
                        color: "#085096",
                        width: "100%",
                        height: "36px",
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "20px",
                      }),
                      dropdownIndicator: (provided, state) => ({
                        ...provided,
                        background: state.isFocused
                          ? "blue.100"
                          : "transparent",
                        color: "blue.300",
                      }),
                      loadingIndicator: (provided, state) => ({
                        ...provided,
                      }),
                    } as any
                  }
                  options={capitalizationOptions as any}
                />
              )}
            </>
          )}
        </>
      );
    } else {
      if (filter.propertyName.includes("industry")) {
        return (
          <Select
            {...industryProps}
            placeholder={`Industries`}
            chakraStyles={chakraStyles as any}
            options={industriesSelect() as any}
          />
        );
      }

      if (filter.propertyName.includes("country")) {
        return (
          <Select
            {...countryProps}
            placeholder={`Countries`}
            chakraStyles={chakraStyles as any}
            options={countrySelect() as any}
          />
        );
      }

      if (filter.propertyName.includes("exchange")) {
        return (
          <Select
            {...exchangeProps}
            placeholder={`Exchanges`}
            chakraStyles={chakraStyles as any}
            options={exchangeSelect() as any}
          />
        );
      }

      if (filter.propertyName.includes("sector")) {
        return (
          <Select
            {...sectorProps}
            placeholder={`Sectors`}
            chakraStyles={chakraStyles as any}
            options={sectorSelect() as any}
          />
        );
      }
      return null;
    }
  }, [
    capitalizationOptions,
    chakraStyles,
    countryProps,
    exchangeProps,
    filter.operator,
    filter.propertyName,
    filter.type,
    filter.value,
    industryProps,
    onChangeBtwValue,
    onChangeValue,
    sectorProps,
    selectCapitalizationProps,
  ]);

  const onChangeProperty = (property: string) => {
    const stringProperties = [
      "sector",
      "industry",
      "country",
      "exchange",
      "Sector",
      "Industry",
      "Country",
      "Exchange",
      "watchlist",
      "portfolio",
      "Watchlist",
      "Portfolio",
    ];
    const isString = stringProperties.includes(property);
    onChangeFilterParams({
      ...filter,
      propertyName: property,
      type: isString ? "string" : "number",
      operator: isString ? "iof" : "eq",
    });
  };

  return (
    <Box
      minWidth="min-content"
      w="max-content"
      h="auto"
      mt="10px"
      alignItems={"center"}
      display={"flex"}
      borderRadius={"20px !important"}
      padding={"10px 15px !important"}
      border={"1px solid #E9EAEB"}
      gap={"10px !important"}
      bgColor={"#F9FAFB"}
      color="#085096"
      fontWeight={500}
      fontSize={"12px"}
      lineHeight={"20px"}
    >
      <TextPropertyInput
        propertyName={resolveNameProperty(filter.propertyName) ?? ""}
        onChangeProperty={onChangeProperty}
      ></TextPropertyInput>
      <Select
        {...selectProps}
        placeholder={`operator`}
        chakraStyles={
          {
            ...chakraStyles,
            placeholder: (provided, state) => ({
              ...provided,
              color: "#085096",
              fontWeight: 500,
              padding: "0 8px",
              fontSize: "12px",
              lineHeight: "20px",
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              color: "#085096",
              width: "max-content",
              maxWidth: "max-content",
              fontWeight: 500,
              padding: "2px 0",
              marginLeft: "8px",
              marginRight: "8px",
              fontSize: "12px",
              lineHeight: "20px",
            }),
            container: (provided, state) => ({
              ...provided,
              color: "#085096",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "20px",
              display: "flex",
              flexWrap: "wrap",
              backgroundColor: "#fff",
              "&::after": {
                content: `''`,
                position: "absolute",
                top: "50%",
                right: 0,
                width: "15px",
                height: "1px",
                transform: "translateX(100%)",
                background: "gray.500",
              },
              "& div:nth-child(2) hr": {
                display: "none",
              },
              "& div:nth-child(2) div:first-of-type": {
                padding: "0 5px",
              },
            }),
            inputContainer: (provided, state) => ({
              ...provided,
              height: "36px",
              width: "min-content",
              maxWidth: "178px",
              padding: "0 16px",
              color: "#085096",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "20px",
              display: "flex",
              flexWrap: "wrap",
            }),
            input: (provided, state) => ({
              ...provided,
              color: "#085096",
              width: "100%",
              height: "36px",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "20px",
            }),
            dropdownIndicator: (provided, state) => ({
              ...provided,
              background: state.isFocused ? "blue.100" : "transparent",
              color: "blue.300",
            }),
            loadingIndicator: (provided, state) => ({
              ...provided,
            }),
          } as any
        }
        options={options as any}
      />

      {renderItemsByOperators()}
      {filter.propertyName.includes("watchlist") && (
        <Select
          {...watchlistSelectedProps}
          placeholder={`Watchlist`}
          chakraStyles={chakraStyles as any}
          options={listWatchlistOptions as any}
        />
      )}
      {filter.propertyName.includes("portfolio") && (
        <Select
          {...portfolioSelectedProps}
          placeholder={`Portfolio`}
          chakraStyles={chakraStyles as any}
          options={listPortfolioOptions as any}
        />
      )}

      {afterText && (
        <Text
          fontFamily={"Poppins"}
          fontWeight={400}
          fontSize={"14px"}
          lineHeight={"20px"}
        >
          {afterText}
        </Text>
      )}

      <Box
        display={{ base: "flex", md: "flex" }}
        bg="rgba(226, 232, 240, 1)"
        width="29.15px"
        height="29.15px"
        borderRadius="5px"
        gap="10px"
        p="8px"
        onClick={(e) => {
          e.preventDefault();
          onRemove();
        }}
      >
        <svg
          style={{ cursor: "pointer" }}
          width="13"
          height="13"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.42461 8.475L2.52461 13.375C2.34128 13.5583 2.10794 13.65 1.82461 13.65C1.54128 13.65 1.30794 13.5583 1.12461 13.375C0.941276 13.1917 0.849609 12.9583 0.849609 12.675C0.849609 12.3917 0.941276 12.1583 1.12461 11.975L6.02461 7.075L1.12461 2.175C0.941276 1.99167 0.849609 1.75833 0.849609 1.475C0.849609 1.19167 0.941276 0.958333 1.12461 0.775C1.30794 0.591666 1.54128 0.5 1.82461 0.5C2.10794 0.5 2.34128 0.591666 2.52461 0.775L7.42461 5.675L12.3246 0.775C12.5079 0.591666 12.7413 0.5 13.0246 0.5C13.3079 0.5 13.5413 0.591666 13.7246 0.775C13.9079 0.958333 13.9996 1.19167 13.9996 1.475C13.9996 1.75833 13.9079 1.99167 13.7246 2.175L8.82461 7.075L13.7246 11.975C13.9079 12.1583 13.9996 12.3917 13.9996 12.675C13.9996 12.9583 13.9079 13.1917 13.7246 13.375C13.5413 13.5583 13.3079 13.65 13.0246 13.65C12.7413 13.65 12.5079 13.5583 12.3246 13.375L7.42461 8.475Z"
            fill="#667084"
          />
        </svg>
      </Box>
    </Box>
  );
}
