import {
  Badge,
  Box,
  Button,
  Flex,
  ModalCloseButton,
  Spinner,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { useFetchSubscriptionDataQuery } from "../../../services/userApi";
import { getSizes } from "../../../utils/subscription";
import { billingPlans , OptionsListTooltips } from "./data";
import { clientApi } from "../../../services/client";
import { trackBeginCheckout, trackDowngradeSubscription, trackRedirectToStripe, trackReturnFromStripe } from "../../../utils/gtagAnalytics";
import { Advanced, BillingPlans } from "../../../types/strapi";

const planPriorities = {
  free: 0,
  basic: 1,
  advanced: 2,
  pro: 3,
};

const getProductName = (plan: string, isMonthly: boolean): string => {
  const suffix = isMonthly ? "MONTHLY" : "YEARLY";
  if (plan === "free") return "free";
  return `${plan.toUpperCase()}_${suffix}`;
};

export default function PricingCards({
  isMonthly,
  setIsMonthly,
  showFree = false,
  showCurrentSubscription = false,
  showOnlyUpgrade = false,
  pricingFromStrapi = null
}) {
  const [loading, setLoading] = useState(-1);
  const [accessToken] = useLocalStorage("accessToken", localStorage.getItem("accessToken") || "");
  const toast = useToast();
  const { data: subscriptionData, refetch } =
    useFetchSubscriptionDataQuery(accessToken);

  const pricingOrder = ['pro', 'advanced', 'basic', 'free'];
  const subPriority = ["free", "basic", "advanced", "pro"];

  const getCurrentPlan = () => {
  
    if (!subscriptionData?.subscription) return "free";

    const planName = subscriptionData.subscription.plan
      .split("_")[0]
      .toLowerCase();

    return planName;
  };

  useEffect(()=>{
    console.log("propslogs:",pricingFromStrapi)
},[pricingFromStrapi])

  const currentPlan = getCurrentPlan() as string;

  const getCheckoutUrl = useCallback(async (plan: string) => {

    if(subPriority.indexOf(plan) <
    subPriority.indexOf(currentPlan)){
        trackDowngradeSubscription(currentPlan,plan)
    }
    const productName = getProductName(plan, isMonthly);
    if (!productName) return;

    setLoading(Object.values(pricingFromStrapi ? pricingFromStrapi : billingPlans).findIndex((e)=>e.name.includes(plan)));
    trackBeginCheckout(plan,planPriorities[plan],isMonthly ? "montly" :"yearly")

    try {
      const resp = await clientApi.post(
        `/api/v1/payments/${productName}`,
        {}
      );

      if (resp.status === 200) {
        if (resp.data.data) {
          // New subscription or checkout required
          window.location.href = resp.data.data;
          trackRedirectToStripe(plan,planPriorities[plan],isMonthly ? "montly" :"yearly")
        } else {
          // Subscription updated (upgrade/downgrade)
          toast({
            title: "Success",
            description: resp.data.message,
            status: "success",
            duration: 5000,
          });
          await refetch();
          trackReturnFromStripe("success",plan,planPriorities[plan],isMonthly ? "montly" :"yearly")
        }
      }
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "An error occurred",
        status: "error",
        duration: 5000,
      });
      console.error(error);
    } finally {
      setLoading(-1);
    }
  },[currentPlan, isMonthly, pricingFromStrapi, refetch, subPriority, toast])

  return (
    <Box
      display="flex"
      rowGap={"14px"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={"10px"}
        fontSize={"sm"}
      >
        <Text color={isMonthly ? "#0A64BC" : ""}>Monthly</Text>
        <Switch
          size="lg"
          isChecked={!isMonthly}
          color={"#0A64BC"}
          onChange={() => setIsMonthly(!isMonthly)}
        />
        <Text color={!isMonthly ? "#0A64BC" : ""}>Yearly</Text>
        <Badge variant="solid" bg="#0A64BC" rounded={"md"} px={2} fontSize={"14px"} textTransform={"lowercase"}>
          2 months free
        </Badge>
      </Box>

      <Box
        display={{ base: "block", md: "block", lg:"flex" }}
        flexDirection={"row"}
        alignItems={"center"}
        gap={"30px"}
        flexWrap={"wrap"}
      >
        {Object.values(pricingFromStrapi ? pricingFromStrapi as any : billingPlans)?.sort((a, b) => pricingOrder.indexOf((a as any).name) - pricingOrder.indexOf((b as any).name)).map((plan: any, idx) => {
          if (!showFree && plan.name === "free") return null;
          if (showFree && plan.name === "free" && currentPlan === "free") return null;
          if (plan.name?.toLowerCase()?.includes(currentPlan)) return null;
          if(showOnlyUpgrade && subPriority.indexOf(plan.name) <= subPriority.indexOf(currentPlan)) return null
          
          return (
            <Box
              key={plan.name}
              display={"flex"}
              flexDirection={"column"}
              gap={"15px"}
              // minW={"200px"}
              maxW="250px"
              backgroundColor={plan.name === "advanced" ? "" : "#F9FAFB"}
              style={{
                background:
                  plan.name === "advanced"
                    ? "linear-gradient(to bottom,  #04284B, #085096)"
                    : "",
              }}
              color={plan.name === "advanced" ? "#FFFFFF" : ""}
              p={"30px"}
              borderRadius={"20px"}
              boxSizing="content-box"
            >
              {/* !! Remove comments for add image <img
                alt={`${plan?.title} plan`}
                src={`/images/${plan.name}_plan_bg.png`}
                width={imageSize}
                height={imageSize}
                style={{
                  margin: "0 auto",
                  marginBottom: "12px",
                }}
              /> */}
              <Box
                display="flex"
                flexDirection={"column"}
                gap="5px"
                alignItems={"start"}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"baseline"}
                >
                  {!isMonthly && plan.name !== "free" && (
                    <Text
                      fontSize={"2xl"}
                      fontWeight={"bold"}
                      textDecoration={"line-through"}
                      marginRight={"5px"}
                      color={plan.name === "advanced" ? "#0A64BC" : "#D3D5DA"}
                    >
                      ${plan.price.monthlyStandard}
                    </Text>
                  )}
                  <Text fontSize={"2xl"} fontWeight={"extrabold"}>
                    $
                    {isMonthly
                      ? plan.price.monthlyDiscounted
                      : plan.price.yearlyDiscounted}
                  </Text>
                  <Text fontSize={"sm"} fontStyle={"italic"} ml={"5px"}>
                    /month
                  </Text>
                </Box>
                {!isMonthly && plan.name !== "free" && (
                  <Text
                    fontStyle={"italic"}
                    fontSize={"smaller"}
                    color={plan.name === "advanced" ? "" : "#4D5461"}
                  >
                    billed as ${plan.price.yearlyTotal} per year
                  </Text>
                )}
              </Box>

              <Text fontSize={"xl"} fontWeight={"bold"}>
                {plan.title}
              </Text>

              <Text fontSize={"smaller"}>{plan.summary}</Text>

              <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                {plan.optionsList.map((option, i) => {
                  const [firstWord, secondWord, ...rest] = option.split(" ");

                  return (
                    <Tooltip key={`tooltip-${i}`} label={OptionsListTooltips[i]} p={2} placement="top-start" hasArrow >
                      <Box
                      display="flex"
                      gap="5px"
                      alignItems={"start"}
                      className="option-item"
                      fontSize={"smaller"}
                      key={i}
                      mt={1}
                    >
                      <Flex fontWeight={"light"} flexWrap={"wrap"} gap={1}>
                        <Text fontWeight={"semibold"}>
                          {firstWord.replaceAll("/"," ")} {secondWord.replaceAll("/"," ")}
                        </Text>{"  "}
                         {rest.join(" ")}
                      </Flex>
                    </Box>
                    </Tooltip>
                  );
                })}
              </Box>

              <Button
                id={`button-${plan.name}`}
                colorScheme={plan.name === currentPlan ? "whiteAlpha" : "blue"}
                w={"100%"}
                textTransform={"uppercase"}
                height={"16px"}
                py={"22px"}
                px={"30px"}
                minWidth={"150px"}
                backgroundColor={
                  plan.name !== currentPlan ? "#055093" : "white"
                }
                color={plan.name !== currentPlan ? "#F9FAFB" : "#0564B8"}
                borderRadius={"8px"}
                fontSize={"16px"}
                lineHeight={"24px"}
                fontWeight={600}
                fontFamily={"Poppins"}
                onClick={() => getCheckoutUrl(plan.name)}
              >
                {loading === idx ? (
                  <Spinner mr="5px" />
                ) : (
                  <>
                    {plan.name === currentPlan
                      ? "Current Plan"
                      : subPriority.indexOf(plan.name) <
                        subPriority.indexOf(currentPlan)
                        ? `Downgrade Plan`
                        : `Upgrade to ${plan.title}`}
                  </>
                )}
              </Button>
              {showCurrentSubscription &&
                subscriptionData?.subscription?.currentPeriodEnd &&
                plan.name !== "free" && (
                  <Box>
                    Subscription ends on{" "}
                    {new Date(
                      subscriptionData?.subscription?.currentPeriodEnd ||
                      Date.now(),
                    )
                      ?.toLocaleDateString()
                      ?.replaceAll("/", "-")
                      ?.split("-")
                      .reverse()
                      .join("-")}
                  </Box>
                )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
