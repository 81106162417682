import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import { Dispatch, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import "../../App.css";
import Eye from "../../assets/svg/Eye.png";
import Eyeshow from "../../assets/svg/Eyeshow.svg";
import googleIcon from "../../assets/svg/google_icon.svg";
import Lock from "../../assets/svg/Lock.svg";
import Mail from "../../assets/svg/Mail.svg";
import twitterIcon from "../../assets/svg/twitter_icon.svg";
import { authentication } from "../../firebase-config.js";
import { setActualUser } from "../../store/slices/user";
import TermConditionModal from "../Auth/Term&conditionModal";
interface TwitterUserInfo {
  user: { email: string | null };
}

const SigninSignupModal = ({
  openModal,
  setOpenModal,
  signinClicked,
  setSigninClicked,
  fetchLoginStatus,
  onClose,
  directSignupWithCredentials = false,
}: {
  openModal: boolean;
  setOpenModal: Dispatch<boolean>;
  onClose: any;
  signinClicked: boolean;
  setSigninClicked: Dispatch<boolean>;
  fetchLoginStatus: any;
  directSignupWithCredentials?: boolean;
}) => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(
    directSignupWithCredentials || false,
  );
  const [openTCModal, setOpenTCModal] = useState(false);
  const [TCChecked, setTCChecked] = useState(false);
  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
  });
  const [userInfo, setUserInfo] = useState({ status: 0, data: { email: "" } });
  const [twitterUserInfo, setTwitterUserInfo] = useState<TwitterUserInfo>();
  const [emailFocused, setEmailFocused] = useState(
    userInput?.email ? true : false,
  );
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [show, setShow] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [userMessage, setUserMessage] = useState({ msg: "", color: "" });
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState({
    email: "",
    password: "",
  });
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

  const isError = error.email != ""

  useEffect(() => {
    userInput?.email
      ? setEmailFocused(true)
      : userInput?.password && setPasswordFocused(true);
  }, [userInput]);

  useEffect(() => {
    if (TCChecked && currentAction === "handleUserSignup") handleUserSignup();
    else if (TCChecked && currentAction === "handleGoogleSignup")
      handleGoogleSignupCheck();
    else if (TCChecked && currentAction === "handleTwitterSignup")
      handleTwitterSignupCheck();
  }, [TCChecked]);

  useEffect(() => {
    if (showForm) setUserMessage({ msg: "", color: "" });
  }, [showForm]);

  async function verifyEmail(email) {
    // Skip disposable email check in local mode
    if (process.env.REACT_APP_MODE === "LOCAL" || "DEVELOPMENT") {
      return "valid";
    }

    const response = await axios.get(
      `https://emailverifier.reoon.com/api/v1/verify`,
      {
        params: {
          email: email,
          key: process.env.REACT_APP_REOON_API_KEY,
          mode: "quick",
        },
      },
    );

    if (response.data) {
      return response.data.status;
    } else {
      return "invalid";
    }
  }

  const handleTwitterLogin = () => {
    const provider = new TwitterAuthProvider();
    signInWithPopup(authentication, provider)
      .then(async (res) => {
        if (res?.user?.email) {
          setShowLoader(true);
          setTCChecked(false);
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/checkUserExist`,
            { email: res?.user?.email },
          );
          if (
            response?.status === 200 &&
            response?.data?.message ===
              "You already have an existing account - TWITTER "
          ) {
            setUserInput({ email: res?.user?.email, password: "" });
            setUserMessage({
              msg: "You already have an existing account - USING_EMAIL",
              color: "red",
            });
            setShowLoader(false);
            setSigninClicked(true);
          } else if (
            response?.status === 200 &&
            response?.data?.message ===
              "You already have an existing account - TWITTER "
          ) {
            const response = await axios.post(
              `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/login`,
              {
                email: res?.user?.email,
                socialId: "TWITTER",
                flag: "TWITTER",
              },
            );
            if (response?.status === 200) {
              setShowLoader(false);
              localStorage.setItem("accessToken", response?.data?.data?.token);
              dispatch(setActualUser(response?.data?.data?.user))
              fetchLoginStatus(response?.data?.data?.token);
              setShowForm(false);
              setSigninClicked(false);
              setOpenModal(false);
              onClose();
            } else {
              console.log(response);
            }
          } else if (
            response?.status === 200 &&
            response?.data?.message === "Account does not exist"
          ) {
            setShowLoader(false);
            setUserMessage({
              msg: "Account does not exist please signup.",
              color: "red",
            });
            setSigninClicked(false);
          }
        } else {
          setShowLoader(false);
          setError({ email: "", password: "" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTwitterSignup = () => {
    const provider = new TwitterAuthProvider();
    signInWithPopup(authentication, provider)
      .then(async (res) => {
        setCurrentAction("handleTwitterSignup");
        setTwitterUserInfo(res);
        setOpenTCModal(!TCChecked && true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTwitterSignupCheck = async () => {
    if (twitterUserInfo?.user?.email && TCChecked) {
      setTCChecked(false);
      setShowLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/checkUserExist`,
        { email: twitterUserInfo?.user?.email },
      );
      if (
        response?.status === 200 &&
        response?.data?.message ===
          "You already have an existing account - USING_EMAIL "
      ) {
        setUserInput({ email: userInfo?.data?.email, password: "" });
        setUserMessage({
          msg: "You already have an existing account - USING_EMAIL",
          color: "red",
        });
        setShowLoader(false);
        setSigninClicked(true);
        setUserMessage({
          msg: "You already have an existing account - please signin using Email & Password",
          color: "red",
        });
      } else if (
        response?.status === 200 &&
        response?.data?.message ===
          "You already have an existing account - TWITTER "
      ) {
        setShowLoader(false);
        setShowForm(false);
        setSigninClicked(true);
        setUserMessage({
          msg: "You already have an existing account - please signin using TWITTER",
          color: "red",
        });
      } else if (
        response?.status === 200 &&
        response?.data?.message === "Account does not exist"
      ) {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/signUp`,
          {
            email: twitterUserInfo?.user?.email,
            socialId: "TWITTER",
            flag: "TWITTER",
          },
        );
        if (
          response?.status === 200 &&
          response?.data?.message === "signup successfully"
        ) {
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/signUp`,
            {
              email: twitterUserInfo?.user?.email,
              socialId: "TWITTER",
              flag: "TWITTER",
            },
          );
          if (response?.status === 200) {
            setUserMessage({
              msg: "Signup successfully please login",
              color: "green",
            });
            setShowLoader(false);
            setShowForm(false);
            setSigninClicked(true);
          } else {
            console.log(response);
          }
        } else {
          console.log(response);
        }
      }
    }
  };

  const handleGoogleSignupCheck = async () => {
    if (userInfo?.status === 200 && TCChecked) {
      setTCChecked(false);
      setShowLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/checkUserExist`,
        { email: userInfo?.data?.email },
      );
      if (
        response?.status === 200 &&
        response?.data?.message ===
          "You already have an existing account - USING_EMAIL "
      ) {
        setUserInput({ email: userInfo?.data?.email, password: "" });
        setUserMessage({
          msg: "You already have an existing account - USING_EMAIL",
          color: "red",
        });
        setShowLoader(false);
        setSigninClicked(true);
        setUserMessage({
          msg: "You already have an existing account - please signin using Email & Password",
          color: "red",
        });
      } else if (
        response?.status === 200 &&
        response?.data?.message ===
          "You already have an existing account - GOOGLE "
      ) {
        setShowLoader(false);
        setShowForm(false);
        setSigninClicked(true);
        setUserMessage({
          msg: "You already have an existing account - please signin using GOOGLE",
          color: "red",
        });
      } else if (
        response?.status === 200 &&
        response?.data?.message === "Account does not exist"
      ) {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/signUp`,
          {
            email: userInfo?.data?.email,
            socialId: "GOOGLE",
            flag: "GOOGLE",
          },
        );
        if (
          response?.status === 200 &&
          response?.data?.message === "signup successfully"
        ) {
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/signUp`,
            {
              email: userInfo?.data?.email,
              socialId: "GOOGLE",
              flag: "GOOGLE",
            },
          );
          if (response?.status === 200) {
            setUserMessage({
              msg: "Signup successfully please login",
              color: "green",
            });
            setShowLoader(false);
            setShowForm(false);
            setSigninClicked(false);
          } else {
            console.log(response);
          }
        } else {
          console.log(response);
        }
      }
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        },
      );
      if (userInfo?.status === 200) {
        setShowLoader(true);
        setTCChecked(false);
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/checkUserExist`,
          { email: userInfo?.data?.email },
        );
        if (
          response?.status === 200 &&
          response?.data?.message ===
            "You already have an existing account - USING_EMAIL "
        ) {
          setUserInput({ email: userInfo?.data?.email, password: "" });
          setUserMessage({
            msg: "You already have an existing account - USING_EMAIL",
            color: "red",
          });
          setShowLoader(false);
          setSigninClicked(true);
        } else if (
          response?.status === 200 &&
          response?.data?.message ===
            "You already have an existing account - GOOGLE "
        ) {
          const response = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/login`,
            {
              email: userInfo?.data?.email,
              socialId: "GOOGLE",
              flag: "GOOGLE",
            },
          );
          if (response?.status === 200) {
            setShowLoader(false);
            localStorage.setItem("accessToken", response?.data?.data?.token);
            dispatch(setActualUser(response?.data?.data?.user))
            fetchLoginStatus(response?.data?.data?.token);
            setShowForm(false);
            setSigninClicked(false);
            setOpenModal(false);
            onClose();
          } else {
            console.log(response);
          }
        } else if (
          response?.status === 200 &&
          response?.data?.message === "Account does not exist"
        ) {
          setShowLoader(false);
          setUserMessage({
            msg: "Account does not exist please signup.",
            color: "red",
          });
          setSigninClicked(false);
        }
      } else {
        setShowLoader(false);
        setError({ email: "", password: "" });
      }
    },
    onError: (error) => console.log(error),
  });

  const handleGoogleSignup = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInforesp = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        },
      );
      setCurrentAction("handleGoogleSignup");
      setUserInfo(userInforesp);
      setOpenTCModal(!TCChecked && true);
    },
  });

  const handleValidation = (emailVerificationStatus = "valid") => {
    let error = { email: "", password: "" };

    if (userInput?.email !== "") {
      if (!userInput?.email?.match(emailRegex)) {
        error.email = "Invalid email address.";
      } else if (emailVerificationStatus === "invalid") {
        error.email = "Check the email address provided.";
      } else if (emailVerificationStatus === "disposable") {
        error.email = "Disposable emails are not allowed.";
      }
    } else {
      error.email = "Email is required.";
    }

    if (userInput?.password !== "") {
      if (userInput?.password?.length < 8) {
        error.password = "Password should have at least 8 character.";
      }
    } else {
      error.password = "Password  is required.";
    }

    setError(error);
  };

  useEffect(()=>{
    if (userInput?.email !== "") {
      if (!userInput?.email?.match(emailRegex)) {
        console.log("Enter in validation modal")
        error.email = "Invalid email address.";
      }else{
        error.email = ""
      }
    }
  },[userInput])

  const handleUserSignin = async () => {
    setError({ email: "", password: "" });
    if (
      userInput?.email?.match(emailRegex) &&
      userInput?.password?.length >= 8
    ) {
      setShowLoader(true);
      setUserMessage({ msg: "", color: "" });
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/login`,
        { ...userInput, flag: "USING_EMAIL" },
      );
      if (
        response?.status === 200 &&
        response?.data?.message === "user doesnot exist"
      ) {
        setShowLoader(false);
        setShowForm(true);
        setSigninClicked(false);
        const message = response?.data?.message === "user doesnot exist" ? "We couldn't find an account associated with the provided email address. Please double-check the email or consider creating a new account." : response?.data?.message
        setUserMessage({ msg: message, color: "red" });
        setUserInput({ email: "", password: "" });
      } else if (
        response?.status === 200 &&
        response?.data?.message === "incorrect password"
      ) {
        setShowLoader(false);
        setSigninClicked(true);
        setShowForm(true);
        setError({ email: "", password: "" });
        setError({ email: "", password: response?.data?.message });
      } else if (
        response?.status === 200 &&
        response?.data?.message === "login successfully"
      ) {
        setShowLoader(false);
        localStorage.setItem("accessToken", response?.data?.data?.token);
        dispatch(setActualUser(response?.data?.data?.user));
        fetchLoginStatus(response?.data?.data?.token);
        setShowForm(false);
        setSigninClicked(false);
        setOpenModal(false);
        onClose();
      } else {
        setShowLoader(false);
        setSigninClicked(true);
        setShowForm(false);
        setError({ email: "", password: "" });
        setUserMessage({ msg: response?.data?.message, color: "red" });
      }
    } else {
      handleValidation();
    }
  };

  const handleUserSignup = async () => {
    setCurrentAction("handleUserSignup");
    setOpenTCModal(!TCChecked && true);
    setError({ email: "", password: "" });

    const emailVerificationStatus = await verifyEmail(userInput?.email);

    if (
      emailVerificationStatus !== "valid" ||
      !userInput?.email?.match(emailRegex) ||
      userInput?.password?.length < 8 ||
      !TCChecked
    ) {
      handleValidation(emailVerificationStatus);
      return;
    }

    setShowLoader(true);
    setUserMessage({ msg: "", color: "" });
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/v1/auth/signUp`,
      { ...userInput, flag: "USING_EMAIL" },
    );
    if (
      response?.status === 200 &&
      response?.data?.message === "signup successfully"
    ) {
      setShowLoader(false);
      setShowForm(false);
      setSigninClicked(true);
      setUserMessage({
        msg: "signup successfully use email & password for login",
        color: "green",
      });
      setUserInput({ email: "", password: "" });
      setError({ email: "", password: "" });
    } else {
      setShowLoader(false);
      setSigninClicked(true);
      setError({ email: "", password: "" });
      setUserMessage({ msg: response?.data?.message, color: "red" });
    }
  };

  return (
    <>
      <Modal isOpen={openModal} onClose={() => setOpenModal(false)} blockScrollOnMount={true}>
        <ModalOverlay sx={{ background: "#3a83c9a8" }} />
        <ModalContent my={140} mx={5} background="#F9FAFB" borderRadius="20px">
          <ModalBody py={10} px="24px">
            <Text
              fontSize="14px"
              fontFamily="Poppins"
              lineHeight="20px"
              textAlign="center"
              fontWeight={400}
              color={userMessage?.color}
              mb={3}
            >
              {userMessage?.msg}
            </Text>
            <Text
              fontSize="16px"
              lineHeight="24px"
              textAlign="center"
              fontFamily="Poppins"
              fontWeight={400}
              mb={6}
            >
              <FormattedMessage
                id={
                  showForm
                    ? "formTitle"
                    : signinClicked
                      ? "signinModalTitle"
                      : "signupModalTitle"
                }
              />
            </Text>
            {!showForm ? (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontWeight={500}
                  fontSize="18px"
                  lineHeight="27px"
                  py="15px"
                  borderRadius="10px"
                  backgroundColor="white"
                  boxShadow="0px 2px 3px rgba(0, 0, 0, 0.25)"
                  my={"14px"}
                  cursor="pointer"
                  onClick={() =>
                    signinClicked ? handleGoogleLogin() : handleGoogleSignup()
                  }
                >
                  <img src={googleIcon} alt="" />
                  <Text ms={4} fontFamily="Poppins">
                    <FormattedMessage
                      id="socialLoginButton"
                      values={{
                        change: signinClicked ? "In" : "Up",
                        name: "Google",
                      }}
                    />
                  </Text>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontWeight={500}
                  fontSize="18px"
                  lineHeight="27px"
                  py="15px"
                  borderRadius="10px"
                  color={"white"}
                  backgroundColor={"#1A8CD8"}
                  boxShadow="0px 2px 3px rgba(0, 0, 0, 0.25)"
                  my={"14px"}
                  cursor="pointer"
                  onClick={() =>
                    signinClicked ? handleTwitterLogin() : handleTwitterSignup()
                  }
                >
                  <img src={twitterIcon} alt="" />
                  <Text>
                    <Text ms={4} fontFamily="Poppins">
                      <FormattedMessage
                        id="socialLoginButton"
                        values={{
                          change: signinClicked ? "In" : "Up",
                          name: "Twitter",
                        }}
                      />
                    </Text>
                  </Text>
                </Box>
              </>
            ) : (
              <>
                <InputGroup my="14px" position="relative">
                  <InputLeftElement py="27px" ms={1}>
                    <img src={Mail} alt="" />
                  </InputLeftElement>
                  <Input
                    focusBorderColor="#D3D5DA"
                    onFocus={() => setEmailFocused(true)}
                    onBlur={() =>
                      setEmailFocused(userInput.email ? true : false)
                    }
                    onChange={(e) =>{
                      setUserInput({ ...userInput, email: e.target.value })
                    }
                    }
                    value={userInput?.email}
                    backgroundColor="white"
                    pt="33px"
                    pb="13px"
                    height="55px"
                    fontWeight={400}
                    fontSize="16px"
                    borderRadius="16px"
                    fontFamily="Poppins"
                    border={0}
                    placeholder=" "
                    type="text"
                  />
                  <FormLabel
                    position="absolute"
                    fontFamily="Poppins"
                    top={emailFocused ? "1px" : 3}
                    color="#9EA3AE"
                    fontWeight={400}
                    fontSize={emailFocused ? "12px" : "16px"}
                    left={10}
                    zIndex={2}
                    cursor="text"
                    onClick={() => setEmailFocused(true)}
                  >
                    <FormattedMessage id="emailLabel" />
                  </FormLabel>
                </InputGroup>
                <Text
                  color="red"
                  fontSize="14px"
                  fontWeight={400}
                  ms={3}
                  fontFamily="Poppins"
                >
                  {error?.email}
                </Text>
                <InputGroup my="14px" position="relative">
                  <InputLeftElement pointerEvents="none" py="27px" ms={1}>
                    <img src={Lock} alt="" />
                  </InputLeftElement>
                  <Input
                    fontWeight={400}
                    fontSize="16px"
                    height="55px"
                    pt="33px"
                    pb="13px"
                    fontFamily="Poppins"
                    onFocus={() => setPasswordFocused(true)}
                    onBlur={() =>
                      setPasswordFocused(
                        show || userInput.password ? true : false,
                      )
                    }
                    onChange={(e) =>
                      setUserInput({
                        ...userInput,
                        password: e.target.value.trim(),
                      })
                    }
                    value={userInput?.password}
                    backgroundColor="white"
                    focusBorderColor="#D3D5DA"
                    borderRadius="16px"
                    border={0}
                    placeholder=" "
                    type={show ? "text" : "password"}
                  />
                  <InputRightElement
                    me={1}
                    py="27px"
                    onClick={() => setShow(!show)}
                    cursor="pointer"
                    children={<img src={show ? Eye : Eyeshow} alt="" />}
                  />
                  <FormLabel
                    position="absolute"
                    top={passwordFocused ? "1px" : 3}
                    color="#9EA3AE"
                    fontWeight={400}
                    fontSize={passwordFocused ? "12px" : "16px"}
                    left={10}
                    zIndex={2}
                    fontFamily="Poppins"
                    cursor="text"
                    onClick={() => setPasswordFocused(true)}
                  >
                    <FormattedMessage id="passwordLabel" />
                  </FormLabel>
                </InputGroup>
                <Text
                  color="red"
                  fontSize="14px"
                  fontFamily="Poppins"
                  fontWeight={400}
                  ms={3}
                >
                  {error.password}
                </Text>
                <Box
                  display="flex"
                  justifyContent={signinClicked ? "start" : "center"}
                  my={6}
                >
                  <Checkbox
                    height="24px"
                    width="24px"
                    mx={3}
                    className="form_checkBox"
                    backgroundColor="white"
                    borderRadius="8px"
                    border="1.5px solid #D3D5DA"
                    boxShadow={0}
                    type="checkbox"
                    isChecked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  />
                  <Text
                    fontSize="16px"
                    fontFamily="Poppins"
                    lineHeight="24px"
                    fontWeight={400}
                    cursor="pointer"
                    onClick={() => setIsChecked(!isChecked)}
                  >
                    <FormattedMessage
                      id={signinClicked ? "remindeme" : "acceptT_C"}
                    />
                  </Text>
                  <Text
                    fontSize="16px"
                    fontFamily="Poppins"
                    ms={1}
                    lineHeight="24px"
                    fontWeight={400}
                    color="#0A64BC"
                    cursor="pointer"
                    onClick={() => setIsChecked(!isChecked)}
                  >
                    {!signinClicked && <FormattedMessage id="privacyPolicy" />}
                  </Text>
                </Box>
                <Button
                  textAlign="center"
                  py="28px"
                  width="100%"
                  fontFamily="Poppins"
                  color="white"
                  borderRadius="10px"
                  backgroundColor="#055093"
                  boxShadow="0px 2px 3px rgba(0, 0, 0, 0.25)"
                  my={"14px"}
                  cursor="pointer"
                  _hover={{ backgroundColor: "#0A64BC" }}
                  isDisabled={!isChecked}
                  onClick={() =>{
                    if(!isError){
                      signinClicked ? handleUserSignin() : handleUserSignup()
                    }
                  }
                    
                  }
                >
                  <Text
                    fontWeight={600}
                    fontSize="16px"
                    fontFamily="Poppins"
                    lineHeight="24px"
                  >
                    {showLoader ? (
                      <Spinner />
                    ) : (
                      <FormattedMessage
                        id={
                          signinClicked
                            ? "signinWithEmailBtn"
                            : "signupWithEmailBtn"
                        }
                      />
                    )}
                  </Text>
                </Button>
              </>
            )}
            <Text
              fontSize="16px"
              fontWeight={400}
              lineHeight="24px"
              textAlign="center"
              fontFamily="Poppins"
              color="#0A64BC"
              cursor="pointer"
              mt={6}
              onClick={() => setShowForm(true)}
            >
              <FormattedMessage
                id={
                  showForm && signinClicked
                    ? "forgetPasswordText"
                    : signinClicked
                      ? "loginWithEmailPassword"
                      : !showForm
                        ? "signupWithEmailPassword"
                        : "noId"
                }
              />
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
      <TermConditionModal
        TCChecked={TCChecked}
        setTCChecked={setTCChecked}
        isOpen={openTCModal}
        setIsOpen={setOpenTCModal}
      />
    </>
  );
};

export default SigninSignupModal;
