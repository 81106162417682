import { useCallback, useEffect } from "react";

declare global {
    interface Window {
      silktideCookieBannerManager?: {
        updateCookieBannerConfig: (config: any) => void;
        cookieBanner?: {
          toggleModal: (show: boolean) => void,
          destroyCookieBanner: () => void
        };
      };
      dataLayer:any
    }
}
type WindowWithDataLayer = Window & {
  gtag: Function;
};

declare const window: WindowWithDataLayer;


export const CookiesBanner = ()=>{
    const initCookieBanner = useCallback(() => {
        window.silktideCookieBannerManager?.updateCookieBannerConfig({
          background: {
            showBackground: true
          },
          cookieIcon: {
            position: "bottomLeft",
            show: false
          },
          cookieTypes: [
            {
              id: "necessary",
              name: "Necessary",
              description: "<p>These cookies are necessary for the website to function properly and cannot be switched off.</p>",
              required: true,
              onAccept: function() {
                window.gtag('consent', 'update', {
                  functionality_storage: 'granted',
                  security_storage: 'granted'
                });
              }
            },
            {
              id: "analytics",
              name: "Analytics",
              description: "<p>These cookies help us improve our website by collecting anonymous usage data.</p>",
              onAccept: function() {
                window.gtag('consent', 'update', {
                  analytics_storage: 'granted',
                });
                window.dataLayer.push({
                  'event': 'consent_accepted_analytical',
                });
              },
              onReject: function() {
                window.gtag('consent', 'update', {
                  analytics_storage: 'denied',
                });
              }
            },
            {
              id: "marketing",
              name: "Marketing",
              description: "<p>These cookies are used to track visitors across websites to display relevant advertisements.</p>",
              onAccept: function() {
                window.gtag('consent', 'update', {
                  ad_user_data: 'granted',
                  ad_storage: 'granted',
                  ad_personalization: 'granted'
                });
                window.dataLayer.push({
                  'event': 'consent_accepted_marketing',
                });
              },
              onReject: function() {
                window.gtag('consent', 'update', {
                  ad_user_data: 'denied',
                  ad_storage: 'denied',
                  ad_personalization: 'denied'
                });
              }
            }
          ],
          text: {
            banner: {
              description: "<p>We use cookies to enhance your experience. Choose your preferences below. <a href='https://www.monk.st/privacy/'>Privacy Policy</a></p>",
              acceptAllButtonText: "Accept all",
              acceptAllButtonAccessibleLabel: "Accept all cookies",
              rejectNonEssentialButtonText: "Reject all",
              rejectNonEssentialButtonAccessibleLabel: "Reject all non-essential cookies",
              preferencesButtonText: "Customize",
              preferencesButtonAccessibleLabel: "Customize cookie preferences"
            },
            preferences: {
              title: "Cookie Preferences",
              description: "<p>Manage how we use cookies to improve your experience.</p>",
              creditLinkText: "Cookie settings",
              creditLinkAccessibleLabel: "Manage cookie settings",
              link:"",
              href:""
            }
          },
          position: {
            banner: "center"
          }
        });
      }, []);
    
      useEffect(() => {
        window.gtag("consent", "default",  {
          analytics_storage: 'denied',
          ad_storage: 'denied',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
        })
        window.dataLayer?.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        window.gtag('config', 'G-20PFT6FX1L')
        initCookieBanner();
      }, []);

      return <></>
}