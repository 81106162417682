import { Button } from "@chakra-ui/react"
import { ReactComponent as OutlineInfoIcon } from "../../../assets/svg/outline-info.svg";

type InfoButtonProps = {
    onClick: ()=>void,
    
}
export const InfoButton = ({ onClick, ...rest }: InfoButtonProps) => {

    return (
        <Button
            onClick={()=>onClick()}
            variant="ghost"
            padding={0}
            w={"max-content"}
            minW={"unset"}
            minH={"unset"}
            h={"unset"}
            cursor={"pointer"}
            zIndex={9999}
        >
            <OutlineInfoIcon style={{ color: "#0564B8" }} width={"20px"} height={"20px"}/>
        </Button>
    )
}