const MODAL_TYPES_ENUM = {
  locale: "locale",
  user: "user",
  iframe: "iframe",
  resetPassword: "resetPassword",
  pricing: "pricing",
  changePassword: "changePassword",
  afterUser: "afterUser",
  registration: "registration_success",
  verification: "verification",
  subscription: "subscription",

};

export { MODAL_TYPES_ENUM };
