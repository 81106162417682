import axios from "axios";

const clientApi = axios.create({
    baseURL: `${process.env.REACT_APP_SERVER_URL}`,
    headers: {
      "accept-language": "es",
      "Content-Type": "application/json"
    },
});

clientApi.interceptors.request.use(function (config) {
    const token = (localStorage.getItem("accessToken") ?? "").replace(/['"]+/g, "").trim();
    if (token) {
      config.headers["Authorization"] = token;
      config.headers["token"] = token;
    }
    return config;
  },function (error) {
    return Promise.reject(error);
});

export {clientApi};