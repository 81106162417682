export const ratioDictSign = {
  //PROFITABILITY FIRST GROUP
  "Gross Margin": "%",
  "Operating Margin": "%",
  "Net Profit Margin": "%",
  "FCF Margin": "%",

  //PROFITABILITY SECOND GROUP
  ROIC: "%",
  ROE: "%",
  ROA: "%",
  ROCE: "%",

  //HEALTH FIRST GROUP
  "Net Debt to Equity": " ",
  "Interest Coverage": " ",
  "Current Ratio": " ",
  "Quick Ratio": " ",

  //HEALTH SECOND GROUP
  "Cash Conversion Cycle": "days",
  'cashConversionCycle': 'days',
  'dso': 'days',
  'dpo': 'days',
  "Asset Growth": "%",
  "External Financing Ratio": "%",
  "Percent Accruals": "%",

  //SHAREHOLDER RETRIBUTION FIRST GROUP
  "Dividend Yield": "%",
  "Buyback Ratio": "%",
  "Debt Paydown": "%",
  "Shareholder Yield": "%",

  //GROWTH FIRST GROUP
  "Revenue Growth": "%",
  "Operating Income Growth": "%",
  "Operating Cash Flow Growth": "%",
  "Capital Expenditures Growth": "%",
  "Free Cash Flow Growth": "%",
  "Oper. Income Growth": "%",
  "Oper. Cash Flow Growth": "%",
  "Cap. Exp. Growth": "%",


  //GROWTH SECOND GROUP
  "Growth Trend": "%",
  "Growth Predictability": "%",
  "Growth Sustainability": " ",
  Momentum: "%",

  //VALUATION FIRST GROUP
  "Price / Sale": " ",
  "Price / Earnings": " ",
  "P/FCF": " ",
  "Price / Earnings Growth": " ",

  //VALUATION SECOND GROUP
  "EV / Revenue": " ",
  "EV / Operating Earnings": " ",
  "EV / EBITDA": " ",
  "EV / FCF": " ",

  // UNKNOWN FIELDS
  "GP/Assets": "%",
  "Probability of Default": "%",
  "EV/Operating Income": "%",
  "EV/Net Income": "%",
  "EV/Assets": "%",
  "EV/Operating Income Growth": "%",
  FCF: "%",
  "Net Income": "%",
  Revenue: "%",
  'revenue': { type: 'currency', currency: 'USD' },
  'marketCap': { type: 'currency', currency: 'USD' },
  'freeCashFlow': { type: 'currency', currency: 'EUR' },
  "Operating Income": "%",
  "Operating Cash Flow": "%",
  "Capital Expenditure": "%",
};

export const getFormattedRatioName = (activeChart: string): string => {
  const ratioMapping: { [key: string]: string } = {
    "Gross Margin": "grossMargin",
    "Operating Margin": "operatingMargin",
    "Net Profit Margin": "netProfitMargin",
    "FCF Margin": "fcfMargin",
    "ROIC": "returnOnInvestedCapital",
    "ROE": "returnOnEquity",
    "ROA": "returnOnAssets",
    "ROCE": "returnOnCapitalEmployed",
    "Net Debt to Equity": "netDebtToEquity",
    "Interest Coverage": "interestCoverage",
    "Current Ratio": "currentRatio",
    "Quick Ratio": "quickRatio",
    "Cash Conversion Cycle": "cashConversionCycle",
    "Asset Growth": "assetGrowth",
    "External Financing Ratio": "externalFinancingRatio",
    "Percent Accruals": "percentAccruals",
    "Dividend Yield": "dividendYield",
    "Buyback Ratio": "buybackYield",
    "Debt Paydown Ratio": "debtPaydownYield",
    "Shareholder Yield": "shareholderYield",
    "Revenue Growth": "revenueGrowth",
    "Oper. Income Growth": "operatingIncomeGrowth",
    "Oper. Cash Flow Growth": "operatingCashFlowGrowth",
    "Cap. Exp. Growth": "capitalExpendituresGrowth",
    "Free Cash Flow Growth": "freeCashFlowGrowth",
    "Growth Trend": "growthTrend",
    "Growth Predictability": "growthPredictability",
    "Growth Sustainability": "growthSustainability",
    "Momentum": "momentum",
    "Price / Sale": "priceToSales",
    "Price / Earnings": "priceToEarnings",
    "Price / FCF": "priceToFCF",
    "Price / Earnings Growth": "priceToEarningsGrowth",
    "EV / Revenue": "evToRevenue",
    "EV / Operating Earnings": "acquirersMultiple",
    "EV / EBITDA": "evToEBITDA",
    "EV / FCF": "evToFCF",
    "P/FCF": "priceToFCF",
    "P/S": "PS",
    "Sloan Ratio": "sloan_ratio",
    "GP/Assets": "GP_Assets",
    "External Financing": "Ex_financing",
    "Net Income": "Net_income",
    "Outstanding Shares": "outstanding",
    "Market cap": "mktCap",
    "Operating Income": "operatingIncome",
    "Probability of Default": "probabilityOfDefault",
    "Operating Cash Flow": "operatingCashFlow",
    "Capital Expenditure": "capEx",
    "Long Term Reinvestment Rate": "longTermReinvestmentRate",
    "R&D to Enterprise Value": "rdToEnterpriseValue",
    "LTM FCF Growth As Percentage Of Revenue": "ltmFCFGrowthAsPercentageOfRevenue",
    "R&D Index Score": "rdIndexScore",
    "EV/Operating Income": "evToOperatingIncome",
    "EV/Net Income": "evToNetIncome",
    "EV/Assets": "evToAssets",
    "EV/Operating Income Growth": "evToOperatingIncomeGrowth"
  };

  return ratioMapping[activeChart] || activeChart;
};

export const ratiosLowerIsBetter = {
    "Cap. Exp. Growth": true,

    "Price / Sale": true,
    "Price / Earnings": true,
    "P/FCF": true,
    "Price / Earnings Growth": true,

    "EV / Revenue": true,
    "EV / Operating Earnings": true,
    "EV / EBITDA": true,
    "EV / FCF": true,

    "Net Debt to Equity": true,
    "Cash Conversion Cycle": true,
    'cashConversionCycle': true,
    "External Financing Ratio": true,
    "Asset Growth": true,
    "Percent Accruals": true,
}