import { Box, Image as ChakraImage, Flex, Text } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useMainlyWhite } from "../../../hooks/useMainlyWhite";
import { IStock } from "../../../types/Stock";
import { fetchBrandLogo } from "../../../utils/image";

interface Props {
  compData?: IStock|any;
  small?: boolean;
}

const checkImage = (path) =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ path, status: "ok" });
    img.onerror = () => resolve({ path, status: "error" });

    img.src = path;
  });

const NewCompanyBasicCard = ({ compData, small = false }: Props) => {
  const logoRef = useRef<any>();

  useEffect(() => {
    const url = compData?.image;

    checkImage(url);
  }, [compData]);

  const imageSrc = compData ? fetchBrandLogo(compData) : `${process.env.PUBLIC_URL}/stock-icons/${compData?.symbol}-${compData?.exchangeShortName}.png`;
  const { isMainlyWhite } = useMainlyWhite(imageSrc, 5);

  return (
    <Flex alignItems="center" flexDirection="column" justifyContent="center" className="border-none-important">
      <Flex
        alignItems="center"
        bgColor="#F9FAFB"
        borderRadius="20px"
        flexDirection={small ? "row" : "column"}
        columnGap={small ? "5px" : "0"}
        className="border-none-important"
      >
        <Box
          height={small ? "30px" : "100px"}
          width={small ? "30px" : "100px"}
          mb={small ? 0 : "15px"}
          borderRadius={isMainlyWhite ? "50%" : "50%"}
          overflow={"hidden"}
          display={"grid"}
          alignItems={"center"}
          justifyItems={"center"}
          className="border-none-important"
        >
          <ChakraImage
            src={compData && fetchBrandLogo(compData)}
            ref={logoRef}
            alt={compData && compData.companyName}
            fallbackSrc={`${process.env.PUBLIC_URL}/stock-icons/fallback.png`}
            onError={(e: any) => {
              e.target.onError = null;
              e.target.src = `${process.env.PUBLIC_URL}/stock-icons/fallback.png`;
            }}
            fallbackStrategy="beforeLoadOrError"
            boxSize={"100%"}
            minHeight={"100%"}
            objectFit="cover"
            maxWidth={"100%"}
            overflow={"hidden"}
            className="border-none-important"
          />
        </Box>

        <Box w={"max-content"}>
          <Text
            fontSize={small ? "8px" : "16px"}
            fontWeight={small ? "700" : "600"}
            lineHeight={small ? "12px" : "24px"}
            textAlign={small ? "left" : "center"}
            noOfLines={2}
          >
            {compData && compData?.companyName}
          </Text>
          <Flex
            mt={small ? "2px" : "4px"}
            alignItems={"center"}
            justifyContent={small ? "flex-start" : "center"}
          >
            <img
              src={`https://purecatamphetamine.github.io/country-flag-icons/1x1/${
                compData && compData.country ? compData.country : "US"
              }.svg`}
              alt=""
              style={{
                borderRadius: "50%",
                height: "10px",
                width: "10px",
                marginRight: "4px",
              }}
            />
            <Text
              fontSize="7px"
              fontWeight="400"
              lineHeight="14px"
              color="#1C1C1C"
              height={"12px"}
              textOverflow={"ellipsis"}
            >
              {compData?.ticker?.split(".")[0]}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default NewCompanyBasicCard;
