import {
  Flex,
  Spinner,
  Text,
  useBreakpoint,
  useToast,
} from "@chakra-ui/react";

import Image from "../../assets/svg/image_icon.svg";
import Share from "../../assets/svg/share_icon.svg";
import Link from "../../assets/svg/link_icon.svg";

import { CommonModal } from "./Common";
import { AvatarCommonBox } from "../common/Box/Avatar";
import { useScreenShots, useSharedDom } from "../../hooks/useScreenShots";
import { useCallback, useState } from "react";
import { iOSSafari } from "../../utils/navigator";
import { copyContent } from "../../utils/share";
import { registerShare } from "../../utils/gtagAnalytics";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  idElementForScreenshot?: string;
  isChart?: boolean;
}

export function ShareModal({
  isOpen,
  onClose,
  idElementForScreenshot,
  isChart

}: Props) {

  const toast = useToast();

  const { getScreenShot } = useScreenShots(
    localStorage.getItem('idElementForScreenshot') ?? '',
    isChart,
  );
  const { share } = useSharedDom(
    localStorage.getItem('idElementForScreenshot') ?? '',
    isChart,
  );
  const breakpoint = useBreakpoint();
  const [isSafari, _] = useState<boolean>(iOSSafari(navigator.userAgent));
  const isMobile = breakpoint === "base" || breakpoint === "sm";
  const [isLoading, setIsLoading] = useState({
    image: false,
    copy: false,
    share: false,
  });

  const registerGtmShare = useCallback(() => {
    if(idElementForScreenshot){
      registerShare(idElementForScreenshot)
    }
  },[idElementForScreenshot])

  const onClickCopyButton = useCallback(async () => {
    if (!isLoading.share) {
      registerGtmShare()
      setIsLoading({ ...isLoading, share: true });
      setTimeout(() => {
        if (isSafari) {
          share();
        } else {
          getScreenShot();
        }
        setTimeout(() => {
          setIsLoading({ ...isLoading, image: false });
        }, 3000);
      }, 500);
    }
  }, [isLoading, isSafari])

  const onClickImage = useCallback(async () => {
    if (!isLoading.image) {
      registerGtmShare()
      setIsLoading({ ...isLoading, image: true });
      getScreenShot();
      setTimeout(() => {
        setIsLoading({ ...isLoading, image: false });
      }, 3000);
    }
  }, [getScreenShot, isLoading, registerGtmShare])

  const shareLink = useCallback(async () => {
    try {
      setIsLoading({ ...isLoading, copy: true });
      let currentUrl = window.location.href ?? "";
      const url = new URL(currentUrl)
      /* url.searchParams.set("idScrollable",idElementForScreenshot ?? "")
      url.searchParams.set("isChart",isChart ? "true" : "false") */
      const link = url.toString()
      await copyContent(link)
      toast({
        title: `Copied the link to the clipboard.`,
        status: "info",
      });
      setTimeout(() => {
        setIsLoading({ ...isLoading, copy: false });
      }, 1000)
    } catch (error) {
      toast({
        title: `There was an error copying to the clipboard`,
        status: "error",
      });
      setIsLoading({ ...isLoading, copy: false });
    }
  }, [isLoading, toast])

  return (
    <CommonModal
      modalContentCustomProps={{
        height: { base: "auto", lg: "200px" },
        padding: { lg: "40px, 20px, 40px, 20px" },
        gap: "20px",
      }}
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <Flex
        alignItems={"center"}
        flexDirection={"row"}
        height={"100%"}
        justifyContent={"space-evenly"}
        flexWrap={"wrap"}
        width={"100%"}
      >

      <Flex
          alignItems={"center"}
          flexDirection={"column"}
          justifyContent={"center"}
          display={ isSafari || isMobile ? {"base":"flex","lg":"none"} : {"base":"flex","lg":"none"}}
        >
          <AvatarCommonBox disable={isLoading.share} onPress={() => { onClickCopyButton() }}>
            {isLoading.share ? <Spinner size="md" color="blue.500"
              css={{ "--spinner-track-color": "colors.gray.200" }} /> : <img
              src={Share}
              color={"whiteAlpha.400"}
            />}

          </AvatarCommonBox>
          <Text
            color={"#000"}
            fontSize={"14px"}
            fontWeight={500}
            textAlign={"center"}
          >
            Share
          </Text>
        </Flex>

        <Flex
          alignItems={"center"}
          flexDirection={"column"}
          justifyContent={"center"}
          display={ !isSafari ? {"base":"flex"} : {"base":"none","lg":"flex"}}
        >
          <AvatarCommonBox disable={isLoading.image} onPress={() => {onClickImage()}}>
            {isLoading.image ? <Spinner size="md" color="blue.500"
              css={{ "--spinner-track-color": "colors.gray.200" }} /> : <img
              src={Image}
            />}
          </AvatarCommonBox>
          <Text
            color={"#000"}
            fontSize={"14px"}
            fontWeight={500}
            textAlign={"center"}
          >
            Copy Image
          </Text>
        </Flex>

        <Flex
          alignItems={"center"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <AvatarCommonBox disable={isLoading.copy} onPress={() => { shareLink() }}>
            {isLoading.copy ? <Spinner size="md" color="blue.500"
              css={{ "--spinner-track-color": "colors.gray.200" }} /> : <img
              src={Link}
              color={"whiteAlpha.400"}
            />}

          </AvatarCommonBox>
          <Text
            color={"#000"}
            fontSize={"14px"}
            fontWeight={500}
            textAlign={"center"}
          >
            Copy link
          </Text>
        </Flex>

      </Flex>
    </CommonModal>
  );
}
