export const billingPlans = {
  pro: {
    title: "Pro",
    name: "pro",
    price: {
      monthlyStandard: 79,
      monthlyDiscounted: 79,
      yearlyStandard: 72,
      yearlyDiscounted: 65,
      withMonthlyDiscount: true,
      withYearlyDiscount: true,
      yearlyTotal: 780,
    },
    summary: "For top investors who don’t want to miss any opportunity.",
    optionsList: [
      "Unlimited company analysis",
      "Unlimited companies in portfolio + watchlist",
      "View all companies in screener results",
      "Priority support",
    ],
    bestValue: false,
  },
  advanced: {
    title: "Advanced",
    name: "advanced",
    price: {
      monthlyStandard: 39,
      monthlyDiscounted: 39,
      yearlyStandard: 24,
      yearlyDiscounted: 32,
      withMonthlyDiscount: true,
      withYearlyDiscount: true,
      yearlyTotal: 384,
    },
    summary:
      "For serious investors who want to build and maintain a market beating portfolio of exceptional companies.",
    optionsList: [
      "200 analysis per month",
      "50 companies in portfolio + 50 in watchlist",
      "Up/to 50/companies in screener results"
    ],
    bestValue: true,
  },
  basic: {
    title: "Basic",
    name: "basic",
    price: {
      monthlyStandard: 19,
      monthlyDiscounted: 19,
      yearlyStandard: 19,
      yearlyDiscounted: 16,
      withMonthlyDiscount: true,
      withYearlyDiscount: true,
      yearlyTotal: 192,
    },
    summary: "For new investors who want to start a market beating portfolio.",
    optionsList: [
      "30 analysis per month",
      "10 companies in portfolio + 10 in watchlist",
      "Up/to 10/companies in screener results",
    ],
    bestValue: false,
  },
  /* free: {
    title: "Free",
    name: "free",
    price: {
      monthlyStandard: 0,
      monthlyDiscounted: 0,
      yearlyStandard: 0,
      yearlyDiscounted: 0,
      monthlyDiscount: null,
      withMonthlyDiscount: false,
      withYearlyDiscount: false,
      yearlyTotal: null,
    },
    summary: "For casual users who want to check a few companies.",
    optionsList: ["5 analysis per month"],
    bestValue: false,
  }, */
};

export const OptionsListTooltips = [
  "Access detailed MonkScore® breakdowns to assess a company’s likelihood of beating the market. Evaluate 5 key factors (Value, Growth, Profitability, Health, and Investor Friendliness) for data-driven decisions",
  "Monitor your investments with weekly updates on MonkScores and factor changes. Track your active positions in your portfolio and potential opportunities in your watchlist",
  "Discover investment opportunities by filtering through MonkStreet's database using our research-backed metrics. Find companies that match your preferred investing style using MonkScore®, individual factors, and essential financial ratios",
  "Priority support"
]
