import { IIncome, IRevenue } from "../types/Stock";
import { FormatChartNumber } from "./formatChartNumber";
import { ratioDictSign } from "./ratios";

export const formatNumberToDecimalPlaces = (n: number) => {
  return (Math.round(n * 100) / 100).toFixed(2);
};

export const prepareMarketCapValue = (n: number) => {
  if (!n) {
    return 0;
  }

  if (Math.abs(n) > 1_000_000_000_000) {
    return FormatChartNumber({ payload: n });
  }

  if (Math.abs(n) > 1_000_000_000) {
    return FormatChartNumber({ payload: n }).replace("B", "");
    // return Math.floor(n / 1_000_000_000);
  }

  if (Math.abs(n) > 1_000_000) {
    return FormatChartNumber({ payload: n }).replace("M", "");
  }

  return FormatChartNumber({ payload: n });
};

export const prepareMarketCapSym = (n: number) => {
  if (!n) {
    return "";
  }

  if (Math.abs(n) > 1_000_000_000_000) {
    return "";
  }

  if (Math.abs(n) > 1_000_000_000) {
    return "bn";
  }

  if (Math.abs(n) > 1_000_000) {
    return "M";
  }

  return "";
};

export const prepareMarketCapMark = (n: number) => {
  //   >100bn - mega
  // 10-100bn - large
  // 2-10bn - mid
  // 300M-2bn - small
  // 50-300M - micro
  // <50M - nano
  if (n > 100_000_000_000) {
    return "mega";
  }

  if (n > 10_000_000_000 && n <= 100_000_000_000) {
    return "large";
  }

  if (n > 2_000_000_000 && n <= 10_000_000_000) {
    return "mid";
  }

  if (n > 300_000_000 && n <= 2_000_000_000) {
    return "small";
  }

  if (n > 50_000_000 && n <= 300_000_000) {
    return "micro";
  }

  if (n && n <= 50_000_000) {
    return "nano";
  }

  return "";
};

export const prepareVolatilityMark = (n: number) => {
  //   <1: low
  // 1-1.2: medium
  // 1.2-1.5: high
  // >1.5: very high

  if (n < 1) {
    return "low";
  }

  if (n >= 1 && n < 1.2) {
    return "medium";
  }

  if (n >= 1.2 && n <= 1.5) {
    return "high";
  }

  if (n > 1.5) {
    return "very high";
  }

  return "";
};

export const calculateRevenueGrowth = (revenueData: IRevenue[]) => {
  if (!revenueData || !Array.isArray(revenueData)) {
    return 0;
  }

  const result = revenueData?.reduce(
    (acc, item, idx) => {
      if (idx < 4 && item?.RevenueLTM) {
        acc.currentYear += item?.RevenueLTM;
      }

      if (idx >= 4 && idx < 8 && item?.RevenueLTM) {
        acc.prevYear += item?.RevenueLTM;
      }

      return acc;
    },
    {
      currentYear: 0,
      prevYear: 0,
    },
  );

  // prevYear = 100%
  // currentYear = ?

  return Math.floor((result.currentYear * 100) / result.prevYear) - 100;
};

export const calculateIncomeGrowthByField = (
  data: IIncome[],
  field: "operatingIncome" | "netIncome",
) => {
  if (!data || !Array.isArray(data)) {
    return 0;
  }

  const result = data?.reduce(
    (acc, item, idx) => {
      if (idx < 4 && item?.[field]) {
        acc.currentYear += item?.[field];
      }

      if (idx >= 4 && idx < 8 && item?.[field]) {
        acc.prevYear += item?.[field];
      }

      return acc;
    },
    {
      currentYear: 0,
      prevYear: 0,
    },
  );

  // prevYear = 100%
  // currentYear = ?

  return Math.floor((result.currentYear * 100) / result.prevYear) - 100;
};

function roundToDecimalPlaces(number, decimalPlaces) {
  const multiplier = Math.pow(10, decimalPlaces);
  const roundedNumber = Math.round(number * multiplier) / multiplier;
  // Convert to string to preserve trailing zeros
  const formattedNumber = roundedNumber.toFixed(decimalPlaces);

  return formattedNumber;
}

export function roundNumber(n: number) {
  if (Number.isNaN(n)) return 0;

  if (n === 0 || n === -1 || n === 1) return n;

  if (n >= 10 && n <= 99 && n <= -10 && n >= -99) {
    return roundToDecimalPlaces(n, 1);
  } else if ((n >= 1 && n < 10) || (n <= -1 && n > -10)) {
    return roundToDecimalPlaces(n, 2);
  } else if ((n >= -1 && n <= 0) || (n >= 0 && n <= 1)) {
    return roundToDecimalPlaces(n, 3);
  } else if (n > 99 || n < -99) {
    return roundToDecimalPlaces(n, 0);
  } else {
    return roundToDecimalPlaces(n, 1);
  }
}

const getCurrencyValues = (n:number) => {
     return prepareMarketCapValue(n|| 0) + prepareMarketCapSym(n || 0) 
}

export const formatMetricValue = (value, metricType) => {
  const config = ratioDictSign[metricType];
  if (!config) return value.toString();
  const absValue = Math.abs(value);
  let formatted;
  // Dimensionless (x)
  if (config === 'x') {
    formatted = absValue >= 100 ?
      `${Math.round(absValue)}x` :
      `${absValue.toFixed(absValue >= 10 ? 1 : 2)}x`;
  }
  // Percentage
  else if (config === '%' || config === " ") {
    const s = config
    if (absValue >= 100) {
      formatted = `${Math.round(absValue)} ${s}`;
    } else if (absValue >= 10) {
      formatted = `${roundNumber(absValue)} ${s}`;
    } else {
      formatted = `${roundNumber(absValue)} ${s}`;
    }
  }
  // Days (round to whole number)
  else if (config === 'days') {
    formatted = `${Math.round(absValue)} days`;
  }
  // Currency
  else if (config.type === 'currency') {
    const scales = [
      { threshold: 1e12, suffix: 'T' },
      { threshold: 1e9, suffix: 'B' },
      { threshold: 1e6, suffix: 'M' },
      { threshold: 1e3, suffix: 'K' }
    ];
    const scale = scales.find(s => absValue >= s.threshold);
    if (scale) {
      const scaledValue = absValue / scale.threshold;
      const decimals = scaledValue >= 100 ? 0 : scaledValue >= 10 ? 1 : 2;
      formatted = `${scaledValue.toFixed(decimals)}${scale.suffix} ${config.currency}`;
    } else {
      formatted = `${getCurrencyValues(absValue)}`;
    }
  }
  return value < 0 ? `-${formatted}` : formatted;
};

export const formatMetricValueWithoutSign = (value, metricType) => {
  const config = ratioDictSign[metricType];
  if (!config) return value.toString();
  const absValue = Math.abs(value);
  let formatted;
  // Dimensionless (x)
  if (config === 'x') {
    formatted = absValue >= 100 ?
      `${Math.round(absValue)}` :
      `${absValue.toFixed(absValue >= 10 ? 1 : 2)}`;
  }
  // Percentage
  else if (config === '%' || config === " ") {
    if(absValue == 0 || absValue === 0){
      formatted =  `0.00`
    }
    else if (absValue >= 100) {
      formatted = `${Math.round(absValue)}`;
    } else if (absValue >= 10) {
      formatted = `${roundNumber(absValue)}`;
    } else {
      formatted = `${roundNumber(absValue)}`;
    }
  }
  // Days (round to whole number)
  else if (config === 'days') {
    if(absValue == 0 || absValue === 0){
      formatted =  `0 days`
    }
    formatted = `${Math.round(absValue)}`;
  }
  // Currency
  else if (config.type === 'currency') {
    formatted = `${getCurrencyValues(absValue)}`;
  }

  if(absValue == undefined || !absValue){
    return `0`
  }
  
  return value < 0 ? `-${formatted}` : formatted;
};

export function calculateLTMChange(oldValue, newValue, isPercentage = false, usePoints = false) {
  console.log("This values got oldValue",oldValue,"newValue",newValue,"isPercentage",isPercentage,"usePoints",usePoints,"")
  // Handle division by zero cases
  if (oldValue === 0) {
      if (newValue === 0) return "0%";
      return "n/a";
  }

  // Handle sign changes
  if (!isPercentage && ((oldValue < 0 && newValue > 0) || (oldValue > 0 && newValue < 0))) {
      return "n/a";
  }

  if (isPercentage) {
    console.log("percentage oldValue",oldValue,"newValue",newValue,"isPercentage",isPercentage,"usePoints",usePoints,"")
    // Calculate basis points (bps) change for percentages
    const bpsChange = !usePoints ? ((newValue - oldValue) * 100) : (newValue - oldValue)*1;

    // Handle extreme cases for bps
    if (bpsChange > 10000) return !usePoints ? ">10000 bps" : ">10000pts";
    if (bpsChange < -10000) return !usePoints ? "<10000 bps" : "<10000pts";;

    return `${bpsChange > 0 ? "+" : ""}${roundToDecimalPlaces(bpsChange,!usePoints ? 3 : 1)} ${!usePoints ? 'bps' : 'pts'}`;
} else {
    // Calculate percentage change for regular numbers
    const percentageChange = ((newValue - oldValue) / oldValue) * 100;
    
    // Handle extreme cases for percentages
    if (percentageChange > 1000) return ">1000%";
    if (percentageChange < -100) return "-100%";

    return `${percentageChange > 0 ? "+" : ""}${percentageChange.toFixed(0)}%`;
}
}
